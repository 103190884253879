/*
 * Machine view header css
 *
*/


.mc-header{
    border-top: 1px solid #ddd;
} 

.h-breadcrumb{
    height: 62px;
    background-color: #f3f4f5;
    padding: 20px;
    li{
        list-style: none;
        float: left;
        margin-right: 25px;
        position: relative;
        line-height: 20px;
        font-size: 14px;
        list-style: none;
        &:first-child{
            span{
                height: 20px;
                width: 20px;
                display: block;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: cover;
            }
        }
        
        > * {
          > i {
            margin-left: 25px;
          }
        }

        > i {
            margin-left: 25px;
        }
        

        a{
            color: #999;
            &:hover{
                text-decoration: underline;
            }
        }
        span{
            display: inline-block;
        }
    }
}

.mc-ttl{
    margin-top: 20px;
    margin-bottom: 30px;
    &.mc-ttl-hpu {
        margin-bottom: 30px;
        .ttl-left {
            h1 {
                margin-bottom: 20px;
                span {
                    font-size: 12px;
                }
            }
        }
    }
}

.machine-view {
    //font-style: 12px;
    .h-breadcrumb li {
        font-size: 1.167em;
    }

    .ttl-right .time-picker {
        > span {
            font-size: 1.167em;
        }

        .ipt {
            font-size: 1.167em;
        }
    }

    .ttl-range .range-lst {
        label {
            font-size: 1.167em;
        }
        span {
            font-size: 1.167em;
        }
    }

    .ttl-chr-opt .btn-apply {
        font-size: 1.167em;
    }

    .chrt-itm {
        .oee p {
            font-size: 3.33em;

            span:first-child {
                font-size: 0.35em;
            }

            span:last-child {
                font-size: 0.6em;
            }
        }


        &.big .oee p {
            font-size: 5.167em;

            span:first-child {
                font-size: 0.323em;
                &.teep {
                    right: -50px;
                }
            }

            span:last-child {
                font-size: 0.42em;
            }
        }
    }

    .c-tab > li > a {
        font-size: 1.167em;
    }

    .table-ft .show-itm .form-control {
        font-size: 1.167em;
    }

    .table-ft .paging .btn {
        font-size: 1em;
    }

    .export-btn {
        font-size: 0.75em;
    }

    .chart-ttl {
        font-size: 1.33em;
    }

    .table-cus tr:first-child th {
        font-size: 1em;
    }

    //.c-tb tr td {
    //    font-size: 1.25em;
    //}

    .oee-summarize {
        font-size: 1.167em;
    }

    .oee-table .table tr td {
        font-size: 1em;
    }

    .assump td label {
        font-size: 1em;
    }

    .assump td input {
        font-size: 1em;
    }

    .btn-edit-assump {
        font-size: 1em;
    }

    .chrt-groupby .ipt {
        font-size: 1em;
    }

    .no-data {
        font-size: 1.67em;
    }

    .station-lst h2 {
        font-size: 2.5em;
    }

    .st-lst li {
        font-size: 1.33em;

        > strong {
            font-size: 1.125em;
        }
    }

    .station-info {
        font-size: 0.875em;
    }

    .noti-tb .chk label {
        font-size: 1.67em;
    }

    .noti-lst li a {
        font-size: 0.916em;
    }

    .noti-tb .noti-action {
        .act-slt .ipt {
            font-size: 1em;
        }

        .btn {
            font-size: 1em;
        }
    }

    .noti-search .noti-search-frm {
        .search-noti {
            font-size: 1em;
        }

        button {
            font-size: 1em;
        }
    }

}

.ttl-left{
    float: left;
    h1{
        font-weight: 700;
        //font-size: 30px;
        font-size: 2.5em;
        margin-top: 0;
        span{
            //font-size: 10px;
            font-size: 0.33em;
            margin-left: 10px;
            text-transform: none;
            /*font-weight: normal;*/
            color: #999;
        }
    }
    li{
        float: left;
        margin-right: 45px;
        //font-size: 12px;
        span{
            //font-size: 16px;
            font-size: 1.33em;
        }
        .dt-ttl{
            font-weight: 700;
        }
    }
    .machine-brief-inf {
        margin-bottom: 10px;
        li {
            .dt-ttl, .dt-numb {
                font-size: 16px;
            }
            .dt-numb {
                margin-left: 20px;
            }
        }
    }
}

.ttl-right{
    float: right;
    .time-picker{
        float: left;
        width: 427px;
        .ipt{
            display: inline-block;
            width: 155px;
            height: 37px;
            margin-right: 20px;
            font-weight: 700;
            font-size: 14px;
            &:last-child{
                margin-right: 0;
            }
        }
        >span{
            margin-right: 10px;
            font-size: 14px;
        }
        .start-blk, .end-blk{
            width: 155px;
            display: inline-block;
            position: relative;
        }
        .start-blk{
            margin-right: 20px;
        }
    }
    .ttl-ipt{
        margin-right: 15px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.ttl-range{
    float: left;
    // width: 305px;
    .range-lst {
        margin-bottom: 0;
        label{
            margin-right: 15px;
            font-weight: normal;
            font-size: 14px;
            float: left;
            line-height: 38px;
        }
        span{
            display: block;
            float: left;
            width: 48px;
            height: 38px;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            border-left: 1px solid #eee;
            text-align: center;
            font-size: 14px;
            line-height: 36px;
            .fGogamBook;
            font-weight: normal;
            cursor: pointer;
            // border-radius: 2px;
            .transition(all 0.4s ease);
            &:hover{
                background-color: #eee;
            }
            &.active{
                background-color: #ddd;
            }
            &:first-child{
                margin-left: 20px;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }
            &:last-child{
                .border-radius(0 2px 2px 0);
                border-right: 1px solid #eee;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
            &:nth-of-type(1) {
                .border-radius(2px 0 0 2px);
            }
        }
    }
}

.ttl-chr-opt{
    float: left;
    height: 38px;
    .refresh-btn{
        width: 40px;
        height: 40px;
        display: inline-block;
        color: #666;
        font-size: 20px;
        padding: 0;
        i{
            .transition(all 0.6s ease);
        }
        &:hover{
            i{
                .transform(rotate(360deg));
            }
        }
    }
    .btn-apply{
        // margin-right: 10px;
        height: 37px;
        // padding-top: 0px;
        // margin-top: -2px;
        width: 70px;
        font-size: 14px;
    }
    .btn{
        &:active, &:focus, &:hover{
            box-shadow: none;
        }
    }
    .ipt{
        display: inline-block;
        width: 175px;
        margin-right: 15px;
        height: 38px;
        border-radius: 2px;
        box-shadow: none;
        font-size: 12px;
    }
}

.mc-h-general{
    margin-bottom: 50px;
}

.chrt-itm{
    width: 180px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    padding-top: 35px;
    min-height: 180px;
    .oee{
        top: 40%;
        left: 50%;
        .transform(translateX(-50%));
        p{
            font-size: 40px;
            span{
                &:first-child{
                    font-size: 14px;
                    top: 42px;
                    // right: -4px;
                }
                &:last-child{
                    font-size: 24px;
                    top: 14px;
                    right: -22px;
                }
            }
        }
        &.oee-style-2 {
            left: 50%;
            top: 35%;
            p{
                font-size: 70px;
                margin-bottom: 0;
                span{
                    &:first-child{
                        font-size: 16px;
                        top: 12px;
                        right: -12px;
                    }
                    &:last-child{
                        font-size: 22px;
                        top: 14px;
                        right: -25px;
                    }
                }
            }
        }
    }
    .nan{
        p{
            span{
                &:first-child{
                    right: 50%;
                    .transform(translateX(50%));
                }
            }
        }
    }
}
.big{
    padding-top: 0;
    width: 245px;
    min-height: 245px;
    margin: 0 auto;
    .oee{
        top: 30%;
        left: 40%;
        p{
            font-size: 62px;
            span{
                &:first-child{
                    font-size: 20px;
                    top: 13px;
                    right: -44px;
                }
                &:last-child{
                    font-size: 26px;
                    top: 35px;
                    right: -28px;
                }
            }
        }
        &.oee-style-2 {
            left: 45%;
            top: 25%;
            p {
                font-size: 85px;
                margin-bottom: 0;
                span{
                    &:first-child{
                        font-size: 17px;
                        top: 25px;
                        right: -30px;
                    }
                }
            }
        }
    }
    .nan{
        p{
            span{
                &:first-child{
                    font-size: 20px;
                    top: 17px;
                    right: -20px;
                }
            }
        }
    }
}

// Noti block
.mc-noti{
    margin-bottom: 30px;
    margin-top: 20px;
    ul{
        li{
            display: inline-block;
            margin-right: 20px;
            position: relative;
            &:last-child{
                margin-right: 55px;
            }
        }
    }
    .noti-ic{
        background-repeat: no-repeat;
        background-size: cover;
        width: 36px;
        height: 36px;
        &.noti-info{
            background-image: url('../../../images/information-blue.png');
            &.evt-gray{
                background-image: url('../../../images/info-gray.png');
            }
        }
        &.noti-trend{
            background-image: url('../../../images/trends.png');
            &.evt-gray{
                background-image: url('../../../images/trend-gray.png');
            }
        }
        &.noti-insight{
            background-image: url('../../../images/insight.png');
            &.evt-gray{
                background-image: url('../../../images/insight-gray.png');
            }
        }
        &.noti-alert{
            background-image: url('../../../images/alert-info.png');
            // background-image: url('../../../images/alert.png');
            &.evt-gray{
                background-image: url('../../../images/alert-gray.png');
            }
        }
        &.noti-critical{
            background-image: url('../../../images/critical.png');
            &.evt-gray{
                background-image: url('../../../images/critical-gray.png');
            }
        }
        &.noti-warning{
            background-image: url('../../../images/warn-ico.png');
            &.evt-gray{
                // background-image: url('../../../images/critical-gray.png');
                // background-color: #DCDCDD;
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                -ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
                filter: grayscale(100%);
                filter: gray; 
            }
        }
    }

    .cus-tooltip{
        position: absolute;
        min-width: 100px;
        padding: 5px;
        border-radius: 4px;
        background-color: #1c2b36;
        color: #fff;
        top: 45px;
        display: none;
        z-index: 100;
        p{
            margin-bottom: 0;
        }
        &:before{
            content: '';
            display: none;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #1c2b36;
            position: absolute;
            top: -5px;
            left: 14px;
            z-index: 100;
        }
        &.criticalsystemevent{
            min-width: 140px;
            left: -30px;
            &:before{
                left: 45px;
            }
        }
        &.shw{
            display: block;
            &:before{
                display: block;
            }
        }
    }
}

.filt-chrt-blk{
    position: fixed;
    top: 320px;
    right: 0;
    z-index: 500;
}
.remotes {
    position: fixed;
    z-index: 110;
    top: 320px;
    right: 0;

    &.has-machine-section {
        top: 380px;
    }

    .remote-btn {
        cursor: pointer;
        display: block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: #eee;
        border: 1px solid #ddd;
        border-right: none;
        .border-radius(2px 0 0 2px);
        text-align: center;
        font-size: 24px;
        color: #333;
        .transition(all .3s ease);
        &:hover {
            background-color: @blue;
            color: #fff;
            &:focus {
                color: #fff;
            }
        }
        &:focus {
            color: #333;
        }
    }

    .cus-tooltip {
        position: absolute;
        min-width: 100px;
        padding: 5px;
        border-radius: 4px;
        background-color: #1c2b36;
        color: #fff;
        top: 0;
        z-index: 100;
        right: 60px;
        display: none;

        &:before {
            content: '';
            display: none;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid #1c2b36;
            position: absolute;
            top: 17px;
            z-index: 100;
            right: -5px;
        }

        &.shw {
            display: block;

            &:before {
                display: block;
            }
        }
    }
}


.relative-machine-btn,
.filt-chrt-btn{
    position: fixed;
    display: none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-right: none;
    .border-radius(2px 0 0 2px);
    text-align: center;
    z-index: 110;
    top: 320px;
    right: 0;
    font-size: 24px;
    color: #333;
    .transition(all .3s ease);
    &:hover {
        background-color: @blue;
        color: #fff;
        &:focus {
            color: #fff;
        }
    }
    &:focus {
        color: #333;
    }
    &.act {
        right: 600px;
        background-color: #181c26;
        color: #fff;
    }
    &.shw{
        display: block;
    }
}

a.relative-machine-btn,
filt-chrt-btn {
    text-decoration: none;

    &.act {
        right: 300px;
        background-color: #181c26;
        color: #fff;

        &:hover {
            background-color: @blue;
        }
    }
}

.filt-chrt-blk.machine-section.active {
    width: 300px;
}

.bd-color .color-system-wrap.shw {
    z-index: 160;
}

.filt-chrt-blk {
    top: 126px;
    height: 100%;
    .transition(all .3s ease);
    &.active {
        width: 600px;
        z-index: 1051;
        // height: 330px;
    }
    &.scroll {
        top: 0;
        .filt-wrap{ 
            top: 0;
        }
    }
    &:before {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        opacity: .7;
        z-index: 200;
    }
    &.loading {
        &:before {
            display: block;
        }
    }
    .spinner {
        z-index: 550;
    }
}
.filt-chrt-btn {
    opacity: 0;
    visibility: hidden;
    top: 0;
    display: block;
    transition: all 0.2s ease;
    border: none;
    &.act {
        opacity: 1;
        visibility: visible;
    }
    &.has-filter {
        background-color: @blue;
        color: #fff;
    }
    &:hover {
        background-color: @blue;
    }
    &.disabled {
        pointer-events: none;
        cursor: default;

    }
}

.filt-head {
    background-color: #DEDEDF;
    padding: 10px 15px;
    h3 {
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 600;
    }
}
.filt-wrap {
    padding-top: 40px;
    width: 600px;
    height: 100%;
    position: fixed;
    top: 126px;
    right: 0;
    z-index: 100;
    background-color: #181c26;
    // border: 1px solid #181c26;
    border-right: none;
    .transition(all .3s ease);
    .opacity(0);
    .transform(translateX(300px));
    visibility: hidden;
    min-height: 150px;
    .border-radius(0 0 0 2px);

    &.machines-content {
        width: 300px;
    }

    &.shw{
        opacity: 1;
        visibility: visible;
        .transform(translateX(0));
        z-index: 170;
    }
    .ipt {
        background-color: #fff;
        height: 80px;
        overflow: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    option {
        padding: 2px 0;
    }
    .filt-itm {
        padding: 15px;
        .filt-ttl {
            color: #fff;
            position: relative;
            display: block;
            font-size: 16px;
            text-decoration: none;
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;
            padding-left: 25px;
            span {
                font-weight: 600;
            }
            .ico {
                left: 0;
                top: 0;
            }
            i {
                position: absolute;
                right: 0;
                top: 5px;
            }
        }
    }
    .filt-ft {
        padding: 15px;
        text-align: center;
    }
    .reset-filt {
        text-decoration: underline;
        color: #fff;
        float: right;
        display: block;
        padding-right: 15px;
        margin-top: -15px;
    }
}

.filt-list {
    display: none;
    margin-top: 10px;
    height: 150px;
    overflow-y: auto;
    position: relative;
    // border: 1px solid #ddd;
    // padding: 10px;
    &.shw {
        display: block;
    }
}

.filt-list::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.filt-list::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.filt-list::-webkit-scrollbar-thumb
{
	background-color: rgba(0,0,0,0.3);;
}
.c-dt-table{
    border: 1px solid #ddd;
    .box-shadow(0px 2px 2px 0px rgba(0,0,0, 0.1));
    margin-bottom: 10px;
}
.filt-list-th{
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.filt-opt {
    margin-bottom: 10px;
    color: #fff;
    .chk-td {
        margin-bottom: 10px;
        label {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 0;
        }
    }
    >div {
        &:last-child {
            .chk-td {
                margin-bottom: 0;
            }
        }
    }
    span {
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
        display: inline-block;
        line-height: 16px;
    }
}

.last-maintain {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    a {
        text-decoration: underline;
        font-size: 18px;
        font-weight: normal;
        font-family: 'UTMCafeta', Arial, sans-serif;
    }
}

// Hpu machine circle block
.cir-blk{
    position: fixed;
    top: 320px;
    right: 0;
    z-index: 120;
}
.circle-blk{
    width: 300px;
    padding: 15px;
    position: fixed;
    top: 320px;
    right: 0;
    z-index: 120;
    background-color: #eee;
    border: 1px solid #ddd;
    border-right: none;
    .transition(all .3s ease);
    .opacity(0);
    .transform(translateX(300px));
    visibility: hidden;
    min-height: 150px;
    .border-radius(0 0 0 2px);
    &.shw{
        opacity: 1;
        visibility: visible;
        .transform(translateX(0));
        z-index: 170;
    }
    .circle-itm{
        position: relative;
        margin-bottom: 15px;
        height: 30px;
    }
    .circle-chrt{
        display: inline-block;
        margin-right: 15px;
        width: 30px;
        height: 30px;
    }
    .circle-inner {
        p{
            display: inline-block;
            position: absolute;
            top: 50%;
            .transform(translateY(-50%));
            margin-bottom: 0;
            span{
                display: inline-block;
                font-weight: 600;
            }
            .canvas-ttl{
                width: 90px;
                margin-right: 10px;
            }
        }
    }
}
.mc-view-2 {
    .circle-inner {
        display: none;
        &.shw {
            display: block;
        }
        p {
            font-size: 14px;
        }
    }
}

.circle-btn{
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-right: none;
    .border-radius(2px 0 0 2px);
    text-align: center;
    z-index: 110;
    top: 320px;
    right: 0;
    font-size: 24px;
    color: #333;
    .transition(all .3s ease);
    &:hover {
        background-color: @blue;
        color: #fff;
        &:focus {
            color: #fff;
        }
    }
    &:focus {
        color: #333;
    }
    &.act {
        right: 299px;
    }
    &.shw{
        display: block;
    }
    &.hasRemote {
        top: 380px;
    }
}
.other-metric {
    margin-bottom: 15px;
    .metric-inner {
        padding-right: 10px;
        overflow-y: auto;
    }
    .blk-ttl {
        font-weight: 600;
    }
    p {
        font-size: 14px;
        margin-bottom: 15px;
        .val {
            float: right;
        }
    }
}

.insight-notification {
    margin: 0px 20px;
    margin-bottom: 20px;
    padding: 12px;
    padding-right: 30px;
    background: #0048CC;
    border-radius: 3px;
    color: white;
    position: relative;
    .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 16.5px;
        height: 16.5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        cursor: pointer;
    }
    label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
    }
    p {
        margin: 0px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }
    a {
        color: #FFC81F;
        text-decoration: underline;
    }

}
.insight-notification {
    &.chart-style{
        background: #F3F6FF;
        color: black;
        padding-left: 25px;
        font-size: 12px;
        line-height: 16px;
        min-height: 80px;
        a {
            color: #005aff;
            text-decoration: none;
        }

        &::before{
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            width: 9px;
            background: #ABE3B0;
            border-radius: 3px 0px 0px 3px;
        }
    }
}

.wrap-tab-lst {
    margin: 0px 20px;
}

.card-block {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 60px;
    .card-head{
        padding-top: 20px;
        border-bottom: 2px solid #DCDCDC;
    }
}