.tooltip-item {
    display: none;
    z-index: 1;
    position: absolute;
    max-width: 400px;
    width: 400px;
    // left: 83%;
    // top: 3.4%;
    // margin-top: -100px;
    border: none;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

    &.show {
        display: block;
    }

    .head-ct {
        width: 100%;
        position: relative;

        .btn-close {
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            overflow: hidden;
            width: 20px;
            height: 20px;
            padding: 0px;
            margin: 0px;
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .item-head {
        padding-bottom: 5px;

        .item-name{
            max-width: calc(~'100% - 25px');
            display: inline-block;
        }
        .item-time {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .item-content {
        display: block;
        background: #fff;
    }

    .table-info-col {
        width: 66.66667%;
        height: 100%;
        padding: 20px 20px 15px;
    
    }

    .table-info-wrapper {
        max-height: 100%;
        overflow: auto;
        margin-right: -15px;
        padding-right: 15px;
    }

    .table-info {
        width: 100%;
        > tbody > tr {
            margin-bottom: 25px;
            height: 35px;

            &:first-of-type {
                margin-bottom: 15px;
                height: 20px;
            }
        }

        > tbody > tr > th {
            text-align: center;
            font-size: 12px;
            line-height: 14px;
            color: #7F8181;

            &:first-of-type {
                text-align: left;
            }
        }

        > tbody > tr > td { 
            text-align: center;
            &:first-of-type {
                text-align: left;
            }
            &.red {
                color: #E81A3C; 
            }
            &.green {
                color: #10A81F;
            }
            &.yellow {
                color: #FFC81F;
            }
        }
    }

    .item-content {
        .content-middle {
            margin-left: -15px;
            margin-right: -15px;
            height: 340px;
            border-bottom: 1px solid #DCDCDC;
        }
        .info-col {
            border-left: 1px solid #DCDCDC;
            .info-box {
                border-bottom: 1px solid #DCDCDC;
                text-align: center;
                height: 85px;
                padding-top: 24px;
            }
        } 
    }

    .bottom-ct{
        position: relative;
        padding: 8px;

        .btn-dropdown {
            position: absolute;
            top: 15px;
            right: 20px;
            border: none;
            background: transparent;
            color: #005AFF;
        }
    }

    .item-content .content-top {
        background-color: #ffffff;
        border-bottom: 1px solid #DCDCDC;
        padding: 20px;
    }

    .status-bar-box{
        i.fa {
            &.increase {
                transform: rotateZ(-45deg);
                color: #10A81F;
            }

            &.decrease {
                transform: rotateZ(45deg);
                color: #10A81F;
            }
        }
    }

    .item-content .info-tab-content{
        > article {
            padding: 5px 0px 5px 15px;
            max-height: 150px;
            height: 150px;
            &.collapsed {
                max-height: 24px;
                overflow: hidden;
            }
    
        }
        
    } 
    .item-content .info-tab-content {
        padding-right: 40px;
    }
}
tooltip-item {
    .item-pointer {
        width: 1px;
        height: 1px;
        border-right: 10px solid #ffffff;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        position: absolute;
        top: 150px;
        margin-left: -10px;
    }
}

