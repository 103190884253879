/*
 *
 * Event input
 * 1. Hierarchy
 *
*/ 
.dash-evt {
    min-height: 600px;
}
// Event input head
.event-logo {
    float: right;
}
.evt-logo-inner {
    display: block;
    text-align: center;
    line-height: 64px;
    img {
        width: 60%;
    }
}

// 1. Hierarchy
.hierarchy-blk {
    background-color: #fff;
    position: absolute;
    top: 25px;
    z-index: 350;
    width: 400px;
    .box-shadow(0px 3px 3px 0px rgba(0, 0, 0, 0.5));
    display: none;
    .expand-child {
        cursor: pointer;
    }
    &.shw {
        display: block;
    }
    .hier-srch {
        position: relative;
        z-index: 100;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        .ipt {
            box-shadow: none;
        }
    }
    &.loading {
        .loader-spinner {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            .transform(translate(-50%, -50%));
            z-index: 603;
        }
        &:before {
            display: block;
        }
    }
    &:before {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 601;
        background-color: #fff;
        .opacity(.7);
    }
}

.hierarchy-inner {
    position: relative;
    &:before {
        content: '';
        z-index: 90;
        display: block;
        width: 1px;
        background-color: #ddd;
        position: absolute;
        bottom: 3px;
        left: 15px;
        top: 78px;
    }
}

.hier-srch-rs {
    position: relative;
    overflow-y: auto;
    padding: 0 10px 30px 10px;
    height: 385px;
    
}

.rs-itm {
    position: relative;
    margin-bottom: 35px;
    .lvl1-blk {
        padding-left: 20px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            z-index: 100;
            left: 0px;
            top: 5px;
            width: 10px;
            height: 10px;
            border: 1px solid #ddd;
            background-color: #fff;
            border-radius: 100%;
        }
        i {
            position: absolute;
            right: 0;
            top: 2px;
            z-index: 60;
            width: 30px;
            text-align: right;
        }
        a{
            display: block;
            position: relative;
            color: #333;
            font-weight: bold;
            font-size: 14px;
            z-index: 50;
        }
    }
}

.lvl2-blk {
    padding-left: 30px; 
    margin-top: 10px;
    display: none;
    &.shw {
        display: block;
    }
    .lvl2-inner {
        position: relative;
        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 20px;
            background-color: #ddd;
            position: absolute;
            bottom: 10px;
            left: -25px;
        }
    }
    i {
        position: absolute;
        right: 0;
        top: 2px;
        z-index: 60;
        width: 30px;
        text-align: right;
    }
    .lvl2-link {
        margin-bottom: 10px;
        display: block;
        position: relative;
        color: #333;
        z-index: 50;
        
        &.has-child {
            margin-bottom: 5px;
        }
    }
}

.lvl3-blk {
    display: none;
    padding-left: 30px; 
    position: relative;
    &.shw {
        display: block;
    }
    &:before {
        content: '';
        display: block;
        width: 1px;
        background-color: #ddd;
        position: absolute;
        bottom: 10px;
        top: 0px;
        left: 9px;
    }
    .lvl3-inner {
        position: relative;
        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 15px;
            background-color: #ddd;
            position: absolute;
            bottom: 10px;
            left: -20px;
        }
    }
    .lvl3-link {
        margin-bottom: 8px;
        display: block;
        position: relative;
        color: #333;
        z-index: 50;
    }
}

.loader-spinner {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid @blue;
    border-bottom: 5px solid @blue;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: none;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.evt-parent, .evt-child {
    padding: 15px 0 15px 0;
}

.evt-parent{
    &.expand {
        // padding-bottom: 0;
        .slick-list {
            height: 395px;
            margin-bottom: -159px;
        }
    }
}

.evt-child {
    margin-bottom: 20px;
    position: relative;
    &.expand {
        position: absolute;
        width: 100%;
        top: 96px;
    }
}

.evt-ipt2 {
    .evt-itm-blk {
        width: 20%;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.evt-parent {
    .evt-itm {
        position: relative;
        .evt-btn {
            .name {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}

// Event item
.evt-itm {
    display: table;
    width: 100%;
    background: @l_dark;
    position: relative;
    // margin-bottom: 10px;
    &.evt-child-itm {
        .evt-btn {
            font-weight: 400;
        }
    }
    .evt-btn {
        width: 100%;
        position: relative;
        display: table-cell;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        font-weight: 700;
        font-size: 20px;
        border-radius: 4px;
        color: #fff;
        padding: 8px;
        background-color: transparent;
        border: none;
        span {
            display: block;
        }
        i{
            &.fa-caret-down {
                position: absolute;
                right: 15px;
                top: 50%;
                .transform(translateY(-50%));
            }
            &.fa-check {
                display: none;
                color: @green;
                position: absolute;
                left: 15px;
                top: 50%;
                .transform(translateY(-50%));
            }
        }
    }
    &.active, &.selected {
        background-color: @blue;
        .evt-btn {
            i {
                &.fa-check {
                    display: inline-block;
                }
            }
        }
    }
    &.evt-child-itm {
        &.disabled {
            pointer-events: none;
            background-color: #ddd;
        }
        .evt-btn {
            height: 80px;
            span {
                &.type {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

// Event name list
.evt-name-lst {
    height: 317px;
    overflow-y: auto;
    // padding: 10px 15px 0 15px;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 2px;
    position: absolute;
    width: 100%;
    z-index: 300;
    left: 0;
    top: 58px;
    background-color: #fff;
    display: none;
    &.shw {
        display: block;
    }
    .evt-name-itm {
        outline: none;
        height: 44px;
        // line-height: 60px;
        width: 100%;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        // font-weight: 600;
        font-family: Arial, sans-serif;
        text-decoration: none;
        display: table;
        // padding-bottom: 8px;
        // margin: 8px 0;
        border-bottom: 1px solid #ddd;
        color: #333;
        &:last-child {
            border: none;
        }
        span {
            display: table-cell;
            vertical-align: middle;
        }
        i {
            display: none;
            position: absolute;
            right: 10px;
            top: 50%;
            .transform(translateY(-50%));
            font-size: 16px;
        }
        &.selected {
            i {
                display: block;
            }
        }
        
    }
}

.evt-ttl {
    font-weight: 600;
}

.evt-ipt-itm {
    display: table;
    border-top: 2px solid #ddd;
    text-decoration: none;
    border-radius: 3px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 2px 5px 0px #cfd0d0;
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #333;
    height: 76px;
    overflow: hidden;
    width: 100%;
    span {
        // display: table-cell;
        display: inline-block;
        vertical-align: middle;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 18px;
        font-weight: normal;
    }
    .ipt-desc {
        margin-bottom: 0;
        margin-top: 5px;
        overflow-y: auto;
        // text-transform: uppercase;

        // &.ipt-25 {
        //     font-size: 16px;
        // }
    }
    &:hover, &:focus, &:active {
        text-decoration: none;
        color: #333;
        box-shadow: 0px 2px 5px 0px #cfd0d0 !important;
    }
    &.active {
        color: #fff;
    }
    &.red-evt {
        border-color: @red;
        &.active {
            background: @red;
        }
    }
    &.green-evt {
        border-color: @green;
        &.active {
            background: @green;
        }
    }
    &.yellow-evt {
        border-color: @yellow;
        &.active {
            background: @yellow;
            color: #333;
        }
    }
    &.disabled {
        pointer-events: none;
    }
}

// Modal
#evt-ipt-modal, #confirm-save-modal {
    .modal-header {
        border: none;
    }
    .modal-footer {
        text-align: center;
        .btn {
            height: 48px;
            width: 159px;
            line-height: 48px;
            text-transform: uppercase;
            font-size: 16px;
        }
        .btn-blue {
            margin-left: 30px;
        }
    }

    .evt-name {
        margin-bottom: 30px;
        font-size: 18px;
        .time {
            display: block;
        }
    }

    .qty-reject {
        margin-bottom: 20px;
        .new-usr-valid {
            margin-bottom: 5px;
        }
        span {
            font-weight: 600;
            // font-size: 16px;
            margin-right: 10px;
            display: inline-block;
            width: 275px;
            text-align: left;
        }
        .qty-ipt {
            display: inline-block;
            border-radius: 2px;
            // height: 40px;
            width: 60px;
            // font-weight: 600;
            font-size: 14px;
            border: 1px solid #ddd;
            color: #333;
            background-image: none;
            text-align: center;
            outline: none;
            // &.ng-invalid {
            //     &.ng-touched {
            //         border-color: @red;
            //     }
            //     &.ng-dirty {
            //         border-color: @red;
            //     }
            // }
            &.err {
                border-color: @red;
            }
        }
    }
    .ipt-detail-itm {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .new-usr-valid {
            width: 325px;
            padding-left: 5px;
        }
    }
}

.inform-msg {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0;
}

// Slick slider
.evt-ipt-slide {
    overflow: hidden;
    height: 100px;
    &.no-height {
        height: auto;
        padding: 0;
        margin: 0;
    }
    .evt-child-inner {
        margin-left: -15px;
        margin-right: -15px;
    }
}
.evt-itm {
    &.slick-slide {
        display: table !important;
        margin: 0 15px;
        width: 300px !important;
    }
}

.slick-arrow {
    z-index: 100;
    background-color: @blue;
    border-radius: 2px;
    &.slick-prev, &.slick-next {
        top: -35px;
        &:before {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 18px;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    &.slick-next {
        right: 15px;
        &:before {
            content: '\f054';
        }
    }
    &.slick-prev {
        right: 55px;
        &:before {
            content: '\f053';
        }
    }
    &.slick-disabled {
        background-color: #ddd;
        pointer-events: none;
        opacity: .5;
        cursor: not-allowed;
        &:before {
            color: #999;
        }
    }
}

.c-tab-ct.ipt2-tab-ct {
    padding-top: 30px;
    min-height: 300px;
}

// Machine list box
.machine-list-inner, .operator-inner, .ipt2-tab-ct {
    position: relative;
    &.loading {
        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 100;
            background-color: #fff;
            .opacity(.7);
        }
    }
}

.evt-parent-inner {
    margin-left: -15px;
    margin-right: -15px;
    &.slick-slider {
        z-index: 145;
    }
}

.evt-head-blk {
    // height: 240px;
    position: relative;
}
.evt-tab-list {
    &.expand {
        margin-top: -50px;
    }
    &.expand-1 {
        // margin-top: -170px;
    }

    .evt-ipt-lst {
        .col-lg-3 span {
            width: auto !important;
        }
    }
}


.slick-track {
    &.one-itm {
        width: 100% !important;
        padding-right: 30px;
        .evt-itm.slick-slide {
            width: 100% !important;
        }
    }
    &.col-2 {
        padding-right: 0px;
        width: 100% !important;
        .evt-itm.slick-slide {
            width: 48% !important;
        }
    }
    &.col-3 {
        padding-right: 0px;
        width: 100% !important;
        .evt-itm.slick-slide {
            width: 31.2% !important;
        }
    }
    &.col-4 {
        padding-right: 0px;
        width: 100% !important;
        .evt-itm.slick-slide {
            width: 23% !important;
        }
    }
    &.col-5 {
        padding-right: 0px;
        .evt-itm.slick-slide {
            width: 18% !important;
        }
    }
}

// Machine list modal
.machine-list-md-ct {
    height: 400px;
    position: relative;
    overflow-y: auto;
    padding: 0;
}
.machine-list-md-ct::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.machine-list-md-ct::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.machine-list-md-ct::-webkit-scrollbar-thumb
{
	background-color: rgba(0,0,0,0.3);;
}
.c-dt-table{
    border: 1px solid #ddd;
    .box-shadow(0px 2px 2px 0px rgba(0,0,0, 0.1));
    margin-bottom: 10px;
}
.machine-lst-modal {
    // padding: 0 15px;
    .machine-with-children {
        width: 100%;
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        color: #000;
    
        &:hover,
        &:active {
          color: @blue;
    
          .children-count {
            background-color: #f3f6ff;
            color: #73a0f2;
          }
        }
    
        &.is-expanded {
          .children-count {
            background-color: #f3f6ff;
            color: #73a0f2;
          }
          i {
            transform: rotate(180deg);
          }
        }
    
        .children-count {
          width: 20px;
          height: 20px;
          background-color: #dcdcdc;
          color: #7f8181;
          font-family: "Roboto", sans-serif;
          margin-left: auto;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          font-weight: 600;
          font-size: 12px;
        }
    
        i {
          margin-left: 15px;
          font-size: 20px;
          color: inherit;
        }
    }

    .base-table{
        .base-table__body {
            max-height: 270px;
        }

        .table-col {
            max-width: 10%;
            width: 10%;
            &--index{
                width: 25px;
                max-width: 25px;
            }

            &--MachineType {
                width: 100px;
                max-width: 100px;
            }
        
            &--DisplayName {
                width: 200px;
                max-width: 200px;
            }
        }
        .table-col--index {
            .table-td-inner {
                width: 20px;
            }
        } 

        .table-col--MachineType {
            .table-td-inner {
                width: 180px;
            }
            .machine-with-children {
                .machine-type-name {
                    max-width: 110px;
                }
            }
        }
        .table-col--DisplayName {
            .table-td-inner {
              width: 100%;
            }
        }
    }

    > base-table > .base-table > base-loading > .base-loading > .base-table__body {
        height: 270px;
    }
}
.machine-lst-itm {
    display: block;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 40px;
    height: 50px;
    line-height: 50px;
    &:before {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 50%;
        .transform(translateY(-50%));
        background-image: url('../../../images/icon/running.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    &:last-child {
        border-bottom: none;
    }
}

.hierarchy-btn {
    display: inline-block;
    margin-right: 10px;
    text-decoration: underline;
    color: #999;
    &:focus, &:active {
        color: #999;
    }
    &:hover {
        color: #999;
    }
}
.hierarchy-btn + .fa {
    display: inline-block;
    margin-right: 10px;
}

.hier-popup {
    padding: 15px;
    &:before {
        content: '';
        z-index: 90;
        display: block;
        width: 1px;
        background-color: #ddd;
        position: absolute;
        bottom: 3px;
        left: 20px;
        top: 25px;
    }
    .rs-itm {
        .lvl1-blk {
            i {
                font-size: 16px;
                right: 10px;
                cursor: pointer;
            }
        }
    }
    .lvl2-blk {
        i {
            font-size: 16px;
            right: 10px;
            cursor: pointer;
        }
    }
}

.h-breadcrumb {
    li {
        a{
            &.bread-hier {
                text-decoration: underline;
                &:hover {
                    color: #999;
                }
            }
        }
    }
}
body {
    &.refresh {
        .evt-head-blk, .evt-tab-list {
            display: none;
        }
    }
}

.search-evt {
    padding: 10px;
    position: relative;
    border-bottom: 1px solid #ddd;
    button {
        right: 15px;
        top: 50%;
        .transform(translateY(-50%));
        position: absolute;
        border: none;
        background-color: transparent;
    }
}