/**
 * Media 480px-
 *
 * description: css rules for screen of old school smartphone
 */
@media (max-width: 480px) {
    //login page
    .login-page{
        background-image: none;
        background-color: #000000;
    }
    .login-frm{
        &:after{
            opacity: 1;
        }
        .logo{
            width: 250px;
        }
    }

    // header
    .header{
        .usr-account {
            .usr-name{
                padding-left: 15px;
                padding-right: 15px;
                .usr-status{
                    left: 48px;
                }
                img{
                    margin-right: 0;
                }
                span{
                    display: none;
                }
            }
            .usr-menu{
                ul{
                    li{
                        a{
                            padding: 10px 5px;
                            font-size: 12px;
                            i{
                                width: 15px;
                                height: 15px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    // Event input
    .event-logo {
        margin-right: 10px;
    }
    .evt-logo-inner {
        img {
            width: 80px;
        }
    }
    .evt-ipt2 {
        [class*=' col-'] {
            width: 100%;
        }
    }
    .evt-ipt-itm {
        font-size: 14px;
    }
    // .evt-p-itm {
    //     width: 
    // }
}

@media (max-width: 380px) {
    // Event input
    .evt-logo-inner {
        img {
            width: 70px;
        }
    }
    .evt-parent, .evt-child {
        padding: 10px 0;
    }
    .evt-head-blk, .evt-ipt-lst {
        .row {
            margin-left: -10px;
            margin-right: -10px;
        }
        [class*=' col-'] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .evt-itm {
        margin-bottom: 10px;
        .evt-btn {
            font-size: 12px;
        }
    }

    .evt-ipt-itm {
        padding: 10px;
        font-size: 12px;
    }

    .evt-itm {
        &.slick-slide {
            width: 140px !important;
        }
    }
}