/**
 * CSS MANUAL INPUT v2
 * @Author: huanvt@elinext.co
 * Date: May 2020
 */
.header-title-default {
    &.subtitle-top {
        flex-direction: column-reverse;
        align-items: flex-start;
        > span {
            margin-left: 0;
        }
    }
    .header-breadcrumb {
        a, span {
            text-transform: none;
            font-size: 12px;
            line-height: 14px;
            color: #7F8181;
            font-weight: normal;
        }
        a {
            padding-right: 10px;
            &:hover {
                text-decoration: underline;
            }
        }
        .fa {
            color: #7F8181;
            padding-right: 10px;
            font-size: 18px;
            vertical-align: -2px;
        }
    }
}
.machine-list-modal {
    .modal-dialog {
        width: 790px;
    }
    .machine-lst-modal {
        height: 600px;
        overflow: hidden;
        overflow-y: auto;
    }
    .machine-filter-wrap {
        group-filter {
            display: block;
            .search-machine {
                left: auto;
                right: 0;
                top: 100%;
                width: 380px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            }
        }
    }
    .machine-list-md-ct {
        height: auto;
        overflow: hidden;
        min-height: 570px;
    }
}
.machine-filter-wrap {
    padding: 20px;
    .row {
        margin-left: -7.5px;
        margin-right: -7.5px;
        [class*="col"] {
            padding-left: 7.5px;
            padding-right: 7.5px;
        }
    }
    .machine-header {
        padding: 0;
        border-bottom: 0;
        height: auto;
        margin-top: 0;
        .mc-btn {
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
            background-color: #f5f5f5;
            border-radius: 5px;
        }
    }
}
.machine-lst-modal {
    padding: 0 40px 20px 40px;
    .machine-item {
        display: block;
        .inner {
            background-color: #F3F6FF;
            border: 1px solid #DCDCDC;
            border-radius: 3px;
            height: 60px;
            margin-bottom: 2px;
            display: flex;
            width: 100%;
            align-items: center;
        }
        .collapse-arr {
            cursor: pointer;
            max-width: 56px;
            flex: 0 0 56px;
            position: relative;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: #7F8181;
            &::after {
                position: absolute;
                content: '';
                display: inline-block;
                width: 2px;
                height: 34px;
                background-color: #DCDCDC;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                right: 0;
            }
            &.not-child {
                pointer-events: none;
                cursor: default;
                .fa {
                    display: none;
                }
            }
        }
    }
}
.machine-list-modal {
    .machine-item {
        .machine-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: 25px;
            padding-right: 15px;
        }
        .select-machine {
            color: #005AFF;
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            .fa {
                margin-left: 10px;
                font-size: 22px;
                vertical-align: -2px;
            }
        }
        .machine-content {
            display: flex;
            align-items: center;
        }
        .ttl {
            color: #333;
            font-size: 14px;
            font-weight: normal;
            padding-left: 15px;
            margin-bottom: 0;
            margin-top: 0;
        }
        .line-name-icon, .machine-name-icon, .logicalmachine-name-icon {
            filter: opacity(0.5);
        }
        .logicalmachine-name-icon {
            width: 24px;
        }
    }
}
.machine-list-modal {
    .sub-machine {
        .machine-item {
            padding-left: 50px;
            .inner {
                background-color: #F5F5F5;
            }
        }
    }
}
.dashboard-manual-input {
    .evt-parent, .evt-child {
        padding-top: 20px;
    }
    .chart-ttl {
        > span {
            left: 0;
        }
    }
    .btn {
        border-radius: 3px;
        height: 40px;
        &.btn-success {
            background-color: #10A81F;
            border-color: #10A81F;
            &:hover {
                background-color: darken(#10A81F, 10%);
            }
        }
        &.btn-primary {
            background-color: #005AFF;
            border-color: #005AFF;
            text-transform: uppercase;
            &:hover {
                background-color: darken(#005AFF, 10%);
            }
            .fa {
                padding-left: 10px;
                margin-left: 10px;
                font-size: 14px;
                border-left: 1px solid #fff;
            }
        }
    }
}
.change-over-step {
    padding-top: 20px;
    padding-bottom: 40px;
    .inner {
        display: flex;
        align-items: center;
    }
    .step-item {
        // cursor: pointer;
        text-transform: uppercase;
        background-color: #f5f5f5;
        border-radius: 4px;
        position: relative;
        font-family: 'Open Sans';
        font-weight: 800;
        color: #7F8181;
        height: 49px;
        width: 278px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 18px;
        &::before, &::after {
            content: '';
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
        }
        &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6.5px 0 6.5px 6px;
            border-color: transparent transparent transparent #fff;
            left: 0;
        }
        &::after {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6.5px 0 6.5px 6px;
            border-color: transparent transparent transparent #f5f5f5;
            right: -6px
        }
    }
}
.change-over-step {
    .step-item {
        &:last-child {
            margin-right: 0;
            &::after {
                display: none;
            }
        }
        &:first-child {
            &::before {
                display: none;
            }
        }
        &.current {
            background-color: #005AFF;
            color: #fff;
            &::after {
                border-color: transparent transparent transparent #005AFF;
            }
            td:not(.bg-eee):not(.bg-f7):not(.bg-fb):not(.bg-eefaff) {
                background-color: transparent !important;
            }
        }
        &.active {
            background-color: #ABE3B0;
            color: #10A81F;
            &::before {
                display: none;
            }
            &::after {
                border-color: transparent transparent transparent #ABE3B0;
            }
            td:not(.bg-eee):not(.bg-f7):not(.bg-fb):not(.bg-eefaff) {
                background-color: transparent !important;
            }
        }
    }
}
.change-over-step {
    .step-item {
        .fa-check-circle {
            font-size: 16px;
            margin-right: 10px;
            display: none;
        }
        &.active {
            .fa-check-circle {
                display: inline-block;
            }
        }
    }
}
.table-shadow {
    .table-th-inner {
        display: flex;
        align-items: center;
        .table-th__label {
            font-size: 12px;
            line-height: 14px;
            color: #7F8181;
            margin: 2px 0px;
            font-weight: normal;
            word-break: break-word;
            &.mb {
                display: none;
            }
        }
        .table-th__icon-sorts {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            color: #7f8181;
            i {
                line-height: 0.55;
            }
        }
    }
}
.table-shadow {
    border: none;
    box-shadow: none;
    .c-tb-th {
        border: none;
    }
    .table-cus {
        tr {
            th {
                span {
                    padding: 0;
                }
            }
        }
    }
    .no-data {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        td {
            padding-top: 20px !important;
            padding-bottom: 20px !important;
            border-bottom: none !important;
        }
    }
    .table-wrap-responsive {
        base-loading {
            width: 100%;
            .base-loading {
                width: 100%;
            }
        }
    }
}
.table-shadow {
    .table-tr {
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        &:hover {
            td:not(.disabled) {
                background-color: #fff !important;
            }
            td.disabled {
                background-color: #cfcfcf !important;
            }
        }
        td {
            // padding: 10px !important;
            border-bottom: none !important;
            font-size: 14px;
            line-height: 18px;
            color: #000;
        }

        &.other-section {
            background: #FFFAEB;
            outline: 1px solid #FFC81F;
        }
    }
    th, td {
        padding: 10px !important;
        &.disabled, &.disabled:hover {
            background-color: #cfcfcf !important;
            cursor: not-allowed;
            .table-th__label,.form-control {
                color: lighten(#7F8181,10%);
                background: #cfcfcf;
            }
        }
    }
    .table-empty {
        td {
            padding: 5px 10px !important;
            border-bottom: none !important;
        }
        &:hover {
            td {
                background-color: transparent !important;
            }
        }
    }

    &.time-study-mode {
        .table-tr,.table-changeover-item {
            &.other-section {
                background: #ECF9ED;
                outline: 1px solid #10A81F;
                &.missed {
                    background: #FEF1F3;
                    outline: 1px solid #E81A3C;
                }
                &.skip {
                    background: #F5F5F5;
                    outline: 1px solid #DCDCDC;
                }
            }
        }
    }
}
.changeover-wrap {
    .table-shadow {
        td {
            .form-control {
                width: 100px;
                height: 40px;
                background-color: #F5F5F5;
                border-radius: 3px;
                border: none;
                box-shadow: none;
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
    }
}
.parameter-review {
    .actions-wrap {
        margin-top: 20px;
    }
    .table-shadow {
        th {
            &.th-target-min, 
            &.th-target-max,
            &.th-actual,
            &.th-uom {
                width: 140px;
            }
        }
    }
    .table-ft {
        border-top: none;
    }
}
.pre-setup {
    .sl-product {
        width: 280px;
        margin-bottom: 20px;
       .form-control {
            background-color: #f5f5f5;
            border: none;
            text-align: start;
       }
    }
    .txt_area {
        height: 190px;
        margin-top: 12px;
    }

   
}
.pre-setup-prod-modal {
    .nr-tag-list a {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }

    .nr-search-frm {
        button {
            top: 8px;
        }

        .ipt {
            border: none;
            background: #F5F5F5;
            border-radius: 3px;
            height: 40px;
        }
    } 
}
.post-setup {
    .txt_area {
        height: 190px;
    }
    .table-cus {
        th {
            &.th-desc {
                width: 260px;
            }
            &.th-attachments {
                width: 130px;
            }
            &.th-completed {
                width: 215px;
            }
        }
        .comment-blk {
            display: flex;
            justify-content: center;
            align-items: center;
            i.fa {
                color: #dcdcdc;
                font-size: 24px;
            }
            .see-comment {
                font-family: Rubik;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                /* identical to box height */
                color: #7F8181;
                margin-left: 9px;
                text-decoration-line: underline;
                text-decoration-style: dotted;
                &:hover {
                    color: #005AFF;
                }
            }
        }
    }
}
.changeover-wrap {
    .txt_area {
        width: 100%;
        border-radius: 3px;
        background-color: #f5f5f5;
        outline: none;
        border: none;
        padding: 14px;
        resize: none;
        white-space: nowrap;
        &.other-section {
            background: #FFFAEB;
            outline: 1px solid #FFC81F;
        }
    }
    .table-cus tr td:not(.bg-eee):not(.bg-f7):not(.bg-fb):not(.bg-eefaff){
        background-color: transparent !important;
    }
}
.attachments-wrap {
    position: relative;
    img {
        width: 40px;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        border: 1px solid #fff;
        &:hover {
            border-color: #005AFF;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
            position: absolute;
            z-index: 1;
        }
        &:nth-of-type(2) {
            left: 15px;
        }
        &:nth-of-type(3) {
            left: 30px;
        }
    }
}
.attach-more {
    color: #7F8181;
    font-size: 12px;
    line-height: 14px;
    border-bottom: 1px dashed #7F8181;
    cursor: pointer;
    position: absolute;
    left: 80px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
        color: #005AFF;
    }
}
.dashboard-manual-input {
    .radio-custom {
        display: none;
        + label  {
            cursor: pointer;
            font-family: Rubik;
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 0;
            color: #000;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            .mask {
                width: 22px;
                height: 22px;
                position: relative;
                border: 3px solid #DCDCDC;
                border-radius: 50%;
                display: inline-block;
                vertical-align: -6px;
                padding-right: 8px;
                &::after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background-color: #bbb;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    z-index: 1;
                    display: none;
                }
            }
        }
        &:checked {
            + label {
                .mask {
                    &::after {
                        display: block;
                    }
                }
            }
        }
    }
}
.dashboard-manual-input {
    .radio-custom {
        &.red {
            + label  {
                .mask {
                    &::after {
                        background-color: #E81A3C;
                    }
                }
            }
            &:checked {
                + label {
                    .mask {
                        border-color: #F8B5C0;
                    }
                }
            }
        }
        &.green {
            + label  {
                .mask {
                    &::after {
                        background-color: #10A81F;
                    }
                }
            }
            &:checked {
                + label {
                    .mask {
                        border-color: #ABE3B0;
                    }
                }
            }
        }
        &.blue {
            + label  {
                .mask {
                    &::after {
                        background-color: #005aff;
                    }
                }
            }
            &:checked {
                + label {
                    .mask {
                        border-color: rgba(0, 90, 255, 0.5);
                    }
                }
            }
        }
    }
}
.dashboard-manual-input {
    .alert-danger {
        background-color: #E81A3C;
        border-radius: 3px;
        border: none;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        padding: 12px;
        position: relative;
        .ttl {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 0;
            max-width: calc(~'100% - 30px');
        }
        .close {
            background-color: transparent;
            border: none;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            padding: 0;
        }
    }
}
.changeover-wrap  {
    .col-left {
        padding-right: 0;
    }
    .custom-select {
        position: relative;
        &::after {
            content: '\f0d7';
            display: inline-block;
            font-family: 'FontAwesome';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            color: #555;
        }
        &.green {
            .form-control {
                background-color: #ABE3B0;
                border: none;
                color: #10A81F;
            }
            &::after {
                color: #10A81F;
            }
        }
        &.red {
            .form-control {
                background-color: #F8B5C0;
                border: none;
                color: #E81A3C;
            }
            &::after {
                color: #E81A3C;
            }
        }
    }
    .form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
}
.group-radio {
    &.mb {
        display: none;
    }
}
.post-setup {
    .custom-select {
        width: 100px;
    }
}
.modal-changeover-attachments {
    .modal-dialog {
        max-width: 1020px;
    }
    .modal-content {
        padding: 20px 60px;
        border: none;
        box-shadow: none;
        min-height: 200px;
    }
}
.main-attachments {
    margin-bottom: 30px;
    iframe {
        min-height: 565px;
    }
    .slick-arrow {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: #F3F6FF;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s ease;
        &:hover {
            background-color: #005AFF;
            &::before {
                color: #fff;
            }
        }
        &::before {
            transition: all .2s ease;
            color: #005AFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
        &.slick-prev {
            right: auto;
            left: -32px;
        }
        &.slick-next {
            right: -32px;
        }
    }
}
.nav-attachments {
    width: 460px;
    margin: 0 auto;
    .slide {
        width: 100px;
        border: 1px solid transparent;
        margin-right: 20px;
        img {
            border-radius: 3px;
        }
        &:last-child {
            margin-right: 0;
        }
        &.slick-current {
            border-color: #005AFF;
        }
    }
}
.time-control {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    .loading-wrapper {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
    }
    .btn {
        font-size: 12px;
        outline: none;
        .fa {
            margin-right: 5px;
        }
        &#pause {
            .stop {
                display: none;
            }
            &.pause {
                background-color: #E81A3C;
                border-color: #E81A3C;
                &:hover {
                    background-color: darken(#E81A3C, 10%);
                }
                .start {
                    display: none;
                }
                .stop {
                    display: inline-block;
                }
            }
            &.play {
                .start {
                    display: inline-block;
                }
                .stop {
                    display: none;
                }
            }
        }
    }
    .ico-clock {
        background-image: url('../../images/manual-input/ico-clock.png');
        display: inline-block;
        width: 12px;
        height: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 3px;
    }
    // .btn-countdown {
    //     &.danger {
    //         animation: shake 0.8s;
    //         animation-iteration-count: infinite;
    //         animation-timing-function: linear;
    //         transform-origin: 50% 50%;
    //     }
    // }
}
.time-control {
    .time-display {
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid #DCDCDC;
        .ttl {
            font-family: 'Open Sans', sans-serif;
            font-weight: 800;
            font-size: 18px;
            line-height: 28px;
            color: #000;
            margin-bottom: 3px;
        }
        .time {
            font-size: 16px;
            line-height: 20px;
            color: #000;
        }
    }
    .time-countdown {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.time-control {
    .time-circle {
        width: 300px;
        margin: 0 auto;
        margin-bottom: 60px;
        position: relative;
        svg {
            width: 300px;
        }
    }
    .controlls {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .remain-time {
        color: #7F8181;
        .ttl {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 0;
        }
        .time {
            font-size: 16px;
            line-height: 18px;
        }
    }
    .display-remain-time {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 70px;
        color: #000;
        &.danger {
            color: #E81A3C;
        }
    }
    .e-c-base {
        fill: none;
        stroke: #ABE3B0;
        stroke-width: 15px
    }
      
    .e-c-progress {
        fill: none;
        stroke: #10A81F;
        stroke-width: 15px;
        transition: stroke-dashoffset 0.7s;
    }
    .circle {
        &.warning {
            .e-c-base {
                stroke: #FFF5D6;
            }
              
            .e-c-progress {
                stroke: #FFC81F;
            }
        }
        &.danger {
            animation: shake 0.8s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            transform-origin: 50% 50%;
            .e-c-base {
                stroke: #F8B5C0;
            }
              
            .e-c-progress {
                stroke: #E81A3C;
            }
        }
    }

    #e-pointer { transition: transform 0.7s; }
}

.time-control {
    &.grey {
        background-color: #F5F5F5;
        border: 1px solid #DCDCDC;
    }
    .inner {
        padding: 15px;
        >* + * {
            margin-top: 16px;
        }
    }
    .control-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .lb-b {
            font-family: Rubik;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            /* Text / black */
            color: #000000;
        }

        .lb {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            /* Text / black */
            color: #000000;
        }
        .custom-mat-toggle {
            >label {
                margin-bottom: 0px;
            }
        }

        .btn {
            height: 50px;
            width: 100%;
            padding: 0px;
            min-width: auto;
            &.red {
                background-color: #E81A3C;
                border-color: #E81A3C;
                color: #fff;
                &:hover {
                    background-color: darken(#E81A3C, 10%);
                }
            }

            &.grey {
                background-color: #DCDCDC;
                border-color: #DCDCDC;
                color: #000000;
                &:hover {
                    background-color: darken(#DCDCDC, 10%);
                }
            }
        }
        .divider {
            height: 1px;
            background-color: #DCDCDC;
        }
    }

    .step-control{
        margin-left: -15px;
        margin-right: -15px;
        .item-wrap {
            float: left;
            width: 50%;
            padding: 0px 15px;
        }
    }

}
.changeover-wrap {
    &.setup {
        .table-shadow {
            min-height: ~'calc(100vh - 173px)';
        }
        .time-countdown {
            min-height: ~'calc(100vh - 440px)';
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}
.setup-table-step {
    .table-shadow {
        background-color: #F3F4F5;
        padding: 5px;
        box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 3px 0px 0px 3px;
        .th-attachments {
            width: 20%;
        }
        .table-tr {
            background-color: transparent;
            box-shadow: none;
            &.current {
                background-color: #005AFF;
                td {
                    color: #fff;
                }
            }
            &.active {
                background-color: #73A0F2;
                td {
                    color: #fff;
                }
            }
            td {
                border-bottom: 1px solid #DCDCDC !important;
            }
            &:hover {
                td {
                    background-color: transparent !important;
                }
            }
            &:last-child {
                td {
                    border-bottom: none !important;
                }
            }
        }

        &.time-study-mode {
            .table-tr {
                &.current {
                    background-color: #10A81F;
                    .blk-activity.comment-include {
                        .btn-comment {
                            i.fa {
                                color: #10A81F;
                            }
                
                            &:hover {
                                i.fa {
                                    color:darken(#10A81F, 10%) ;
                                }
                            }
                        }
                    }
                }

                &.finish {
                    background-color: #E81A3C;
                    td {
                        color: #fff;
                    }
                    .blk-activity.comment-include {
                        .btn-comment {
                            i.fa {
                                color: #E81A3C;
                            }
                
                            &:hover {
                                i.fa {
                                    color:darken(#E81A3C, 10%) ;
                                }
                            }
                        }
                    }
                }
                
                &.skip {
                    background-color: #DCDCDC;
                    .blk-activity.comment-include {
                        .btn-comment {
                            i.fa {
                                color: #DCDCDC;
                            }
                
                            &:hover {
                                i.fa {
                                    color:darken(#DCDCDC, 10%) ;
                                }
                            }
                        }
                    }
                }

                &.active {
                    background-color: #ABE3B0;
                    td {
                        color: #000000;
                    }
                    .blk-activity.comment-include {
                        .btn-comment {
                            i.fa {
                                color: #ABE3B0;
                            }
                
                            &:hover {
                                i.fa {
                                    color:darken(#ABE3B0, 10%) ;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .client-tb {
        th {
            &.th-step {
                width: 100px;
            }
            &.th-sub-step {
                width: 100px;
            }
            &.th-category {
                width: 130px;
            }
        }
    }
}
.setup-right {
    max-height: calc(~'100vh - 176px');
    overflow-y: auto;

    .setup-table-step {
        padding-right: 0;
    }
    .setup-table-duration {
        padding-left: 0;
        position: relative;
        .table-shadow {
            min-height: auto !important;
        }
    }
    .timeline {
        position: absolute;
        top: 40px;
        bottom: 20px;
        width: 2px;
        background-color: #E81A3C;
        z-index: 10;
        left: 10px;
        display: none;
        &::before {
            content: '';
            display: inline-block;
            background-image: url('../../images/manual-input/arr-timeline.png');
            width: 10px;
            height: 10px;
            position: absolute;
            top: -2px;
            left: -4px;
            z-index: 1;
            background-size: cover;
        }
    }
}
.duration-wrap {
    overflow: hidden;
    overflow-x: auto;
    background-color: #FFFFFF;
    border: 2px solid #F3F4F5;
    border-radius: 5px;
    min-height: ~'calc(100vh - 208px)';
    .duration-list {
        min-width: 100%;
        // min-height: ~'calc(100vh - 210px)';
    }
    .duration-item {
        height: 25px;
        line-height: 25px;
        color: #fff;
        text-align: center;
        border-radius: 3px;
        background-color: #005AFF;
        position: relative;
        overflow: hidden;
        min-width: 30px;
        span {
            &.progress-bar {
                background-color: #73A0F2;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                z-index: 1;
            }
            &.time-text {
                position: relative;
                z-index: 2;
            }
        }
        &.seconds-item {
            span {
                &.time-text {
                    font-size: 12px;
                }
            }
        }

        &.active {
            background-color: #73A0F2;
        }
    }
    &.time-study-mode {
        .duration-item {
            background-color: #10A81F;
            &.active, &.skip, &.finish {
                span {
                    &.progress-bar {
                        display: none;
                    }
                }
            }
            &.active {
                background-color: #ABE3B0;
                color: #000000;
            }
            &.skip {
                background-color: #DCDCDC;
                color: #000000;
            }
            &.finish {
                background-color: #E81A3C;
            }
        }
    }
    .table-shadow {
        .table-tr {
            box-shadow: none;
            td {
                border-bottom: 1px solid #DCDCDC !important;
            }
            &:first-child {
                td {
                    border-top: 1px solid #DCDCDC !important;
                }
            }
            &:last-child {
                td {
                    border-bottom: none !important;
                }
            }
        }
    }
}
.changeover-wrap.setup {
    .blk-activity {
        height: 46px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .duration-wrap {
        .blk-activity {
            display: flex;
            align-items: center;
        }
    }
    .setup-table-duration {
        .table-shadow {
            thead {
                tr {
                    th {
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }
    }

    .blk-activity.comment-include {
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn-comment {
            max-width:  46px;
            min-width: auto;
            flex: 0 0 46px;
            height: 46px;
            padding: 0px;
            background-color: #FFFFFF;
            margin-left: 10px;
            border-radius: 3px;
            i.fa {
                color: #005AFF;
                font-size: 18px;
            }

            &:hover {
                i.fa {
                    color:darken(#005AFF, 10%) ;
                }
            }
        }
    }
}

.comment-modal{
    .modal-content {
        border: none;
        box-shadow: none;
        min-height: 200px;
        .content-inner{
            padding: 20px;
        }
        textarea {
            width: 100%;
            border-radius: 3px;
            background-color: #f5f5f5;
            outline: none;
            border: none;
            padding: 14px;
            resize: auto;
        }
    }

}

.mbt-30 {
    margin-bottom: 30px;
}
.mbt-50 {
    margin-bottom: 50px;
}
.attach-ttl {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 12px;
    position: relative;
    .count-item {
        text-transform: none;
        font-size: 14px;
        line-height: 18px;
        color: #7F8181;
        margin-left: 5px;
        font-family: Rubik;
        font-weight: normal;
    }
    .nav-item-action-group {
        position: absolute;
        right: 0px;
        .btn {
            width: 24px;
            height: 24px;
            min-width: 24px;
            padding: 0px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #fff;
        }
    }
}
.group-attachments {
    .dropzone {
        &.dz-wrapper {
            width: 100%;
            display: block;
            min-height: auto;
            .dz-message {
                height: 63px;
                background-color: #F5F5F5;
                border-color: #DCDCDC;
                border-radius: 3px;
                display: block;
            }
            &.dz-started {
                .dz-message {
                    .dz-text {
                        opacity: 0;   
                    }
                }
            }
            .dz-preview {
                position: absolute;
                top: 12px;
                left: 0;
                right: 0;
                z-index: 1;
                width: auto;
                .dz-progress {
                    height: 6px;
                    border: none;
                    background-color: #C4C4C4;
                    border-radius: 3px;
                    overflow: hidden;
                    margin-top: -3px;
                    .dz-upload {
                        background-color: #10A81F !important;
                    }
                }
                .dz-image, .dz-details {
                    display: none !important;
                }
                .dz-error-message {
                    min-height: 40px;
                    width: 90%;
                }
            }
        }
    }
    .head-text {
        font-size: 14px;
        line-height: 18px;
        color: #7F8181;
        font-weight: 500;
        margin-bottom: 0;
        span {
            color: #005AFF;
        }
    }
    .last-text  {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 16px;
        color: #7F8181;
    }
}
.attachments-list {
    margin-top: 10px;
    .attachment-item {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    i {
        margin-right: 10px;
        background-repeat: no-repeat;
        display: inline-block;
        background-size: cover;
        vertical-align: middle;
        &.close {
            background-image: url('../../images/manual-input/ico-close.png');
            width: 9px;
            height: 9px;
            cursor: pointer;
            &:hover {
                background-image: url('../../images/manual-input/ico-close-red.png');
            }
        }
        &.check {
            background-image: url('../../images/manual-input/ico-check.png');
            width: 18px;
            height: 18px;
        }
    }
    .name {
        font-size: 14px;
        line-height: 18px;
        color: #000;
        cursor: pointer;
        word-break: break-all;
        &:hover {
            color: #005AFF;
            border-bottom: 1px dashed #005AFF;
        }
    }
}
.evt-itm.slick-slide {
    &.hide {
        display: none !important;
    }
}
.negative {
    display: none;
    margin-right: -10px;
}
.display-mb {
    display: none;
}
.setup-summary {
    background-color: #ABE3B0;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    display: inline-block;
    margin-bottom: 20px;
    span {
        display: inline-block;
        &.ttl { 
            text-transform: uppercase;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 2px solid #000;
            font-weight: 500;
        }
        &.txt {
            padding-right: 20px;
        }
    }
    .red {
        color: #E81A3C;
    }
    &.warning {
        background-color: #FCE3E7;
    }
}
.changeover-wrap {
    &.summary {
        position: relative;
        .time-circle {
            margin-bottom: 0;
        }
        .table-wrap-responsive {
            &.set-height {
                height: 310px;
                overflow-y: auto;
            }
        }
        .table-cus {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .btn-close-setup {
        text-transform: uppercase;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: -80px;
        z-index: 1;
    }
}

.changeover-wrap {
    &.summary {
        .table-cus tr:first-child th {
            &.th-desc {
                width: 300px;
            }
        }
        .dashboard-manual-input .radio-custom + label {
            margin-right: 10px;
        }
    }
}
.mbt-10 {
    margin-bottom: 10px !important;
}
.mbt-20 {
    margin-bottom: 20px !important;
}
.mbt-0 {
    margin-bottom: 0px !important;
}
#emt-modal-merge-changeover {
    font-size: 16px;
    .modal-footer {
        text-align: center;
    }
    .changeover-merge-item {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .mask {
            margin-right: 10px;
        }
    }
    .btn-primary {
        background-color: #005aff;
        &:hover {
            background-color: darken(#005aff, 10%);
        }
    }
    .btn {
        outline: none;
    }
}
.selected-machine {
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;
}
#emt-form-select-modal {
    .running {
        display: none;
        color: @red;
        animation: blinker 1s linear infinite;
        &.shw {
            display: inline-block;
        }
    }
}
@-webkit-keyframes blinker {
    50% {
      opacity: 0;
    }
}
@-moz-keyframes blinker {
    50% {
      opacity: 0;
    }
}
@keyframes blinker {
    50% {
      opacity: 0;
    }
}
// CSS RESPONSIVE
@media (max-height: 800px) {
    .duration-wrap {
        min-height: 560px;
    }
    .changeover-wrap.setup {
        .table-shadow {
            min-height: 597px;
        }
    }
}
@media (max-width: 1700px) {
    .changeover-wrap {
        .btn-close-setup {
            position: relative;
            margin-bottom: 10px;
            top: auto;
            float: right;
        }
        &.summary {
            .row {
                clear: both;
            }
        }
    }
    .post-setup {
        .table-cus {
            th {
                &.th-desc {
                    width: 240px;
                }
                &.th-completed {
                    width: 190px;
                }
            }
        }
    }
    .dashboard-manual-input {
        .radio-custom {
            + label {
                margin-right: 10px;
            }
        }
    }
    .changeover-wrap  {
        .col-left {
            width: 75%;
        }
        .col-right {
            width: 25%;
        }
    }
}
@media (max-width: 1500px) {
    .post-setup {
        .table-cus {
            th {
                &.th-desc {
                    width: 220px;
                }
                &.th-step {
                    width: 80px;
                }
            }
        }
    }
    .duration-wrap {
        min-height: 560px;
    }
    .changeover-wrap.setup {
        .table-shadow {
            min-height: 597px;
        }
    }
}
@media (max-width: 1440px) {
    .post-setup {
        .table-cus {
            th {
                &.th-desc {
                    width: 200px;
                }
                &.th-duration {
                    width: 110px;
                }
                &.th-section {
                    width: 90px;
                }
            }
        }
    }
    .changeover-wrap {
        &.summary {
            .time-circle {
                width: 100%;
            }
            .time-control .time-circle svg {
                width: 90%;
            }
        }
    }
}
@media (max-width: 1366px) {
    .post-setup {
        .table-cus {
            th {
                &.th-desc {
                    width: 140px;
                }
                &.th-attachments {
                    width: 112px;
                }
            }
        }
    }
    .attachments-wrap {
        img {
            &:nth-of-type(2) {
                left: 10px;
            }
            &:nth-of-type(3) {
                left: 20px;
            }
        }
    }
    .attach-more {
        left: 65px;
    }
}
@media (max-width: 1300px) {
    .main .margin-lft {
        margin-left: 0 !important;
    }
}
@media (max-width: 1200px) {
    .pre-setup {
        .table-cus {
            th {
                &.th-desc {
                    width: 300px;
                }
            }
        }
    }
    .setup-right {
        padding-left: 0;
        .setup-table-step, .setup-table-duration {
            width: 50%;
        }
        .setup-table-step {
            th, td {
                // &:nth-of-type(2),
                &:nth-of-type(3) {
                    display: none !important;
                }
            }
        }
    }
    .time-control {
        .time-circle {
            svg {
                width: 250px;
            }
        }
        .display-remain-time {
            font-size: 36px;
            line-height: 50px;
        }
    }
}
@media (max-width: 1024px) {
    // .setup-right {
    // max-height: calc(~'100vh - 255px');
    // }
    .group-radio {
        &.mb {
            display: block;
        }
        &.pc {
            display: none;
        }
    }
    .post-setup {
        .table-cus {
            th {
                &.th-completed {
                    width: 120px;
                }
                &.th-desc {
                    width: 30%;
                }
                &.th-step {
                    width: 60px;
                }
            }
        }
    }
    .table-shadow {
        .table-th-inner {
            .table-th__label {
                &.pc {
                    display: none;
                }
                &.mb {
                    display: inline-block;
                }
            }
        }
    }
    .changeover-wrap  {
        .col-left, .col-right {
            width: 100%;
        }
        .col-left {
            margin-bottom: 20px;
            padding-right: 15px;
        }
        .txt_area {
            margin-top: 0;
        }
    }
    .table-wrap-responsive {
        overflow: hidden;
        overflow-x: auto;
        .table-cus {
            padding-right: 5px;
            padding-left: 5px;
            
        }
    }
    // .post-setup {
    //     .txt_area {
    //         height: 220px;
    //     }
    // }
    .change-over-step {
        overflow: hidden;
        overflow-x: auto;
        .step-item {
            font-size: 14px;
            width: 208px;
        }
    }
    .time-control {
        .time-countdown {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .time-display {
            .col-md-6 {
                width: 100%;
                &:first-child {
                    margin-bottom: 15px;
                }
            }
        }
        .time-circle {
            width: 100%;
            margin-bottom: 40px;
        }
    }
    .changeover-wrap.setup {
        .time-countdown {
            min-height: 380px;
        }
    }
    .setup-summary {
        display: block;
    }
    .changeover-wrap {
        &.summary {
            .summary-left, .summary-right {
                width: 100%;
            }
            .summary-left {
                margin-bottom: 30px;
            }
        }
    }
    .changeover-wrap {
        &.summary {
            .time-control .time-circle svg {
                width: 300px;
            }
        }
    }
}
.summary-blk {
    margin-bottom: 30px;
    color: #000;
    .summary-ttl {
        font-weight: 800;
        font-size: 18px;
        line-height: 26px; 
        text-transform: uppercase;
        .count {
            text-transform: none;
            font-size: 14px;
            line-height: 18px;
            font-weight: normal;
            color: #7F8181;
            margin-left: 5px;
        }
    }
}
.changeover-wrap {
    .box-white {
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
}
.blk-changeover-setup {
    font-size: 16px;
    line-height: 20px;
    .box-white {
        padding: 15px;
        position: relative;
        border-left: 8px solid #ABE3B0;
    }
    .fa-long-arrow-right {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        vertical-align: middle;
    }
}
.bold {
    font-weight: bold;
}

.timestudy-check {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    color: #7F8181;
    &.green {
        color: #10A81F;
    }
}

.color-scheme-info {
    display: inline-block;
    margin-left: 30px;
    vertical-align: middle;
    height: 40px;
    margin-bottom: 20px;
    *+* {
        margin-left: 32px;
    }
    .color-item {
        float: left;
        padding-top: 15px;
        >.info {
            font-family: Rubik;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-left: 8px;
            vertical-align: bottom;
        }
    }
    .color-box {
        width: 60px;
        height: 23px;
        display: inline-block;
        vertical-align: text-bottom;
    }
}
@media (max-width: 840px) {
    .time-control .time-circle svg {
        width: 200px;
    }
}

@media (max-width: 1024px) {
    .color-scheme-info {
        margin-left: 0px;
    }
}

@media (max-width: 992px) {
    .table-wrap-responsive {
        .table-cus {
            width: 990px;
        }
    }
    .dashboard-manual-input {
        .alert-danger {
            font-size: 12px;
        }
        .ttl {
            font-size: 16px;
        }
    }
    .setup-table-step {
        .table-wrap-responsive {
            .table-cus {
                width: 700px;
            }
        }
    }
    
    .changeover-wrap  {
        .table-shadow td {
            .custom-select {
                .form-control {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 900px) {
    .change-over-step .inner {
        width: 900px;
    }
    .time-control {
        .step-control{
            margin-left: -6px;
            margin-right: -6px;
            .item-wrap {
                padding: 0px 6px;
            }
        }
    }
}
@media (max-width: 800px) {
    .machine-list-modal .modal-dialog {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .machine-filter-wrap {
        padding: 15px;
    }
    .machine-list-modal {
        .machine-lst-modal {
            padding: 0px 15px;
            height: calc(~'100vh - 220px');
        }
        .machine-item {
            .inner { 
                height: 50px;
            }
            .collapse-arr { 
                max-width: 45px;
                height: 50px;
                flex: 0 0 45px;
            }
            .machine-info {
                padding-left: 10px;
                padding-right: 10px;
            }
            .sub-machine .machine-item {
                padding-left: 40px;
            }
        }
        .line-name-icon,.machine-name-icon {
            margin-right: 0px;
        }
        .line-name-icon {
            width: 50px;
            height: 15px;
        }
        .machine-name-icon {
            width: 24px;
        }
    } 
}
@media (max-width: 767px) {
    .time-control .time-circle svg {
        width: 250px;
    }
    .changeover-wrap {
        &.setup {
            .setup-right {
                display: none;
            }
        }
    }
    .time-control {
        .time-display {
            .col-md-6 {
                width: 50%;
            }
        }
    }
    .change-over-step {
        padding-bottom: 20px;
        .inner {
            width: auto;
        }
        .step-item {
            text-transform: capitalize;
            font-weight: normal;
            font-size: 12px;
            width: auto;
            padding-left: 10px;
            padding-right: 10px;
            height: 30px;
            &.active {
                .fa-check-circle {
                    display: none;
                }
            }
        }
    }
    .display-pc {
        display: none;
    }
    .display-mb {
        display: block;
    }
    .table-changeover-item {
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .inner {
            padding: 15px;
        }
        .title {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;
        }
        .desc,.duration {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 15px;
        }
        .attachments-wrap {
            margin-bottom: 30px;
        }
        * + .group-radio  {
            margin-top: 15px;
        }

        &.other-section {
            background: #FFFAEB;
            outline: 1px solid #FFC81F;
        }
        .comment-blk {
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 15px;
            i.fa {
                color: #dcdcdc;
                font-size: 24px;
            }
            .see-comment {
                font-family: Rubik;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                /* identical to box height */
                color: #7F8181;
                margin-left: 9px;
                text-decoration-line: underline;
                text-decoration-style: dotted;
                &:hover {
                    color: #005AFF;
                }
            }
        }
    }
    
    .dashboard-manual-input {
        .btn-next {
            display: block;
            margin: 0 auto;
        }
    }
    .header {
        .notify {
            display: none;
        }
    }
    .usr-account {
        display: none;
    }
    .header-title-default {
        font-size: 16px;
        &.subtitle-top {
            align-items: center; 
        }
        .header-breadcrumb {
            display: none;
        }
    }
    .blk-changeover-setup {
        .box-white {
            font-size: 14px;
        }
    }
    .changeover-wrap {
        .btn-close-setup {
            float: left;
        }
    }
}

@media (max-width: 515px) {
    .color-scheme-info {
        margin-left: 0px;
        *+* {
            margin-left: 14px;
        }
        .color-item {
            >.info {
                font-size: 13px;
                line-height: 13px;
                margin-left: 5px;
            }
        }
        .color-box {
            width: 15px;
            height: 15px;
        }
    }
}

@media (max-width: 480px) {
    .change-over-step {
        .inner {
            display: block;
            width: 500px;
        }
        .step-item {
            float: left;
        }
    }

    .machine-list-modal .machine-filter-wrap group-filter .search-machine {
        width: 100%;
    }
    .machine-list-modal {
        group-filter .search-rs-ct {
            height: 250px;
        }
        .machine-list-md-ct {
            min-height: 450px;
        }
    }
}
@media (max-width: 440px) {
    .pre-setup {
        .sl-product {
            width: 100%;
        }
    }
    .dashboard-manual-input {
        .btn-next {
            width: 100%;
        }
    }
    .time-control .time-display .col-md-6 {
        width: 100%;
    }
    .changeover-wrap.setup .time-countdown {
        min-height: 385px;
    }
}

.justify-content-between {
    display: flex;
    justify-content: space-between;
}

@-webkit-keyframes shake {
    0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
	10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
	20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
	30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
	40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
	60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
	70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
	80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
	100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}
@-moz-keyframes shake {
    0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
	10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
	20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
	30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
	40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
	60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
	70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
	80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
	100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}
@keyframes shake {
    0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
	10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
	20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
	30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
	40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
	60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
	70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
	80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
	100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}