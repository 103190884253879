.dashboard-header {
  padding: 32px 20px;
  display: flex;
  align-items: flex-end;
  .custom-mat-dropdown-btn,
  .input-group.date-time-ft input {
    height: 35px;
  }

  .input-group.date-time-ft input {
    font-size: 14px;
    background: #f5f5f5;
    border-radius: 3px;
  }

  .machine-header {
    padding: 0;
    border-bottom: 0;
    height: auto;

    .mc-btn {
      padding: 9px 10px;
      background-color: #f7f7f7;
      border-radius: 5px;

      &:hover {
        background-color: #f3f6ff;
      }
    }
  }

  .default-widget-wrapper {
    &.has-search-field {
      width: 305px;
    }
  }
}

.blocked {
  pointer-events: none;
}
.search-box {
  width: 305px;
  height: 40px;
  margin-right: 20px;
}

.default-widget-wrapper {
  width: 250px;
  margin-right: 20px;
  display: block;
  &.widget-quicklinks {
    width: 100%;
    margin-right: 0;
    .custom-mat-dropdown {
      float: right;
      width: 250px;
    }
  }
  >label {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
    font-weight: 500;
    display: block;
  }

  .custom-mat-dropdown-btn {
    padding: 9px;
    padding-left: 12px;
  }

  .dropdown-menu {
    z-index: 1000;
  }
}

.view-mode-wrapper {
  // margin-left: auto;
  width: 220px;

  >label {
    width: 100%;
    text-align: right;
    font-weight: 500;
  }

  &.pull-right-flex {
    margin-left: auto;
  }
}

.date-time-ft {
  position: relative;

  i.fa {
    position: absolute;
    top: ~"calc(50% - 8px)";
    font-size: 16px;
    left: 10px;
  }
}

.input-group.date-time-ft input {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 12px;
  padding-left: 35px;
  border: 0px;
  height: 40px;

  &:focus,
  &:hover {
    background-color: #f3f6ff;
  }
}

.btn-vmode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #dcdcdc;
  border-right: none;
  padding: 0px;
  background-color: #f5f5f5;

  i.fa {
    color: #7f8181;
    font-size: 20px;
  }

  &.active,
  &:hover {
    background-color: #005aff;
    border-color: #005aff;

    i.fa {
      color: #ffffff;
    }
  }
}

.viewmode-boxes {
  list-style: none;
  display: flex;
  justify-content: flex-end;

  >li {
    display: block;
    width: 55px;
    height: 35px;

    &::after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: #dcdcdc;
    }

    &:first-child {
      .btn-vmode {
        border-radius: 3px 0px 0px 3px;
      }
    }

    &:last-child {
      .btn-vmode {
        border-radius: 0px 3px 3px 0px;
        border-right: 1px solid #dcdcdc;
      }
    }
  }
}

.auto-update-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 240px;
  margin-left: auto;
  .label {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
.last-update-inf {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
  margin-top: 10px;
  /* Text / grey */

  color: #7f8181;
}
.auto-update-toggle {
  .label {
    font-weight: 500;
    height: 20px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    margin-right: 24px;
  }

  .widget-group {
    width: 40px;
    height: 18px;
    display: inline-block;

    input {
      display: none;
    }

    input:checked+.mat-toggle {
      background-color: rgba(16, 168, 31, 0.3);

      >.toggle-circle {
        background-color: #10a81f;
        transform: translateX(18px);

        i.fa {
          color: #fafafa;
        }
      }
    }
  }
}

.auto-update-btn {
  border: 1px solid #7f8181;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #7f8181;
  padding: 10px 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
    color: #005aff;
    border-color: #005aff;

    svg {
      path {
        fill: #005aff;
      }
    }
  }
}

.end-divider {
  margin-left: 30px;
  margin-right: 30px;
  position: relative;

  &:after {
    content: "";
    width: 2px;
    height: 100%;
    background: #dcdcdc;
    height: 40px;
    position: absolute;
    top: calc(~"50% - 20px");
    left: 0px;
  }
}

.flex-box {
  display: flex;
}

.mat-toggle {
  width: 40px;
  height: 18px;
  background: #dcdcdc;
  border-radius: 10px;
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;

  >.toggle-circle {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0px;
    top: -2px;
    border-radius: 50%;
    background: #c4c4c4;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    transition: all 80ms linear;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  i.fa {
    color: #c4c4c4;
  }
}

.fa-sync {
  width: 15px;
  height: 15px;
  fill: none;

  path {
    fill: #7f8181;
  }
}
.d-none {
  display: none !important;
}
.default-widget-wrapper {
  &.widget-toogle {
    width: 300px;
    margin-right: 0;
    @media (max-width: 1600px) {
      width: 250px;
    }
    .widget-toogle-wrap {
      display: flex;
      align-items: center;
      @media (max-width: 1600px) {
        display: block;
      }
    }
    .toogle-item {
      margin-right: 10px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1600px) {
        justify-content: flex-end;
        height: auto;
        margin-right: 0;
        margin-bottom: 10px;
        group-toggle-button, group-toggle-changeover-button {
          .line-center {
            display: none !important;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .ttl {
        margin-right: 10px;
      }
    }
  }
}
@media (max-width: 1520px) {
  .auto-update-wrapper {
    margin-right: 20px;
  }
}

@media (max-width: 1120px) {
  .dashboard-header {
    .default-widget-wrapper {
      &.has-search-field {
        width: 200px;
      }
    }
  }
  .date-range-picker {
    .main-dropdown {
      left: auto !important;
      right: 0;
    }
  }
}

.widget-tiles-sort {
  .sub-dropdown-menu {
    max-height: none;
    overflow-y: visible;
  }
  .sort-display {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 3px;
    cursor: pointer;
    padding: 0;
    &::before {
      content: '';
      display: block;
      background-image: url('../../../assets/images/dashboard/ico-switch.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 20px;
      height: 20px;
      display: inline-block;
    }
    &:hover {
      background-color: #005aff;
      &::before {
        background-image: url('../../../assets/images/dashboard/ico-switch-hover.png');
      }
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    padding: 15px !important;
    &:hover, &.selected {
      background-color: #F5F5F5 !important;
      color: #000 !important;
    }
    &.selected {
      .sort-icon {
        background-image: url('../../../assets/images/dashboard/ico-sort-active.png');
        &.asc {
          transform: rotate(180deg);
        }
      }  
    }
    .sort-icon {
      background-image: url('../../../assets/images/dashboard/ico-sort.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 18px;
      height: 18px;
      display: inline-block;
    }
    &.reset {
      text-transform: uppercase;
      justify-content: center;
      background-color: #F5F5F5;
      color: #005AFF;
      &:hover {
        text-decoration: underline;
        color: #005AFF;
      }
    }
  }
}