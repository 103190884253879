/**
 * CSS TABLE
 */
 * {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    box-sizing: border-box;
}
[layout]>* {
    min-height: auto;
    min-height: initial;
}
.flex {
    -ms-flex: 1;
    flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    -webkit-box-flex: 1;
    -ms-box-flex: 1;
    box-sizing: border-box;
}
.layout, .layout-column, .layout-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.layout-row {
    .flex-row();
    >.flex {
        min-width: 0;
    }
}
.layout-column {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .flex-column();
}
.dataTables_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0;
}
.dataTables_wrapper {
    position: relative;
    clear: both;
    zoom: 1;
}
.dataTables_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -o-box-orient: vertical;
    -o-box-direction: normal;
    -ms-box-orient: vertical;
    -ms-box-direction: normal;
}
.fullpage-datatable {
    .dataTables_wrapper {
        height: 100%;
        max-height: 100%;
    }
}
.dataTables_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    .dataTables_scroll {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
    }
}
.dataTables_wrapper {
    .dataTables_scroll {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 0;
        flex: 1 1 0;
        -ms-flex-direction: column;
        flex-direction: column;
        clear: both;
        min-height: 0;
    }
}
.dataTables_wrapper {
    .bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 64px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 16px 8px;
        border-top: 1px solid rgba(0,0,0,.12);
    }
}
.dataTables_wrapper {
    .dataTables_scroll {
        .dataTables_scrollBody {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            overflow-x: hidden!important;
            overflow-y: scroll!important;
            -webkit-overflow-scrolling: touch;
        }
    }
}
.dataTables_wrapper {
    .dataTables_scroll {
        .dataTables_scrollBody {
            position: relative;
            overflow: auto;
            height: auto;
            width: 100%;
        }
    }
}

.flex-row {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    flex: 1 1 0;
}
.flex-scroll {
    display: flex;
    flex-direction: column;
    align-content: center;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    min-height: 100%;
}
.flex-bottom {
    display:flex;
    height:50px;
}
.flex-fixed {
    align-self: flex-end;
}
.c-tb-th, .table-ft{
    width: 100%;
}

// Custom table
.custom-table {
    border: 1px solid #ddd;
    .box-shadow(0px 2px 2px 0px rgba(0, 0, 0, 0.1));
    margin-bottom: 10px;
}
.table-container {
    position: relative;
    display: flex;
    flex: 1;
    overflow: auto;
}
.tb-inner {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    // overflow: auto;
    position: relative;
}

.table-header {
    flex-shrink: 0;
    // padding-right: 17px;
    display: flex;
    flex-direction: column;
    // overflow: auto;
    position: relative;
    border-bottom: 1px solid #ddd;
}

.tb-head-inner {
    // overflow: auto;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    display: flex;
    flex: 1;
    position: relative;
}

.table-comp {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    overflow: visible;
    justify-content: center;
    min-width: 80px;
    padding: 10px 8px;
    align-items: center;
    text-align: center;
    word-break: break-word;

    &.w-100 {
        width: 100px;
    }

    &.w-150 {
        width: 150px;
    }

    &.head-comp {
        // flex: 1 1 auto;
        height: 57px;
        font-weight: 600;
        font-size: 13px;
    }
    &.small-comp {
        width: 80px;
        &.manual-ipt {
            // width: 110px;
        }
    }
    &.md-comp {
        width: 140px;
        // &.manual-ipt {
        //     width: 120px;
        // }
    }
    &.big-comp {
        // flex: 1 1 0%;
        width: 210px;
    }
    &.supper-big-comp {
        // width: calc(~'100% - 1600px');
        width: calc(~'100% - 1250px');
        min-width: 350px;
    }
    &.shift-detail-comp {
        width: ~'calc(100% / 9)';
    }
    &.prod-detail-comp {
        width: ~'calc((100% - 890px) / 2)';
        .ipt {
            display: block !important;
        }
        .txt-area {
            cursor: default;
            background-color: #ddd;
            outline: none;
            width: 100%;
            border: 1px solid #ddd;
            color: #333;
            background-image: none;
            font-weight: normal;
            border-radius: 2px;
            resize: none;
            overflow-y: auto;
        }
        .tb-comp-inner {
            width: 100%;
        }
        &.chk-comp {
            width: 160px;
        }
        &.prod-desc-comp {
            width: 630px;
        }
        &.act-comp {
            width: 260px;
        }
    }
    &.no-center {
        align-items: flex-start;
        text-align: left;
    }
    &.not-edit {
        .kf-slt-tagname {
            display: none;
        }
        .ipt {
            display: none;
        }
    }
    .kf-slt-tagname {
        display: none;
    }
    p {
        margin: 0;
    }
    .kf-mc-tagname, .kf-mc-shortname {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .expand-p{
        display: none;
        &.shw{
            display: inline-block;
        }
    }
    .kf-wrap{
        position: relative;
        &.wrapped{
            height: 50px;
            overflow: hidden;
        }
    }
    .err {
        border-color: @red;
    }
}

.table-content {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    &:before {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        opacity: .7;
        z-index: 100;
    }
    &.loading {
        &:before {
            display: block;
        }
    }
}

.tb-content-inner::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.tb-content-inner::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.tb-content-inner::-webkit-scrollbar-thumb
{
	background-color: rgba(0,0,0,0.3);;
}

.tb-content-inner {
    flex: 1 1 auto;
    overflow-y: auto;
    // overflow-x: hidden;
}

.table-row {
    border-bottom: 1px solid #ddd;
    display: flex;
    // height: 77px;
    font-size: 13px;
    .ipt {
        display: none;
    }
    select {
        height: 32px;
        outline: none;
        border: 1px solid #ddd;
        line-height: 32px;
        border-radius: 2px;
        width: 100%;
        display: none;
    }
    &.editting {
        .ipt {
            display: inline-block;
        }
        select {
            display: inline-block;
        }
        p {
            display: none;
        }
        .not-edit {
            p {
                display: block;
            }
            .ipt {
                display: none;
            }
            .kf-slt-tagname {
                display: none;
            }
        }
        .kf-slt-tagname {
            display: block;
        }
    }
    &.open-frm {
        .ipt, select {
            display: inline-block;
        }
    }
    
    &.new-frm {
        textarea {
            outline: none;
            width: 100%;
            height: 70px;
            border: 1px solid #ddd;
            background-color: #fff;
            font-size: 12px;
            color: #333;
            padding: 5px;
            background-image: none;
            font-weight: normal;
            border-radius: 2px;
            &.err {
                border-color: @red;
            }
        }
        .n-record-btn {
            .btn {
                width: 100%;
                margin-bottom: 5px;
            }
        }
        .big-comp {
            width: 210px;
            textarea {
                width: 100%;
            }
        }
        .small-comp {
            .ipt {
                width: 80px;
            }
            .tb-comp-inner {
                width: 80px;
            }
            &.manual-ipt {
                width: 110px;
            }
        }
        .md-comp {
            .ipt {
                // width: 120px;
            }
        }
    }

    &:last-child {
        border: none;
    }

    &:hover {
        background: #f3f6ff;
    }
}
.tb-comp-inner {
    .status-gd {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        color: #fff;
        background-color: #1cc861;
        padding-top: 2px;
        font-size: 12px;
        text-align: center;
        &.status-bad {
            background-color: @red;
        }
    }
    .tb-act-btn{
        .transform(translateY(-50%));
        top: 50%;
        position: absolute;
        color: #333333;
        font-size: 20px;
        &:hover{
            color: #000;
        }
        &.copy-btn {
            left: 30px;
        }
        &.del-btn {
            right: 30px;
        }
    }
    button {
        &.tb-act-btn{
            font-size: 16px;
            border: 1px solid #ddd;
            background-color: transparent;
            border-radius: 3px;
            display: inline-block;
            padding: 0 10px;
            line-height: 30px;
            .transition(all .3s ease);
            &:hover {
                background-color: #ddd;
            }
            &.copy-btn {
                left: unset;
                right: 60px;
            }
            &.del-btn {
                right: 15px;
            }
        }
        &.del-shift {
            &.disabled {
                pointer-events: none;
                background-color: #ddd;
                color: #eee;
            }
        }
    }
}
.material-table {
    .box-shadow(0 2px 20px 0 #cfd0d0);

    .table-container {
        overflow-x: auto;
        overflow-y: hidden;

        .table-header {
            color: #7f8181;
            border-bottom: 0;
        }
    }

    .table-ft {
        .box-shadow(0px -9px 30px 0px #e1e2e2);
    }
}

.keyfile-tb {
    .actions-lst{
        .close-btn{
            display: none;
            color: @red;
        }
        .save-edit-btn{
            display: none;
            color: @green;
        }
        .del-btn{
            left: 70%;
        }
        .edit-btn{
            left: 30%;
        }
    }
    .editting {
        .actions-lst{
            .close-btn{
                display: block;
                left: 30%;
            }
            .save-edit-btn{
                display: block;
                left: 60%;
            }
            .edit-btn{
                display: none;
            }
            .del-btn{
                left: 90%;  
            }
            .lock-btn{
                // left: 70%;
            }
        }
    }
    .state-status {
        width: 100%;
        margin-bottom: 0;
        // overflow-x: auto;
        cursor: default;
        position: relative;
        height: 100%;
        color: @green;
        &.unverified{
            color: @red;
        }
        .state-tooltip {
            display: block;
            width: 100%;
            z-index: 70;
            text-align: center;
            font-size: 11px;
            background-color: #000;
            color: #fff;
            padding: 3px 5px;
            border-radius: 2px;
            position: absolute;
            .opacity(0);
            visibility: hidden;
            .transition(all 0.4s ease);
            &:before{
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #000;
                position: absolute;
                top: -5px;
                left: 50%;
                .transform(translateX(-50%));
                .opacity(0);
                visibility: hidden;
            }
        }
        &:hover{
            .state-tooltip{
                .opacity(1);
                visibility: visible;
                &:before{
                    .opacity(1);
                    visibility: visible;
                }
            }
        }
    }

    .table-container {
        overflow-x: auto;
        overflow-y: hidden;

        .table-content {
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-track {
                background-color: #F5F5F5;
            }
        }

        .tb-content-inner {
            overflow-y: unset;
        }
    }
}

.new-shift {
    .table-row .ipt {
        display: inline-block;
    }
}