/*
 * Machines css
*/ 
ul{
    padding: 0;
    li{
        list-style: none;
    }
}
.custom-scroll{
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: #e1e1e1;
    } 
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2); 
    } 
    
    &::-webkit-scrollbar-button {
        display: none;
    } 
    &::-webkit-scrollbar-corner {
        background-color: black;
    } 
}
.dashboard-ct{
    .transition(all 0.7s ease);
    &.hide-menu{
        margin-left: 65px;
    }
}

.machines{
    
    table{
        tr{
            td{
                &:first-child{
                    border-left: none;
                }
            }
        }
    }
    .c-tb-th{
        border-left: none;
        border-right: none;
    }
}
// CSS COLOR SYSTEM
.color-system, .filter-chrt {
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-right: none;
    .border-radius(2px 0 0 2px);
    text-align: center;
    z-index: 110;
    top: 200px;
    right: 0;
    font-size: 24px;
    color: #333;
    .transition(all .3s ease);
    &:hover {
        background-color: @blue;
        color: #fff;
        &:focus {
            color: #fff;
        }
    }
    &:focus {
        color: #333;
    }
    &.act {
        right: 299px;
    }
}
.color-system-wrap, .filter-wrap {
    width: 300px;
    padding: 15px;
    position: fixed;
    top: 200px;
    z-index: 100;
    background-color: #eee;
    border: 1px solid #ddd;
    border-right: none;
    .transition(all .3s ease);
    .opacity(0);
    right: 0;
    .transform(translateX(300px));
    visibility: hidden;
    .border-radius(0 0 0 2px);
    min-height: 200px;
    &.shw {
        .opacity(1);
        .transform(translateX(0));
        visibility: visible;
    }
    .ipt {
        padding-left: 10px;
        background-color: #fff;
        display: inline-block;
        margin-left: 10px;
        width: 140px;
        float: right;
    }
    .form-group {
        &:last-child {
            margin-bottom: 0;
        }
    }
    .btn {
        &.btn-blue {
            width: 100%;
            font-size: 14px;
        }
    }
}
.color-system-wrap {
    label {
        margin-bottom: 0;
        margin-top: 5px;
    }
    .lbl-color {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        vertical-align: -4px;
        .border-radius(2px);
        background-color: #fff;
    }
    .no-data {
        text-align: center;
        color: #333;
        padding-top: 0;
    }
}
.colorpicker {
    z-index: 100;
}
.form-group {
    margin-bottom: 10px;
    position: relative;
    .clearfix();
}
.uppercase {
    text-transform: uppercase;
}
.colorpicker_submit {
    background: none;
    background-color: #333;
    color: #898989;
    text-align: center;
    line-height: 22px;
    font-weight: 700;
    font-size: 9px;
    cursor: pointer;
    .transition(all .3s ease);
    &:hover {
        background-color: #222;
    }
    &::before {
        content: "OK";
        display: inline-block;
    }
}
.blk-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(255,255,255,0.7);
}
.ipt {
    &.ipt_error  {
        border-color: #db0000;
    }
}

// Filter chart
.filter-chrt {
    top: 260px;
    z-index: 90;
    &:hover {
        background-color: @green;
    }
}
.filter-wrap {
    top: 260px;
}

.logicalmachine-name-icon,
.machine-name-icon,
.line-name-icon {
    display: inline-block;
    height: 27px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    margin-left: 0 !important;
    margin-right: 10px;
}

.line-name-icon {
    background-image: url(~assets/images/icon/line.png);
    width: 85px;
}

.machine-name-icon {
    background-image: url(~assets/images/icon/machine.png);
    width: 24px;
}

.logicalmachine-name-icon {
    background-image: url(~assets/images/icon/machine_dotted.png);
    width: 22px;
}

.build-hie-blk {
    .machine-name-icon {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
    }
}

.build-blk {
    .emptyTree {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        .node-drop-slot {
            height: 64px;
        }
    }
}

.build-line,
.line-wf-build {
    .build-hie-blk {
        .tree-node {
            > .node-wrapper {
                .node-content-wrapper {
                    &.is-dragging-over {
                        background-color: #e5f3e8 !important;
                        border: 1px solid #7bc38b;
                    }

                    &.is-dragging-over-disabled {
                        background-color: #F3E4E4 !important;
                        border: 1px solid #f2a5ad;
                    }
                }
            }

            .node-content-wrapper {
                margin-bottom: 0;
            }

            .node-drop-slot {
                height: 10px;
            }
        }
    }


}

.machines-lst {
    min-height: 150px;

    .node-content-wrapper {
        padding: 0;

        .enable,
        .disabled {
            padding: 2px 5px;
        }

        .disabled {
            background-color: #ddd;
        }
    }

    .attr-tooltip {
        left: 5px;
    }

}

.line-wf-build {
    .chart-ttl {
        &:before {
            display: none;
        }
    }

    h2.text-uppercase {
        min-height: 52px;
    }

    .build-hie-blk {
        margin-top: 20px;

        .toggle-children-wrapper {
            left: unset;
            right: 15px;
        }

        .node-content-wrapper {
            background-color: #fff;
            box-shadow: unset;
            border: 1px solid #DCDCDC;
            padding: 0px;

            .node-content-custom {
                padding: 10px;
            }

            &.is-dragging-over-disabled {
                .node-content-custom {
                    cursor: not-allowed !important;
                }
            }

            input {
                width: calc(~'80% - 55px');
            }
        }

        .toggle-children-wrapper {
            .toggle-children:before {
                content: '\F078';
            }

            &.toggle-children-wrapper-expanded .toggle-children:before {
                content: '\F053';
            }
        }
    }

    .action-number {
        color: #7F8181;
    }

    .tree-node-level-1 {
        margin-bottom: 10px;

        &.tree-node-expanded {
            margin-bottom: 0px;
        }

        > tree-node-drop-slot > .node-drop-slot {
            height: 0px !important;
        }

        .drag-input {
            cursor: pointer;
        }

        .tree-node-level-2 {
            .node-content-custom {
                padding: 5px 10px;
            }
        }
    }

    .tree-node.tree-node-leaf {
        cursor: default;

        .node-wrapper > .node-content-wrapper {
            background: #fff !important;
        }

        .drag-input {
            cursor: default;
        }
    }

    .tree-children {
        padding-left: 0;
    }

    .name-wf-version {
        display: -webkit-box;
        max-height: 22px;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-transition: 1s linear;
        transition: 1s linear;
    }
}

.build-line-tab {
    .tree-node.tree-node-leaf {
        cursor: default;

        .node-wrapper > .node-content-wrapper {
            background: #fff !important;
        }
    }
}

.workflow-section {
    .btn {
        min-width: 126px;
        font-weight: 600;
        letter-spacing: 0.4px;
        border-radius: 3px;
    }
}

.machines-lst,
.machine-actions {
    .tree-node-active > .node-wrapper > .node-content-wrapper,
    .tree-node-focused > .node-wrapper > .node-content-wrapper,
    .tree-node-focused > .node-content-wrapper,
    .node-content-wrapper:hover{
        background: #F3F6FF !important;
    }
}

.build-hie-blk {
    .tree-node-level-2.tree-node-leaf > .node-wrapper > .node-content-wrapper {
        background: #fff !important;
    }
}

.build-hie-blk,
.line-wf-build {
    .tree-node.tree-node-expanded > .node-wrapper > .node-content-wrapper,
    .tree-node.tree-node-expanded > .node-wrapper > .node-content-wrapper:hover,
    .tree-node.tree-node-collapsed > .node-wrapper > .node-content-wrapper:hover {
        background: #F3F6FF !important;
    }

    .tree-node.tree-node-collapsed  > .node-wrapper > .node-content-wrapper {
        background: #fff !important;
    }
}

.build-line-tab,
.list-wf-version {
    overflow-y: auto;
}

.mb-60 {
    margin-bottom: 60px;
}

.none-selection::selection {
    background: transparent;
}

.machine-actions {
    .node-content-wrapper {
        box-shadow: unset;
        border: 1px solid #DCDCDC;
    }

    .machines-lst {
        max-height: 100%;
        min-height: 100%;
    }

    .node-drop-slot {
        height: 0;
    }
}

.chart-ttl .text-small {
    margin-left: 10px;
    font-weight: normal;
    font-size: 14px;
    text-transform: none;
}

.version-list {
    .wf-field,
    .action-wv {
        padding: 5px !important;
        vertical-align: middle !important;
        width: 25%;
    }

    .action-wv.three-action a {
        margin: 2px;
    }

    .act td.wf-field {
        > span,
        > a {
            color: #1cc861;
        }
    }

    .file-date {
        width: 80px;
        display: inline-block;
    }
}

.chart-ttl,
.version-section {
    &.loading {
        pointer-events: none;

        &:before {
            background-color: #fff;
            border: none;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            opacity: .7;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;
        }
    }
}

#work-order-detail {
    .modal-dialog {
        width: auto;
        max-width: 500px;
    }
    .modal-footer {
        text-align: center;
    }
    .modal-body {
        font-size: 14px;
        line-height: 20px;
    }
    .form-group {
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    .bold {
        word-break: break-word;
        font-size: 13px;
    }
}
.link {
    color: #005aff !important;
}
.chart-ttl {
    a {
        display: block;
        color: #000;
        text-transform: uppercase;
        &:hover {
            text-decoration: none;
            .ttl {
                text-decoration: underline;
            }
        }
        .summary {
            color: #7F8181;
            text-transform: none;
            margin-left: 10px;
            font-weight: normal;
        }
    }
}
.card-notifi-blk {
    background-color: #fff;
    padding: 10px;
    border-bottom: 2px solid #DCDCDC;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
    .mat-inner {
        color: #7F8181;
    }
}
.notifi-item {
    min-height: 80px;
    background: #F3F6FF;
    border-radius: 3px;
    padding: 10px;
    border-left: 8px solid #ABE3B0;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    z-index: 120;
    // &.red {
    //     border-left-color: #E81A3C;
    // }
    .txt {
        max-width: 85%;
        color: #333;
        &:hover {
            text-decoration: none;
        }
    }
    .fa-times {
        color: #7F8181;
        font-size: 18px;
        position: absolute;
        right: 10px;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        &:hover {
            color: #000;
        }
    }
}
.card-block {
    min-height: 450px;
}
.overview-tab, .metric-wrap  {
    .chart-ttl {
        height: auto;
    }
    .custom-mat-dropdown {
        width: 53px;
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        z-index: 1;
        &.metric-dropdown {
            position: relative;
            top: auto;
            right: auto;
            width: 200px;
            transform: translateY(0);
            z-index: 150;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            border: none;
            .custom-mat-dropdown-btn {
                background-color: transparent;
                border: none;
                border-radius: 0;
                font-weight: 700;
                font-size: 1.33em;
                padding-left: 0;
                cursor: pointer;
                outline: none;
                box-shadow: none;
            }
            .custom-mat-icon {
                font-size: 1.33em;
            }
            .dropdown-menu {
                width: 100%;
                .dropdown-item {
                    padding-left: 10px;
                    padding-right: 10px;
                    color: #000;
                }
            }
        }
        &.disabled {
            pointer-events: none;
            .thumb-wrap {
                &.up {
                    &::after {
                        color: #1cc861;
                    }
                }
                &.down {
                    &::after {
                        color: #E81A3C;
                    }
                }
            }
        }
        .thumb-wrap {
            cursor: pointer;
            width: 38px;
            height: 38px;
            border: 1px solid #7F8181;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #7F8181;
            font-size: 18px;
            background-color: transparent;
            &.up {
                &::after {
                    content: "\F164";
                    font-family: FontAwesome;
                }
            }
            &.down {
                &::after {
                    content: "\F165";
                    font-family: FontAwesome;
                }
            }
            .custom-mat-dropdown-btn {
                padding: 0;
            }
        }
        .mat-inner {
            background-color: transparent;
            width: 53px;
            height: 38px;
            padding: 0;
        }
    }
    .custom-mat-icon {
        i.fa {
            right: 0;
        }
    }
}
.overview-tab, .metric-wrap {
    .custom-mat-dropdown {
        .dropdown-menu {
            min-width: auto;
            width: 38px;
        }
        .sub-dropdown-menu {
            overflow-x: hidden;
            > li {
                padding: 15px 0;
                cursor: pointer;
                i.fa {
                    color: #7F8181;
                    right: 0;
                    left: 0;
                    text-align: center;
                    &.fa-thumbs-up {
                        color: #10A81F;
                    }
                    &.fa-thumbs-down {
                        color: #E81A3C;
                    }
                }
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}
.overview-tab {
    .col-sm-6 {
        &.w-full {
            clear: both;
        }
    }
}
.metric-wrap {
    .notifi-item {
        margin-top: -15px;
        margin-bottom: 15px;
    }
    .metric-header {
        margin-bottom: 20px;
        .row {
            display: flex;
            align-items: flex-end;
        }
        .chart-ttl {
            margin-bottom: 0;
        }
    }
    .card-block {
        margin-bottom: 30px;
    }
    .card-content {
        min-height: 400px;
    }
    .metric-slect {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .append-select {
            width: 80px;
            background-color: #f5f5f5;
            border-radius: 3px;
            border: none;
            margin-left: 10px;
            margin-right: 10px;
            padding: 0 10px;
        }
        span {
            color: #7F8181;
            font-size: 14px;
        }
    }
    .card-header {
        padding: 15px 15px 10px 15px;
        border-bottom: 1px solid #DCDCDC;
        // min-height: 140px;
    }
    .blk-chart-ttl {
        cursor: pointer;
        i {
            margin-left: 8px;
            &.fa-caret-up {
                display: none;
            }
        }
    }
    .card-block {
        &.collapsed {
            min-height: auto;
            .card-header {
                border-bottom: none;
            }
            .card-content {
                min-height: auto;
            }
            .blk-chart-ttl {
                i {
                    &.fa-caret-up {
                        display: inline-block;
                    }
                    &.fa-caret-down {
                        display: none;
                    }
                }
            }
            .card-content {
                display: none;
            }
        }
    }
}
.metric-wrap {
    .chart-demo {
        position: relative;
        padding: 10px;
        min-height: 380px;
        img {
            width: 100%;
        }
        &::after {
            content: '';
            background-color: rgba(255,255,255,0.8);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
        .demo-msg {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            z-index: 2;
            margin-bottom: 0;
            font-size: 24px;
            color: #000;
            animation: blinker 2s linear infinite;
            text-align: center;
        }
    }
    .chrt-zoom{
        color: #000;
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        background-color: transparent;
        padding: 10px 20px;
        z-index: 100;
        text-decoration: none;
        &:hover{
            background-color: #ddd;
        }
    }
    .card-content {
        position: relative;
    }
}
@-webkit-keyframes blinker {
    50% {
      opacity: 0;
    }
}
@-moz-keyframes blinker {
    50% {
      opacity: 0;
    }
}
@keyframes blinker {
    50% {
      opacity: 0;
    }
}
.metric-chart-wrap {
    .col-md-6 {
        &:nth-of-type(3) {
            clear: both;
        }
    }
}