/*
 * Content header
 *
*/ 

.content-header{
    height: 217px;
    padding-right: 15px;
    padding-left: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .transition(all 0.7s ease);
    .ct{
        position: relative;
        height: 100%;
    }
    .title{
        padding-top: 37px;
        h1{
            font-weight: bold;
            margin-top: 0;
            color: #fff;
            margin-bottom: 20px;
            span{
                margin-left: 30px;
                font-weight: 100;
                font-size: 14px;
            }
        }
        .search-frm{
            width: 515px;
            position: relative;
            input{
                outline: none;
                width: 100%;
                display: block;
                border: none;
                border-bottom: 1px solid #ddd;
                padding: 10px 15px;
                padding-left: 25px;
                background-color: transparent;
                color: #ddd;
            }
            button{
                border: none;
                background-color: transparent;
                position: absolute;
                top: 8px;
                left: 0;
                color: #ddd;
            }
        }
    }
}


.content-header-nav{
    ul{
        padding: 0;
        li{
            float: left;
            list-style: none;
            a{
                color: #ddd;
                display: inline-block;
                margin-right: 20px;
                font-weight: 300;
                text-decoration: underline;
                &:focus, &:active, &:hover{
                    text-decoration: none;
                }
                &.view-mode{
                    text-decoration: none;
                    position: relative;
                    margin-left: 30px;
                    i{
                        position: absolute;
                        font-size: 20px;
                        top: -3px;
                        left: -30px;
                    }
                }
            }

            strong{
                margin-right: 20px;
            }

            &.active{
                a{
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }
    }
}