// Fix ie css
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body{
        margin-right: 15px;
    }
    .header{
        margin-right: 15px;
    }
    .color-system{
        right: 15px;
    }
    .notify-ct{
        .scroll-noti{
            margin-left: 0 !important;
            margin-right: 0 !important;
            &.scrollbar-macosx > .scroll-element.scroll-y{
                display: none;
            }
        }
    }
    .c-tb{
        margin-left: 0 !important;
        margin-right: 0 !important;
        &.scrollbar-macosx > .scroll-element.scroll-y{
            display: none;
        }
    }
 }

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .notify-ct{
        .scroll-noti{
            margin-left: 0 !important;
            margin-right: 0 !important;
            &.scrollbar-macosx > .scroll-element.scroll-y{
                display: none;
            }
        }
    }
    .c-tb{
        margin-left: 0 !important;
        margin-right: 0 !important;
        &.scrollbar-macosx > .scroll-element.scroll-y{
            display: none;
        }
    }
}


// fix css for firefox
@-moz-document url-prefix() {
    .notify-ct{
        .scroll-noti{
            margin-left: 0 !important;
            margin-right: 0 !important;
            &.scrollbar-macosx > .scroll-element.scroll-y{
                display: none;
            }
        }
    }
    .c-tb{
        margin-left: 0 !important;
        margin-right: 0 !important;
        &.scrollbar-macosx > .scroll-element.scroll-y{
            display: none;
        }
    }
 }