/**
 * Page components
 *
 * Description: This is the most accessible LESS file. For creating stucture of a LESS project
 * There're some simple rules: 
 *  - use base rule on this file. E.g. every single rule/indent or each component. To generate the fast view for the parent components
 *  - for children rules put it in matched folders and import it in parent's section
 *  
 */
body {
  // background-image: url('../images/login-bg-sm.jpg');
  // background-repeat: no-repeat;
  // background-color: #222;
  // background-attachment: fixed;
  // position: fixed;
  // width: 100%;
  // height: 100%;
  // z-index: 100;
  // background-repeat: no-repeat;
  // background-size: cover;
  // .spinner > div {
  //   background-color: #fff;
  // }
  // &.no-bg {
  //   background: none;
  //   position: relative;
  //   height: auto;
  //   .spinner > div {
  //     background-color: @blue;
  //   }
  //   #preview-area {
  //     z-index: 10;
  //   }
  // }
}
h1, h2, h3 , h4, h5, h6 {
    margin-top: 0;
}
.t-l {
    text-align: left;
}
.t-r {
    text-align: right;
}
.t-c {
    text-align: center;
}
// CSS LOADING
#preview-area {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 200;
}
.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  .transform(translateX(-50%) translateY(-50%));
  z-index: 120;
  &.loading-tile{
    top: 50%;
    left: 50%;
    // .transform(translateX(-50%) translateY(-50%));
    // margin-top: 20px;
    // margin-bottom: 30px;
  }
  &.loading-gr{
    top: 40%;
    z-index: 220;
  }
  &.loading-chrt{
    top: 50%;
  }
}

.spinner > div {
  background-color: @blue;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
.aiq-version {
  color: #7f8181;
  position: absolute;
  z-index: 200;
  right: 10px;
  bottom: 0;
  display: none;
}
.login-page {
  .aiq-version {
    display: block;
  }
}

.ico-trash,
.ico-duplicate,
.ico-edits,
.ioc-custom {
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.ioc-custom {
  &.ioc-m-custom {
    width: 15px;
    vertical-align: sub;
    height: 15px;
    margin-right: 7px;
  }
}


.ico-trash {
  background: url("../images/icon/delete.svg") no-repeat;

  &.ico-red {
    background: url("../images/icon/red-delete.svg") no-repeat;
  }
}

.ico-duplicate {
  background: url("../images/icon/copy.svg") no-repeat;
}

.ico-edits {
  background: url("../images/icon/edit.svg") no-repeat;
}

.ico-file-upload {
  background: url("../images/icon/file-upload.svg") no-repeat;
}

.ico-file-download {
  background: url("../images/icon/file-download.svg") no-repeat;
}

.ico-plus-circle {
  background: url("../images/icon/plus-circle.svg") no-repeat;
}

.mt-17 {
  margin-top: 17px;
}

.mb-0 {
  margin-bottom: 0px;
}

.font-20 {
  font-size: 20px !important;
}

.wrench-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url('../images/wrench-icon.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
//** ------ {{ plugins page }} ------------------------------------ 
@import "blocks/plugins.less";
@import "blocks/slick.less";

//** ------ {{ header }} ------------------------------------ 
@import "blocks/dashboard/header.less";
@import "blocks/dashboard/scroll-header.less";

//** ------ {{ dashboard }} ------------------------------------ 
@import "blocks/dashboard/content-header.less";
@import "blocks/dashboard/machine-header.less";
@import "blocks/dashboard/map.less";
@import "blocks/dashboard/table.less";
@import "../../app/dashboard/dashboard-plant/dashboard-plant.less";
//dashboard header less
@import '../../app/dashboard/dashboard-header/dashboard-header.less';

//** ------ {{ machines grid }} ------------------------------------ 
@import "blocks/machines-page/machines.less";
@import "blocks/machines-page/image-generator-hotspot.less";

//** ------ {{ machines view }} ------------------------------------ 
@import "blocks/machine-view/machine-view.less";
@import "blocks/machine-view/mc-header.less";
@import "blocks/machine-view/station-tab.less";
@import "blocks/machine-view/overview.less";
@import "blocks/machine-view/parts-tab.less";
@import "blocks/machine-view/faults-tab.less";
@import "blocks/machine-view/oee-tab.less";
@import "blocks/machine-view/noti-tab.less";
@import "blocks/machine-view/newkeyfile.less";


//** ------ {{ tiles grid }} ------------------------------------ 
@import "blocks/tiles-page/tiles.less";

//** ------ {{ sidebar }} ------------------------------------ 
@import "blocks/dashboard/sidebar.less";

//** ------ {{ login page }} ------------------------------------ 
@import "blocks/login/login.less";

//** ------ {{ admin page }} ------------------------------------ 
@import "blocks/admin/manage-user.less";

//** ------ {{ forms }} ------------------------------------ 
@import "blocks/table.less";

//** ------ {{ User }} ------------------------------------ 
@import "blocks/user/user-profile.less";

//** ------ {{ Hierachy }} ------------------------------------ 
@import "blocks/hierachy/hierachy-table.less";
@import "blocks/hierachy/new-hierachy.less";

//** ------ {{ Admin machine }} ------------------------------------ 
@import "blocks/admin/manage-machine.less";
@import "blocks/admin/dropzone.less";

//** ------ {{ Admin datasource page }} ------------------------------------
@import "blocks/admin/datasource.less";

//** ------ {{ Admin alert page }} ------------------------------------
@import "blocks/admin/alert.less";

//** ------ {{ Admin contract page }} ------------------------------------
@import 'blocks/admin/contract.less';

//** ------ {{ Event input page }} ------------------------------------
@import 'blocks/event-input/event-input.less';

//** ------ {{ Schedule page }} ------------------------------------
@import 'blocks/schedule/schedule.less';
//** ------ {{ datepicker }} ------------------------------------ 
@import "blocks/datepicker.less";

//** ------ {{ forms }} ------------------------------------ 
// @import "blocks/form.less";

//** ------ {{ Admin theme }} ------------------------------------
@import "blocks/admin/manage-theme.less";

//** ------ {{ custom setting }} ------------------------------------ 
@import "blocks/custom-setting.less";
//** ------ {{ scrollbar setting }} ------------------------------------
@import "blocks/scrollbar.less";
//** ------ {{ IE CSS }} ------------------------------------
@import "blocks/ie-fix.less";
//** ------ {{ Pace css }} ------------------------------------
@import "pace/pace-theme-minimal.less";
//** ------ {{ material ui css }} ------------------------------------
@import "blocks/mat-ui/select.less";
@import "blocks/mat-ui/autocomplete.less";

// Hotspot css
@import "hotspot/squares/css/squares.less";
@import "hotspot/squares/css/squares-controls.less";
@import "hotspot/squares/css/squares-editor.less";
@import "hotspot/wcp-editor/css/wcp-editor.less";
@import "hotspot/wcp-form/css/wcp-form.less";
@import "hotspot/wcp-form/css/wcp-form-controls.less";
@import "hotspot/wcp-tour/css/wcp-tour.less";
@import "hotspot/css/image-map-pro.less";
@import "hotspot/css/image-map-pro-editor.less";

// Import Manual Input V2
@import "blocks/manual-input.less";
//meeting css
@import "meeting/amz-chime-meeting.less";
// Import Thing Management
@import 'blocks/admin/manage-thing.less';