/*
 * Parts tab
 *
*/ 

.p-t-dt{
    .c-tb-th{
        .table-cus{
            tr{
                th{
                    border-top: 3px solid #ddd;
                }
            }
        }
    }
}

.chrt-groupby {
    margin-bottom: 25px;
    width: 50%;
    .ipt {
        display: inline-block;
        width: auto;
        margin-left: 10px;
    }
}

.starvedblocked-block {
    clear: both;
    padding: 20px 0;
    position: relative;
}


.hidden-fault {
    display: none;
}

.table-blocked-tarved tbody {
    > tr.tbl-bt {
        > th {
            background-color: #7cb0ea;
            color: #fff;
            border: 1px solid #ddd;
            padding: 20px 10px;

            i {
                margin-left: 4px;
            }

            &.act i:before {
                content: "\f0de";
            }
        }

        &#head-title {
            th {
                cursor: pointer;
            }
        }

        &:first-child th {
            font-size: 16px;
            border-top: none;
            vertical-align: middle;
        }

        > td {
            border: 1px solid #ddd;
            padding: 20px 10px !important;
            position: relative;
            font-size: 15px;

            .btn-toggle-status:before {
                display: inline-block;
                font-size: 22px;
                left: 10px;
                text-rendering: auto;
                top: 19px;
                width: 30px;
                position: absolute;
                -webkit-font-smoothing: antialiased;
                text-align: center;
                cursor: pointer;
                border-radius: 2px;
                content: "\F067";
                font: normal normal normal 22px/1 FontAwesome;
            }
        }



        &:not(.hidden-fault):hover {
            td {
                background-color: #eee;
            }
        }

        &.act {
            display: table-row;

            .btn-toggle-status:before {
                content: "\F068";
            }
        }
    }
}