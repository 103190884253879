hotspot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    // top: 24px;
    // bottom: 24px;
    // left: 24px;
    // right: 24px;
    // position: absolute;
}

.hotspot-wrapper {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
}

.veil-block {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}