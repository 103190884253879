.analysis-theme {
    .modal-header {
        background: #F3F6FF;
        border-radius: 3px 3px 0px 0px;
        padding: 15px;

        .close {
            font-size: 38px;
            top: 20px;
            span {
                color: #000000;
            }
        }
    }
    .modal-title {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
    }

    .modal-inner-content {
        padding: 20px 20px 0px;

        .info-tab-content > article{
            max-height: unset;
        }
    }
}

.insights-full-list {
    margin-left: -10px;
    margin-right: -10px;
    overflow: hidden;
    > li {
        width: 50%;
        padding: 0px 10px;
        margin-bottom: 20px;
        float: left;
    }
}
