/**
 * Media 1200px+
 *
 * description: css rules for screen of tablets
 */
@media (max-width: 991px){
    .evt-tab-list {
        .evt-ipt-lst {
            .col-lg-3:nth-child(2n+1) {
                clear: left;
            }
        }
    }

    .edit-wf {
        .edit-wf-left {
            width: calc(~'100% - 140px');
            float: left;
            display: inline-block;
        }

        .edit-wf-right {
            width: 140px;
            float: left;
            display: inline-block;
        }
    }
}

@media only screen and (min-width: 991px){
    .table-comp {
        &.prod-detail-comp {
            &.prod-attribute-comp {
                width: 150px;
            }
            &.prod-desc-att-comp {
                width: 555px;
            }
        }
    }
}

@media (max-width: 940px){
    // login page
    .text{
        h1{
            width: 400px;
        }
    }

    // content header
    .content-header {
        .title {
            .search-frm{
                display: none;
            }
        }
    }

    // Machine view header canvas
    .chrt-itm{
        padding-top: 30px;
        canvas{
            width: 140px;
            height: 140px;
        }
        .oee{
            top: 36%;
            // left: 28%;
            p{
                font-size: 36px;
                margin-bottom: 0;
                span{
                    &:first-child{
                        font-size: 14px;
                        // right: -30px;
                        top: 40px;
                    }
                    &:last-child{
                        font-size: 20px;
                        top: 14px;
                        right: -18px;
                    }
                }
            }
        }
    }
    .chrt-itm{
        &.big{
            width: 160px;
            canvas{
                width: 160px;
                height: 160px;
            }
            .oee{
                top: 26%;
                // left: 18%;
                p{
                    font-size: 44px;
                    span{
                        &:first-child{
                            font-size: 12px;
                            top: 10px;
                            right: -14px;
                        }
                        &:last-child{
                            font-size: 16px;
                            top: 28px;
                            right: -16px;
                        }
                    }
                }
            }
        }
    }

    // Dasboard grid table
    .geo-tb, .d-g-tb{
        td{
            .expand-tr{
                display: block;
            }
        }
    }

    .d-g-tb{
        .col-small{
            width: 10%;
        }
        .col-big{
            width: 30%;
        }
        .col-eq{
            width: 15%
        }
        .col-hide{
            display: none;
        }
        tr{
            td{
                &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10){
                    display: none;
                }
            }
        }
    }
    // Dashboard geo table
    .geo-tb{
        .col-small{
            width: 10%;
        }
        .col-big{
            width: 30%;
        }
        .col-eq{
            width: 15%;
        }
        .col-hide{
            display: none;
        }
        tr{
            td{
                &:nth-child(5), &:nth-child(6), &:nth-child(7){
                    display: none;
                }
            }
        }
    }
    // machines page
    table{
        tr{
            &.sub-td{
                display: none;
                &.active{
                    display: table-row;
                }
            }
        }
    }

    // Event input
    .evt-itm {
        &.slick-slide {
            width: 190px !important;
        }
    }

    // Table
    .table-comp{
        &.shift-detail-comp {
            width: 200px;
        }
    }
}

@media (min-width: 861px){
    //.login-page.loaded .login-frm {
    //    right: 100px;
    //}
    //
    //.login-frm .splash-page-text {
    //    background-position: right;
    //}
}

@media (max-width: 860px){
    // login page
    .login-page{
        padding-left: 0;
        &.loaded{
            .login-frm{
                .logo{
                    opacity: 1;
                }
            }
        }
    }
    .text{
        display: none;
    }
    .login-frm{
        width: 100%;
        right: -100%;
        &:after{
            width: 100%;
            right: -100%;
            .transition(all 0.7s ease);
        }
        .login-ct{
            .transition(all 0.9s ease 0.7s);
        }
    }

    .login-ct, .choose-pg{
        //width: 100%;
        margin: 0 auto;
    }


    // table
    // Production tab table
    .prod-tb{
        .col-eq{
            width: 25%;
            &.col-hide{
                display: none;
            }
        }
        tr{
            td{
                &:nth-child(5), &:nth-child(6), &:nth-child(7){
                    display: none;
                }
            }
        }
    }
    .table-cus{
        td{
            .expand-tr{
                display: block;
            }
        }
    }


    // Machine view tab 
    // Overview tab
    .overview-tab{
        .w-half{
            width: 100%;
        }
    }
    // Station tab
    .station-tab{
        .machine-drawing{
            >img{
                width: 100%;
            }
        }
    }

    // User list
    .usr-lst{
        .table-cus{
            tr{
                th{
                    &:nth-child(2), &:nth-child(3){
                        width: 45% !important;
                    }
                }
            }
            .tb-th{
                th{
                    &:nth-child(2), &:nth-child(3){
                        width: 45% !important;
                    }
                }
            }
        }
    }

    // Admin manage user
    
    .user-tb{
        td{
            .expand-tr{
                display: inline-block;
            }
        }
    }

    .machine-dt-head{
        &.shw{
            display: none;
        }
    }

    // Event input
    .evt-itm {
        &.slick-slide {
            width: 200px !important;
        }
        &.evt-child-itm {
            .evt-btn {
                height: 60px;
            }
        }
    }

    .evt-parent {
        .evt-itm {
            .evt-btn {
                font-size: 16px;
                .name {
                    font-size: 14px;
                }
            }
        }
    }

    .evt-ipt-slide {
        height: auto;
    }
    .evt-child {
        padding: 10px 0 0 0;
        margin-bottom: 0;
    }

    // Event input - hierarchy
    .hierarchy-blk {
        position: fixed;
        z-index: 600;
        top: 80px;
        left: 50%;
        .transform(translateX(-50%));
        
    }
    body {
        &.shw-modal {
            &:before {
                content: '';
                display: block;
                position: fixed;
                z-index: 500;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: #000;
                .opacity(.7);
            }
        }
    }
}

@media (max-width: 768px) {
    // header
    .header{
        .search-frm{
            .h-search-frm{
                border-right: 1px solid #eee;
            }
        }
        .usr-account {
            .usr-name{
                padding-left: 15px;
                padding-right: 15px;
                .usr-status{
                    left: 48px;
                }
            }
            .usr-menu{
                ul{
                    li{
                        a{
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }

    // machine header
    .machine-header{
        height: 122px;
        .mh-blk{
            width: 100%;
        }
        .from-to{
            float: left !important;
            .date-picker{
                left: -21px;
                top: 59px;
            }
            a{
                padding-left: 35px;
                .icon-calendar{
                    left: 0;
                    margin-left: 0;
                }
            }
        }
    }

    // Custom tab
    .nav-tabs{
        >li{
            >a{
                font-size: 14px;
            }
        }
    }

    // Machine view header
    .ttl-range {
        width: 100%;
        margin-bottom: 15px;
    }

    // Machine view header canvas
    .col-small{
        width: 50%;
        float: left;
    }
    .chrt-itm{
        min-height: 180px;
    }
    .big{
        >.oee{
            top: 34% !important;
        }
    }

    .user-info{
        .lbl{
            margin-top: 8px;
        }
        .ipt{
            margin-bottom: 15px;
        }
        .c-r{
            .col-md-2, .col-sm-2{
                width: 100%;
            }
            .col-md-10, .col-sm-10{
                width: 100%;
            }
        }
    }

    // Event input
    .evt-itm {
        .evt-btn {
            font-size: 16px;
        }
    }
}