/*
    Alert css
*/ 

.alert-filter{
    padding: 15px 0 0 0;
    span{
        margin-left: 15px;
    }

}

.alert-fl-blk{
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 10px;
    position: relative;
    display: inline-block;
    p{
        position: absolute;
        left: 15px;
        top: -10px;
        padding: 0 10px;
        z-index: 40;
        background-color: #fff;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
    }
}
.flt-by-user{
    padding: 10px 0;
    
}

.alert-tb{
    tr{
        th{
            padding: 0;
            span{
                display: block;
                padding: 20px 10px;
                cursor: pointer;
                i{
                    margin-left: 5px;
                }
            }
        }
    }
}

.add-alert-head{
    margin-top: 30px;
}
.filter-mc-blk{
    .or{
        display: inline-block;
        font-weight: 600;
        margin: 0 20px;
    }
    .slt-ttl{
        margin-left: 10px;
    }
}



.rd-btn{
    display: inline-block;
    margin-right: 15px;
    input[type="radio"]{
        display: none;
    }
    label{
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 18px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-weight: 100;
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        &:before{
            width: 18px;
            content: "\f10c";
            display: inline-block;
            text-align: left;
        }
        span{
            font-size: 14px;
            font-family: 'Raleway', sans-serif;
            font-weight: normal;
        }
    }

    input[type=radio]:checked + label:before{
        content: '\f192';
    }
}
textarea{
    resize: none;
}
.alert-txt-blk{
    position: relative;
    margin-top: 30px;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 15px;
    margin-bottom: 15px;
    h3{
        font-size: 16px;
        padding: 0 10px;
        background-color: #fff;
        position: absolute;
        z-index: 100;
        top: -10px;
        left: 5px;
        font-weight: 600;
        margin-bottom: 0;
    }
    p{
        padding: 8px 15px;
        background-color: @blue;
        color: #fff;
        border-radius: 2px;
        text-align: center;
    }
    button{
        text-align: center;
    }
    .alert-text-ct{
        margin-top: 15px;
        // margin-bottom: 15px;
        // height: 180px;
        // overflow-y: auto;
        // padding: 0 15px;
        .ipt{
            margin-bottom: 15px;
        }
    }
}


.new-alert-frm{
    display: none;
    &.shw{
        display: block;
    }
}

.new-alert-footer{
    display: none;
    &.shw{
        display: block;
    }
}
.select-alert{
    padding: 15px 0;
}
.slt-alrt-ttl{
    h3{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 40px;
    }
}

.slt-alert-ct{
    span{
        margin-right: 10px;
    }
    .slt-alert-blk{
        margin-bottom: 25px;
        border: 1px solid #ddd;
        border-radius: 2px;
        padding: 15px 20px;
        position: relative;
        p{
            position: absolute;
            top: -10px;
            margin-bottom: 0;
            z-index: 20;
            background-color: #fff;
            padding: 0 10px;
            left: 10px;
        }
        select{
            height: 32px;
            outline: none;
            border: 1px solid #ddd;
            background-color: transparent;
            width: 200px;
            // padding: 0px 10px;
            font-size: 12px;
            line-height: 32px;
            color: #333;
            background-image: none;
            font-weight: normal;
            border-radius: 2px;
            &:focus, &:active{
                border-color: #bbb;
            }
        }
    }
}
.slt-alert-ft{
    a{
        display: inline-block;
        margin-right: 10px;
    }
}
.eff-str-date{
    padding-left: 22px;
}
.alert-srch{
    width: 515px;
    float: right;
}

.alert-program{
    margin-top: 30px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 15px;
    margin-bottom: 15px;
    .ipt{
        margin-left: -5px;
    }
    h3{
        font-size: 16px;
        padding: 0 10px;
        background-color: #fff;
        position: absolute;
        z-index: 100;
        top: -10px;
        left: 5px;
        font-weight: 600;
        margin-bottom: 0;
    }
    p{
        padding: 8px 15px;
        background-color: @blue;
        color: #fff;
        border-radius: 2px;
        text-align: center;
    }
    button{
        text-align: right;
    }
    .alert-text-ct{
        margin-top: 15px;
        // margin-bottom: 15px;
        // height: 180px;
        // overflow-y: auto;
        // padding: 0 15px;
        .ipt{
            margin-bottom: 15px;
        }
    }
}
.alert-switch{
    text-align: left;
}
.lock-user {
    &.alert-tpl-act{
        padding-right: 0;
    }
}
.a-tpl-sch{
    width: 100%;
}

.alert-subs-tab{
    padding-top: 0;
}
.u-pf-name{
    width: 100%;
    float: left;
    margin-bottom: 0;
}

.up-slt-client{
    margin: 0;
    padding: 0px;
    width: 53%;
    float: left;
    select{
        width: 165px;
        margin-left: 22px;
    }
}
.srch-blk{
    float: right;
    width: 47%;
    position: relative;
}
.up-alert-btn{
    margin-top: 18px;
    .btn-blue{
        margin-right: 0;
        padding: 0 20px;
    }
}
.alert-subs-tab{
    .alert-srch{
        margin-top: 0;
    }
}
.font-size-medium {
    font-size: 14px;
}