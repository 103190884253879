/**
 * Media 1200px+
 *
 * description: css rules for screen of desktop
 */
@media (max-width: 1670px){
    // Machine view header
    .mc-header{
        .ttl-left{
            margin-bottom: 30px;
        }
        .ttl-right{
            float: left !important;
        }
    }

    .mc-noti{
        margin-bottom: 30px;
        ul{
            float: left;
        }
    }
}

@media (max-width: 1480px) {
    .slick-track {
        &.one-itm {
            width: 100% !important;
            padding-right: 30px;
            .evt-itm.slick-slide {
                width: 100% !important;
            }
        }
        &.col-2 {
            padding-right: 0px;
            width: 100% !important;
            .evt-itm.slick-slide {
                width: 48% !important;
            }
        }
        &.col-3 {
            padding-right: 0px;
            width: 100% !important;
            .evt-itm.slick-slide {
                width: 31% !important;
            }
        }
        &.col-4 {
            padding-right: 0px;
            width: 100% !important;
            .evt-itm.slick-slide {
                width: 22% !important;
            }
        }
        &.col-5 {
            padding-right: 0px;
            width: 100% !important;
            .evt-itm.slick-slide {
                width: 17.5% !important;
            }
        }
    }
}

@media (max-width: 1440px){
    // Tiles page
    .tiles-wrap{
        .tile-itm{
            width: 33.33%;
        }
    }

    // Notify head
    .notify-ct{
        .scroll-noti{
            max-height: 400px;
        }
    }

     &.no-height{
        .scroll-noti{
            height: auto !important;
        }
    }
}

@media (max-width: 1300px) {
    // Style sticky for group button Quantity/Downtime on fault page
    .sankey-toggle {
        &.active {
            right: 0px;
        }
    }

    .margin-lft {
        margin-left: 64px;
    }
    // login page
    .text{
        h1{
            width: 600px;
            img{
                width: 100%;
            }
        }
    }

    // navigation
    .navigation{
        display: block;
        height: 64px;
        .box-shadow(0px 2px 2px 0px rgba(0,0,0, 0.2));
        &.show-menu{
            height: 100%;
            .nav-menu{
                display: block;
            }
        }
        .nav-menu{
            display: none;
        }
    }

    // header

    .togle-nav-menu{
        a{
            &.toggle-btn{
                display: block;
            }
        }
    }
    .header{
        margin-left: 64px;
        
    }

    //  Custom tab
    .nav-tabs{
        >li{
            >a{
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }

    // Machine view
    // Station tab
    .st-trend, .st-top-10{
        width: 100%;
    }
    .drawing-opt{
        right: 0px;
    }

    // Noti tab
    .noti-lst{
        li{
            a{
                font-size: 11px;
                padding: 15px 5px;
            }
        }
    }
}

@media (min-width: 1281px){
    // Dashboard title fixed broken layout when screen > 1280px (3 columns)
    //.tiles-wrap{
    //    .tile-itm:nth-of-type(3n+1) {
    //        clear: left;
    //    }
    //}
}

@media (max-width: 1280px){
    // Machine station tab
    .station-tab
    {
        .w-full{
            // width: 100%;
        }
        .station-lst{
            width: 90%;
            float: right;
        }
    }

    .overview-tab{
        .w-full{
            width: 100%;
        }
        .w-half{
            width: 50%;
        }
    }

    // Noti tab
    .notification-tab{
        .col-md-2{
            width: 25%;
        }
        .col-md-10{
            width: 75%;
        }
    }

    // Notify header
    .notify-ct{
        .scroll-noti{
            height: 450px;
        }
    }

     &.no-height{
        .scroll-noti{
            height: auto !important;
        }
    }

    .n-record-td {
        .n-record-btn {
            a{
                position: static;
                display: block;
                &.btn-default{
                    margin-bottom: 15px;
                }
            }
        }
    }

    .manage-header{
        padding: 15px;
    }
    .k-h{
        margin-bottom: 30px;
    }

    // Grid tile full screen
    .tile-full-scr{
        .tile-itm{
            width: 50%;
        }
        .oee-full-scr{
            left: 25%;
            span{
                top: 12px;
                right: -36px;
                &:last-child{
                    top: 30px;
                    right: -24px
                }
            }
            &.resize-font {
                font-size: 3.2vw;
                span{
                    top: 15%;
                    font-size: 1.2vw;
                    &:last-child{
                        font-size: 1.2vw;
                        top: 41%;
                    }
                }
            }
        }
    }

    .qpa-itm{
        strong{
            font-size: 1.4vw;
        }
        .qpa-canvas-inf{
            .chrt{
                span{
                    font-size: 1.3vw;
                }
            }
        }
        .qpa-txt{
            font-size: 1.2vw;
        }
        .hpu-txt {
            font-size: 1.2vw;
        }
    }
    .machine-itm {
        //.full-scr-p{
        //    font-size: 1.2vw;
        //}
        .h-full-scr{
            font-size: 1.4vw;
        }
    }
    .oee-full-scr{
        font-size: 4.2vw;
        span{
            font-size: 1.4vw;
            &:last-child{
                font-size: 1.7vw;
                top: 33px;
            }
        }
    }

    // Event input
    .evt-parent, .evt-child {
        padding: 10px 0;
    }
    .evt-itm {
        margin-bottom: 10px;
    }
    .evt-itm {
        &.slick-slide {
            width: 280px !important;
        }
    }

    .slick-track {
        &.one-itm {
            width: 100% !important;
            padding-right: 30px;
            .evt-itm.slick-slide {
                width: 100% !important;
            }
        }
        &.col-2 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 47.7% !important;
            }
        }
        &.col-3 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 30.5% !important;
            }
        }
        &.col-4 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 22% !important;
            }
        }
        &.col-5 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 17.4% !important;
            }
        }
    }
}

@media (max-width: 1355px) {
    // Keyfile group action
    .kf-grp-action {
        //width: 100%;

        .kf-action-left a.add-single-kf {
            margin: 0;
        }
    }
}


@media (max-width: 1210px) {
    .kf-grp-action {
        width: 100%;

        .kf-action-left {
            text-align: left;

            a {
                width: 31%;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1210px) {
    .kf-search-filter {
        width: 50%;
    }
}

@media (max-width: 1200px) {
    // login page
    .login-page{
        .text{
            padding-left: 30px;
            h1{
                width: 550px;
            }
        }
    }

    // content header
    .content-header {
        .title {
            .search-frm{
                width: 400px;
            }
        }
    }
    
    // Machine view header
    .ttl-right{
        .ttl-ipt{
            margin-right: 15px;
        }
    }

    // Machine view tab
    .station-tab{
        .w-full{
            width: 100%;
        }
        .r-st-lst{
            display: none;
            &.station-shw{
                display: block;
                width: 370px;
                z-index: 9999999;
                position: absolute;
                right: -15px;
                top: 55px;
                .station-lst{
                    width: 100%;
                }
            }
        }
    }
    .machine-drawing{
        .drawing-opt{
            width: 55px;
            height: 180px;
            right: 5px;
            z-index: 50;
            a{
                border-left: none;
                border-right: none;
                border-bottom: 1px solid #ddd;
                &:last-child{
                    border-bottom: none;
                }
            }
        }
        .open-st-lst{
            display: inline-block;
        }
    }

    .station-lst{
        >h2{
            .st-close{
                display: block;
            }
        }
    }
    
    // Event input
    .evt-itm {
        &.slick-slide {
            width: 260px !important;
        }
    }
}

@media (max-width: 1170px){
    // Machine view header canvas
    .chrt-itm{
        padding-top: 40px;
        canvas{
            width: 160px;
            height: 160px;
        }
        .oee{
            p{
                margin-bottom: 0;
                span{
                    &:first-child{
                        right: 50%;
                        .transform(translateX(50%));
                    }
                }
            }
            &.oee-style-2 {
                p span:first-child {
                    right: -5px;
                }
            }
        }
    }
    .chrt-itm{
        &.big{
            padding-top: 15px !important;
            width: 200px;
            canvas{
                width: 200px;
                height: 200px;
            }
            .oee{
                top: 33%;
                left: 42%;
                p{
                    font-size: 50px;
                    span{
                        &:first-child{
                            font-size: 16px;
                            top: 12px;
                            right: -18px;
                        }
                        &:last-child{
                            font-size: 20px;
                            top: 28px;
                            right: -20px;
                        }
                    }
                }
                &.oee-style-2 {
                    p span:first-child {
                        top: 9px;
                    }
                }
            }
        }
    }

    // machine view header
    .ttl-right {
        .time-picker{
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .ttl-range {
        p {
            span{
                &:first-child{
                    margin-left: 15px;
                }
            }
        }
    }

    // Table responsive
    .c-tb-th{
        .table-cus{
            th{
                span{
                    i{
                        display: block;
                    }
                }
            }
        }
    }

    // Admin manage user
    .m-ttl, .u-search-frm{
        width: 50%;
    }

    // Event input
    .evt-itm {
        &.slick-slide {
            width: 190px !important;
        }
    }
}

@media (max-width: 1024px){
    // login page
    .text{
        h1{
            width: 450px;
        }
        p{
            width: 450px;
            font-size: 14px;
        }
    }
    .login-frm{
        width: 340px;
        &:after{
            width: 340px;
        }
        .login-ct{
            .btn-login{
                padding: 10px 12px;
            }
        }
    }

    // tiles page
    .tiles-wrap{
        .tile-itm{
            width: 50%;
        }
    }

    //  Custom tab
    ul.nav-tabs{
        >li{
            >a{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    // Admin manage user
    .m-ttl, .u-search-frm{
        width: 100%;
    }
    .m-ttl{
        margin-bottom: 30px;
    }
    .u-search-frm{
        .filter-user{
            text-align: left;
        }
    }
    .client-search{
        float: left !important;
    }

    // Event input
    .evt-itm {
        &.slick-slide {
            width: 200px !important;
        }
    }

    .slick-track {
        &.one-itm {
            width: 100% !important;
            padding-right: 30px;
            .evt-itm.slick-slide {
                width: 100% !important;
            }
        }
        &.col-2 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 46% !important;
            }
        }
        &.col-3 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 30% !important;
            }
        }
        &.col-4 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 21.2% !important;
            }
        }
        &.col-5 {
            padding-right: 0px;
            .evt-itm.slick-slide {
                width: 18% !important;
            }
        }
    }

    .shift-table {
        .table-comp{
            &.prod-detail-comp{
                width: 200px;
                &.chk-comp {
                    width: 100px;
                }
                &.prod-desc-comp {
                    width: 300px;
                }
            }
        }
    }
}

@media (max-width: 991px){
    .notification-tab{
        .col-md-2{
            width: 100%;
        }
        .col-md-10{
            width: 100%;
        }
    }
    .noti-type{
        margin-bottom: 30px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 30px;
        padding-top: 0px;
    }
    
    // Admin manage user
    .u-search-frm{
        .col-md-6{
            width: 50%;
        }
    }
    .user-tb{
        .col-eq{
            width: 25%;
        }
        .col-sm{
            width: 5%;
        }
        .t-hide{
            display: none;
        }
        td{
            .expand-tr{
                display: inline-block;
            }
        }
        tr{
            &.active{
                display: table-row;
            }
        }
    }

    .define-st-blk{
        .warn-itm{
            .col-xs-12{
                width: 100%;
            }
            .attr-btn-blk{
                margin-bottom: 15px;
            }
        }
    }

    .key-header{
        .k-h{
            margin-bottom: 30px;
        }
        .k-act{
            text-align: left;
            a{
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }

    .big-canvas{
        float: none;
        width: 70%;
        margin: 0 auto;
        margin-bottom: 30px;

        .oee-full-scr {
            font-size: 6.8vw;
        }
    }
    .qpa-itm{
        strong{
            font-size: 2vw;
        }
        .qpa-canvas-inf{
            .chrt{
                span{
                    font-size: 1.6vw;
                }
            }
        }
        .qpa-txt{
            font-size: 1.5vw;
        }
        .hpu-txt {
            font-size: 1.2vw;
        }
    }
    .machine-itm {
        .full-scr-p{
            font-size: 1.5vw;
        }
        .h-full-scr{
            font-size: 1.8vw;
        }
    }
    
    .tile-full-scr {
        .oee-full-scr{
            //font-size:10vw;
            span{
                //font-size: 3vw;
                font-size: .263em;
                top: 20%;
                &:last-child{
                    //font-size: 5vw;
                    font-size: .375em;
                    top: 42%;
                }
            }
        }
    }

    // Event input
    .evt-itm {
        .evt-btn {
            // font-size: 14px;
        }
    }

    // Custom table
    .schedule-tb {
        .table-comp {
            &.big-comp {
                width: 280px !important;
            }
        }
    }

    .machines-content {
       .filt-itm {
           .machines-content-left {
               width: 58.33333333%;
               float: left;
           }

           .machines-content-right {
               width: 41.66666667%;
               float: left;
           }

           .title-filt-detail {
               text-align: right;
           }
       }
    }
}

@media (min-width: 1200px){
    .evt-tab-list {
        .evt-ipt-lst {
            .col-lg-3:nth-child(4n+1) {
                clear: left;
            }
        }
    }

    .tile-section.machine-tab .qpa-canvas-inf canvas {
        width: 45px;
        height: 45px;
    }
}

@media (min-width: 1080px) and (max-width: 1199px){
    .tile-section.machine-tab .qpa-canvas-inf canvas {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .evt-tab-list {
        .evt-ipt-lst {
            .col-lg-3:nth-child(3n+1) {
                clear: left;
            }
        }
    }
}

@media (max-width: 1079px) {
    .machine-tab .tile-itm {
        width: 50% !important;
    }

    .tile-section.machine-tab .qpa-canvas-inf canvas {
        width: 45px;
        height: 45px;
    }
}


@media (min-width: 992px) {
    .kf-filter-tag {
        padding-left: 0;
    }
}