/*
    Build hierachy tab css
*/ 

.machines-blk{
  margin-bottom: 30px;
  h2{
      background-color: @l_dark;
      color: #fff;
      font-size: 20px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      padding: 15px;
      margin-bottom: 0;
  }
  .srch-machine{
      border: 1px solid #ddd;
      border-top: none;
      position: relative;
      .ipt{
          border-radius: 0;
          border: none;
          height: 40px;
          line-height: 40px;
      }
      button{
          position: absolute;
          border: none;
          background-color: transparent;
          right: 0px;
          top: 10px;
      }
  }
}

.enable-toggle{
  a{
      display: inline-block;
      color: #C8C6C8;
      background-color: #DEDCDE;
      text-transform: uppercase;
      padding: 7.5px;
      font-weight: bold;
      &:hover, &:active, &:focus, &:visited{
          text-decoration: none;
      }
      &.active{
          background-color: @green;
          color: #fff;
      }
      &:last-child{
          margin-left: -3px;
      }
  }
}

.machines-lst{
  border: 1px solid #ddd;
  border-top: 0;
  overflow: auto;
  min-height: 150px;
  position: relative;
  ul{
      li{
          a{
              display: block;
              color: #333;
              text-decoration: none;
              height: 38px;
              line-height: 38px;
              border-bottom: 1px solid #ddd;
              padding: 0 15px 0 25px;
              position: relative;
              cursor: move;
              span{
                  display: none;
                  position: absolute;
                  left: 9px;
                  top: 0px;
                  i{
                      &:first-child{
                          margin-right: 1px;
                      }
                  }
              }
              &.active, &:hover{
                  background-color: #F3F7FF;
                  span{
                      display: inline-block;
                  }
              }
          }
          &:last-child{
              a{
                  border-bottom: none;
              }
          }
      }
  }
}

.build-blk{
  .chart-ttl{
      .btn{
          position: absolute;
          top: 4px;
      }
      .add-hie{
          right: 66px;
      }
      .save-hie-build{
          right: 0;
      }
  }
}

.build-hie-blk{
  position: relative;
  min-height: 150px;
  ul{
      li{
          border: 1px solid #ddd;
          border-radius: 2px;
          padding: 8px;
          margin-bottom: 8px;
          .ipt{
              background-color: #fff;
          }
          &.active{
              background-color: #F3F7FF;
              &:hover{
                  .ipt{
                      background-color: #fff;
                  }
              }
          }
          &:hover{
              background-color: #F3F7FF;
              .ipt{
                  background-color: transparent;
              }
          }
      }
  }
}

.edit-hie{
  position: relative;
  a{
      color: #333;
      text-decoration: none;
      position: absolute;
      font-size: 18px;
      top: 3px;
      &.del-hie{
          right: 0;
      }
      &.close-upt{
          left: 0;
          display: none;
          color: @red;
      }
      &.edit-btn{
          left: 50%;
          .transform(translateX(-50%));
      }
  }
  &.editting{
      a{
          &.close-upt{
              display: inline-block;
          }
          &.edit-btn{
              color: @green;
              i{
                  &:before{
                      content: '\f00c';
                  }
              }
          }
      }
  }
}

// New hierachy form
.general-frm{
  .new-usr-valid{
      margin-bottom: 15px;
  }
  textarea{
      margin-bottom: 15px;
  }
}

.tab-content{
  position: relative;
  .add-hie-prog{
      position: absolute;
      top: 0;
      width: 100%;
  }
}

.search-add{
  .c-tb{
      height: 470px !important;
  }
  .btn{
      padding: 0 25px;
  }
}

// Build hierachy
.build-hie-blk{
  .node-wrapper{
      position: relative;
  }
  .node-content-wrapper{
      margin-bottom: 10px;
      width: 100%;
      padding: 10px;
      position: relative;
      box-shadow: unset;
      border: 1px solid #DCDCDC;

      input{
          width: 80%;
          padding: 8px;
          outline: none;
          border: 1px solid #ddd;
          margin-left: 40px;
          &:focus{
              border-color: #bbb;
          }
          &:disabled{
              background-color: transparent;
              border: none;
          }
          &.err{
              border-color: @red;
          }
      }
      button{
          border: none;
          background-color: transparent;
          position: absolute;
          top: 50%;
          .transform(translateY(-50%));
          &:before{
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 16px;
          }
      }
      .del-btn{
          right: 10px;
          &:before{
              content: '\f1f8';
          }
      }
      .save-btn{
          color: @green;
          right: 50px;
          display: none;
          &:before{
              content: '\f00c';
          }
          &.shw{
              display: inline-block;
          }
      }
      .edit-btn{
          right: 50px;
          &:before{
              content: '\f040';
          }
          &.hide{
              display: none;
          }
          // &.check{
          //     color: @green;
          //     &:before{
          //         content: '\f00c';
          //     }
          // }
      }
      .close-btn{
          right: 90px;
          color: @red;
          display: none;
          &:before{
              content: '\f00d';
          }
          &.shw{
              display: block;
          }
      }
      .expand-child{
          left: 30px;
          display: none;
          &:before{
              content: '\f054';
              font-size: 14px;
          }
          &.has-child{
              display: block;
              &.expand-mode{
                  &:before{
                      content: '\f078';
                      font-size: 14px;
                  }
              }
          }
      }
  }
  .toggle-children-wrapper{
      position: absolute;
      // display: none;
      left: 30px;
      top: 16px;
      z-index: 100;
      &.hide{
          display: none;
      }
      .toggle-children{
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 14px;
          width: auto;
          height: auto;
          background-image: none;
          &:before{
              content: '\f054';
          }
      }
  }
  .toggle-children-placeholder{
      &.hide{
          display: none;
      }
  }
  .can-drop{
      display: none;
      position: absolute;
      top: 50%;
      .transform(translateY(-50%));
      left: 10px;
  }
  .tree-node-active > .node-wrapper > .node-content-wrapper, 
  .tree-node-focused > .node-content-wrapper, .node-content-wrapper:hover{
      .can-drop{
          display: inline-block;
      }
  }

  .node-content-wrapper{
      &.removed{
          display: none;
      }
  }
  
}


.machines-lst{
  .n-name{
      margin-left: 10px;
      padding: 10px;
      display: block;
  }
  .can-drop{
      display: none;
      position: absolute;
      left: 5px;
      top: 12px;
  }
  .node-content-wrapper{
      border-radius: 0;
      width: 100%;
      background-color: #fff;
      // padding: 10px;
      position: relative;
      &:hover{
          background-color: #F3F7FF;
      }
  }
  .toggle-children-placeholder{
      display: none;
  }
  .tree-node-active > .node-wrapper > .node-content-wrapper, 
  .tree-node-focused > .node-content-wrapper, .node-content-wrapper:hover{
      box-shadow: none;
      .can-drop{
          display: inline-block;
      }
  }
}
.node-drop-slot{
  &.is-dragging-over{
      background-color: #F3F4F5 !important;
      border-color: #888 !important;
      border-style: dashed !important;
      height: 64px !important;
      margin-bottom: 10px;
  }
}

.toggle-children-placeholder{
  display: none !important;
  &.toggle-children-wrapper-collapsed{
      display: inline-block !important;
  }
}

.drop-fake{
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px dashed #ccc;
  text-align: center;
  line-height: 64px;
  font-size: 14px;
  display: none;
  &.shw{
      display: block;
  }
}

#add-build-hier{
  &.disabled{
      position: relative;
      color: #eee;
      pointer-events: none;
  }
}
.add-hier-btn{
  margin-top: 30px;
}
.difinition-tan{
  .assign-user{
      margin-bottom: 15px;
  }
}

.build-hie-blk{
  .root-node{
      display: none;
  }
}

.tree-node-loading{
  span{
      display: none;
  }
}

.dashboard-ct .c-tab a.disabled {
  pointer-events: none;
  color: #ddd;
}
