/*
 * Login css
 *
*/
body {
    &.login-page {
        //background-image: url('../../../images/login-bg-sm.jpg');
        background-repeat: no-repeat;
        background-color: #eeeeef;
        background-attachment: fixed;
    }
}
.login-page{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    // padding-left: 135px;
    //background-image: url('~assets/images/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .fontRaleway(400);
    &.loaded{
        .caption{
            .cap-ct{
                .transform(translateY(0));
            }
        }
        h1{
            img{
                .transform(translateY(0));
            }
        }
        .login-frm{
            right: 0;
            &:after{
                right: 0;
            }
            .login-ct{
                opacity: 1;
                .transform(translateY(-50%));
                z-index: 500;
                &.hide-login{
                    opacity: 0;
                    .transform(translate(100%, -50%));
                    z-index: 300;
                }
            }
        }
    }
    .ttl{
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .m-logo {
        background-image: url('~assets/images/full-logo.png');
        background-size: contain;
        background-position: left;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 100px;
        height: 100%;
        display: block;
    }
} 
.text{
    .transform(translateY(-50%));
    position: absolute;
    top: 50%;
    color: #7f8181;
    z-index: 200;
    overflow: hidden;
    padding-left: 135px;
    width: 740px;
    h1{
        padding-bottom: 25px;
        overflow: hidden;
        a{
            display: block;
        }
        img{
            .transform(translateY(120px));
            .transition(all 0.9s ease);
            width: 100%;
        }
    }
    .caption{
        padding-top: 25px;
        overflow: hidden;
        .cap-ct{
            .transform(translateY(-200px));
            .transition(all 0.9s ease);
        }
    }
    h3{
        &:after {
            content: "Welcome to Automation Intellect";
        }
        span{
            font-weight: 700;
        }
        margin-bottom: 25px;
    }
    p{
        width: 525px;
        font-size: 12px;
        letter-spacing: 1px;
        color: #ddd;
    }
}

.login-proccess{
    // position: relative;
    .transition(all 0.4s ease);
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        z-index: 100;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.login-frm{
    width: 400px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    color: #fff;
    z-index: 250;
    font-size: 24px;
    &.external_auth {
        .spinner {
            z-index: 500;
        }
        .error-msg {
            z-index: 500;
            position: absolute;
            margin: auto;
            width: 100%;
            text-align: center;
            top: 50%;
        }
    }

    .ttl {
        font-size: 0.75em;
    }

    .usr-lst,
    .choose-pg .btn{
        font-size: 0.5em;
    }

    &:after{
        content: '';
        display: block;
        position: fixed;
        top: 0;
        right: -400px;
        background-color: #000000;
        opacity: 0.7;
        z-index: 260;
        width: 400px;
        height: 100%;
        .transition(all 0.7s ease 0.2s);
    }

    .login-ipt{
        .fGogamBold();
        height: auto;
        min-height: 34px;
        outline: none;
        width: 100%;
        border: none;
        background-color: transparent;
        border-bottom: 1px solid #444444;
        display: block;
        padding: 6px 12px 6px 0;
        font-size: 0.58em;
        line-height: 1.42857143;
        color: #fff;
        background-image: none;
        font-weight: normal;
        &:focus{
            border-bottom-color: #f1f1f1;
        }
    }

    .btn-login{
        color: #fff;
        outline: none;
        display: block;
        margin-bottom: 0;
        text-align: center;
        cursor: pointer;
        background-image: none;
        opacity: 1;
        border: none;
        white-space: nowrap;
        letter-spacing: 5px;
        padding: 15px;
        border-radius: 3px;
        font-size: 0.58em;
        line-height: 1.42857143;
        background-color: @blue;
        width: 100%;
        font-weight: bold;
        &:hover{
            background-color: darken(@blue, 10%);
        }
    }

    .remember{
        display: flex;
        position: relative;
        input[type="checkbox"]{
            display: none;
        }

        label{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 0.83em;
            margin-bottom: 0px;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-weight: 100;
            cursor: pointer;
            span{
                //font-size: 12px;
                font-size: 0.6em;
                font-weight: 100;
                position: absolute;
                left: 22px;
                top: 48%;
                transform: translateY(-50%);
                display: inline-block;
                .fontRaleway(400);
            }
            &:before{
                content: '\f096';
            }
        }

        input[type=checkbox]:checked + label:before{
            content: '\f046';
        }
        .forgot-pass{
            text-align: right;
            position: absolute;
            right: 0;
        }
    }

    .logo{
        background-image: url('~assets/images/full-logo.png');
        background-size: contain;
        background-position: left;
        background-repeat: no-repeat;
        min-height: 100px;
        display: block;
        width: 300px;
        position: fixed;
        z-index: 500;
        top: 10%;
        left: 50%;
        .transform(translateX(-50%));
        opacity: 0;
        .transition(all 0.7s ease 0.9s);
    }

    .splash-page-text {
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        width: 100%;
        background-position: center;
        min-height: 45px;
        max-width: 100%;
        z-index: 400;
        bottom: 20px;
        position: absolute;
    }
}

.login-ct{
    position: absolute;
    // left: 30px;
    // right: 30px;
    padding: 30px;
    width: 100%;
    top: 50%;
    opacity: 0;
    // z-index: 400;
    .transform(translateY(-45%));
    .transition(all 0.9s ease 0.8s);
    a{
        &:hover{
            color: @blue;
        }
    }
}

.login-ct{
    .err-msg{
        // .transform(scale(0));
        .opacity(0);
        .transition(all 0.7s ease);
        display: block;
        font-size: 0.5em;
        text-align: left;
        &.shw{
            // .transform(scale(1));
            .opacity(1);
        }
    }
    p{
        font-size: 12px;
    }
    .valid-msg{
        display: inline-block;
        font-size: 0.5em;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: normal;
        .transform(scale(0));
        .transition(all 0.7s ease);
        &.shw{
            .transform(scale(1));
        }
    }
}

.login-ct{
    .lg-ipt{
        position: relative;
        .red-line:before{
            content: '';
            position: absolute;
            height: 1px;
            width: 0;
            margin-top: -1px;
            background-color: @red;
            .transition(all 0.4s ease);
        }
        &.shw{
            .red-line:before{
                width: 100%;
            }
        }
    }
}

.choose-pg{
    position: absolute;
    left: 30px;
    right: 30px;
    z-index: 400;
    top: 50%;
    .transform(translateY(-50%));
    opacity: 0;
    .transition(all 0.4s ease 1.3s);
    a{
        display: block;
        margin-bottom: 15px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 3px;
    }
    &.shw-page{
        opacity: 1;
    }
}
.choose-pg{
    a{
        position: relative;
        span{
            position: absolute;
            top: 8px;
            right: 10px;
            display: none;
        }
        &.choose{
            span{
                display: block;
            }
        }
    }
}
.usr-lst{
    height: 300px;
    overflow-y: auto;
    display: none;
    &.shw{
        display: block;
        >.usr-lst{
            display: block;
        }
    }
}
.usr-tb{
    margin-bottom: 0;
    background-color: transparent !important;
    tr{
        th{
            border-color: #3c3c3c !important;
        }
        td{
            border-color: #3c3c3c !important;
            a{
                font-weight: 700;
            }
        }
    }
    .client-btn{
        i{
            margin-left: 10px;
            display: none;
        }
        &.active{
            i{
                display: inline-block;
            }
        }
    }
}

// Login error modal
.login-err{
    h5{
        margin-bottom: 15px;
        line-height: 20px;
    }
    a{
        width: 100%;
    }
}
.login-err-head{
    span{
        color: @red !important;
    }
}


// EULSA Signed modal
.confirm-contract{
    display: inline-block;
    label{
        
        span{
            .fontRaleway(400);
            font-size: 16px;
        }
        &:before{
            width: auto;
        }
    }
}

.eulsa-signed-ct{
    // padding: 30px 15px;
    p{
        line-height: 1.6;
    }
    ul{
        padding-left: 30px;
        li{
            list-style: disc;
        }
    }
    a{
        color: @blue;
        text-decoration: none;
    }
}

#eulsa-signed{
    .modal-lg{
        width: 600px;
    }
    .btn{
        width: 120px;
        margin: 0 auto;
        // display: block;
        float: right;
    }
    h3{
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0;
        text-align: center;
        color: #000;
    }
    .contract-ct{
        height: 250px;
        overflow-y: auto;
    }
    .sign-err{
        margin-bottom: 0;
        margin-top: 15px;
    }
    .check-confirm{
        position: relative;
    }
}