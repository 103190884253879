// custom mat dropdown

.custom-mat-dropdown {
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    position: relative;
}

// button 

.custom-mat-dropdown-btn{
    width: 100%;
    text-align: left;
    padding: 12px;
    background-color: #F5F5F5;
    border: 0px;
    border-radius: 5px;

    transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

    &.multiple {
        height: unset;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        &::before{
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 12px;
            background-color: #F5F5F5;
            z-index: 1;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:hover {
            &::before{
                background-color: #F3F6FF;
            }
        }
    }
    &.multiple.no-value  {
        height: 54px;
    }

    &:hover, &:focus {
        background-color: #F3F6FF;
    }

}

//icon
.custom-mat-icon {
    i.fa {
        position: absolute;
        right: 5px;
        font-size: 14px;
        top: ~"calc(50% - 7px)";
    }
}

//drop down
.custom-mat-dropdown .dropdown-menu {
    right: 0;
    padding: 0px;
    overflow: hidden;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.custom-mat-dropdown .sub-dropdown-menu {
    max-height: 304px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-track {
        background-color: #F5F5F5;
    }
    > li {
        padding: 20px;
        padding-right: 40px;
        border-bottom: 1px solid #EEEEEE;
        position: relative;
        cursor: pointer;
        &:hover {
            background-color: #005AFF;
            color: #fff;
            i.fa {
                color: #fff;
            }
        }
        &:last-child {
            border-bottom: 0px;
        }

        i.fa {
            position: absolute;
            font-size: 20px;
            right: 15px;
            top: ~'calc(50% - 10px)';
            color: #1cc861;
        }
    }
}

.custom-mat-dropdown .search-input-wrapper {
    position: relative;
    width: 100%;

    .search-icon {
        position: absolute;
        left: 25px;
        top: ~"calc(50% - 13px)";
        font-size: 18px;
        color: #7F8181;
    }
}

.custom-mat-dropdown .custom-mat-search-input {
    width: ~"calc(100% - 24px)";
    border: 0px;
    margin: 8px 12px;
    padding: 12px 12px 12px 38px;
    background-color: #f7f7f7;
}

.custom-mat-dropdown .custom-mat-label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;

    display: block;
    transform-origin: top left;

    top: 3px;
    left: 0;
    position: absolute;
    transform: translate(12px, 17px) scale(1);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    
    &.focused, &.has-value {
        transform: translate(12px, 10px) scale(0.75);
    }
}

.custom-mat-dropdown.has-label {
    .custom-mat-dropdown-btn {
        padding: 27px 12px 10px;
    }
}