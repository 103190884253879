// Shift schedule css
.new-shift {
    position: relative;
    min-height: 200px;
    .tooltip {
        z-index: 1000;
    }
}

.view-mode-schedule {
    margin-top: 15px;
    .title {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        margin-right: 15px;
    }
    .view-mode-btn {
        display: inline-block;
        font-size: 16px;
        color: #ddd;
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid #ddd;
        &:last-child {
            border-right: none;
        }
        &.active, &:hover {
            color: #333;
        }
    }
}

.save-schedule {
    margin-bottom: 15px;
}

.msg-panel {
    // margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
}

.shift-tab {
    cursor: pointer;
}

.custom-action-single {
     .tb-comp-inner {
         a.tb-act-btn {
             left: 50%;
             transform: translate(-50%, -50%);
         }
     }
}

.ask-tpl {
    padding: 30px 0px;

    .btn + .btn {
        margin-left: 5px;
        margin-right: 0;
    }
}