/*
    Datasource page css
*/ 

.cloud-pass{
    position: relative;
    a{
        display: inline-block;
        text-decoration: none;
        color: #333;
        // position: absolute;
        // right: 0;
        // top: 50%;
        // .transform(translateY(-50%));
        font-size: 14px;
    }
    .ipt{
        width: auto;
        // margin-right: 10px;
        padding: 0;
        height: auto;
        line-height: normal;
    }
}
.cloud-tb{
    .conn-url{
        display: block;
        width: 250px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.actions-lst{
    &.two-actions {
        a{
            position: initial;
            margin: 0 5px;
        }
    }
}

#new-client-dts, #client-dts-info {
    .input-field-container {
        i.eye-icon {
            float: right;
            margin-left: -25px;
            margin-top: 10px;
            position: absolute;
            cursor: pointer;
        }
    }
}