/*
 *
 * Contract css
 *
*/ 


.txt-editor{
    &.err{
        .ql-container, .ql-toolbar{
            border-color: @red;
        }
    }
}

#contract-info{
    .lock-user{
        text-align: left;
        padding-right: 0;
    }
    .modal-content{
        z-index: 50;
        .box-shadow(none);
    }
}



.list-head{
    border-bottom: 1px solid #ddd;
    height: 56px;
    margin-bottom: 30px;
    position: relative;
    .chk-all{
        line-height: 56px;
        float: left;
    }
    .order-contract{
        float: right;
        line-height: 56px;
        color: #333;
        &:hover{
            color: #000;
        }
        &:focus, &:active, &:hover{
            text-decoration: none;
        }
    }
}

.contract-itm{
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-right: 35px;
    .del-btn{
        position: absolute;
        right: 0;
        top: 50%;
        .transform(translateY(-50%));
        color: #444;
        text-decoration: none;
        font-size: 16px;
        &:hover, &:focus, &:active{
            text-decoration: none;
        }
        &:hover{
            color: #000;
        }
    }
    .d-chk{
        margin-right: 5px;
    }
    .open-modal{
        font-size: 14px;
    }
}