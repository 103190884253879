/*
 * Machine header
 *
*/

.machine-header {
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  position: relative;
  margin-top: -60px;
  border-bottom: 3px solid #ddd;
  width: 100%;

  .mh-sub {
    &.active {
      display: block;
    }
  }

  .group-machine {
    position: relative;
    width: 100%;

    > a {
      display: block;
      color: @dark;
      font-size: 14px;
      padding: 20px 0;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
      .hash {
        margin-left: 10px;
        margin-right: 10px;
      }
      span {
        font-weight: normal;
        max-width: ~"calc(100% - 15px)";
      }
      
      i {
        margin-left: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }
    }
  }

  .from-to {
    .group-machine;
    a {
      position: relative;
      .icon-calendar {
        font-size: 24px;
        position: absolute;
        margin-right: 20px;
        /*top: -5px;*/
        top: 50%;
        left: -50px;
        .transform(translateY(-50%));
        color: #11141c;
      }
      img {
        width: 22px;
        height: 22px;
        margin-right: 20px;
      }
      // span{
      //     font-weight: bold;
      // }
      .start-date,
      .end-date {
        font-weight: bold;
      }
    }
  }
}

// date picker
.date-picker {
  width: 440px;
  background-color: #fff;
  .box-shadow(0px 3px 3px 0px rgba(0, 0, 0, 0.5));
  position: absolute;
  right: -20px;
  top: 57px;
  z-index: 50;
  padding: 10px 15px 20px 15px;
  display: none;

  .period {
    margin-bottom: 20px;

    .btn {
      display: inline-block;
      width: 108px;
      border: 1px solid #f4f4f4;
      border-radius: 2px;
      text-align: center;
      font-size: 12px;
      margin-right: 40px;
      background-color: transparent;
      color: #11141c;
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
      &:hover {
        border-color: #bbbbbb;
        background-color: transparent;
        text-decoration: none;
      }
      &.active {
        border-color: @blue;
        background-color: transparent;
      }
    }
  }
}

.date-picker {
  .picker-ipt {
    margin-bottom: 20px;

    span {
      font-weight: bold;
      font-size: 12px;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }

    input[type="text"] {
      margin-left: 12px;
      width: 155px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .start-blk,
    .end-blk {
      display: inline-block;
      width: 155px;
      position: relative;
    }
  }
  .custom-btn {
    width: 100%;
  }
}

.tile-date {
  table {
    thead {
      tr {
        &:first-child {
          th {
            &:first-child {
              display: table-cell !important;
            }
          }
        }
      }
      tr {
        &:last-child {
          th {
            &:first-child {
              // display: none;
            }
          }
        }
      }
      th {
        button {
          border: none;
        }
        &:first-child,
        &:last-child {
          button {
            i {
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
        }
        &:first-child {
          button {
            i {
              &:before {
                content: "\f053";
              }
            }
          }
        }
        &:last-child {
          button {
            i {
              &:before {
                content: "\f054";
              }
            }
          }
        }
      }
    }
  }
}

.not-show {
  display: none;
}

.tile-date {
  .well {
    position: absolute;
    z-index: 100;
    background-color: #fff;
    border-radius: 0;
    .box-shadow(0px 3px 1px 0px rgba(0, 0, 0, 0.5));
  }
}

.tile-start {
  .well {
    left: 0px;
  }
}

.tile-end {
  .well {
    right: 0px;
  }
}

.tile-date {
  table {
    tbody {
      tr {
        td {
          &:first-child {
            // display: none;
            em {
              // display: none;
              font-size: 10px;
            }
          }
          button {
            border: none;
            background-color: transparent;
            border-radius: 0;
            padding: 0 9px;
            span {
              font-size: 10px;
              &.text-muted {
                color: #999;
              }
              &.text-info {
                color: @blue;
              }
            }
            &:hover {
              background-color: #ddd;
            }
            &.active {
              box-shadow: none;
              border: none;
              background-color: @l_dark;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.tile-date {
  .well {
    monthpicker,
    yearpicker {
      table {
        tbody {
          tr {
            td {
              &:first-child {
                display: table-cell;
              }
            }
          }
        }
      }
    }
  }
}

// Dashboard datetime picker css
.dashboard {
  .ttl-right {
    float: none;
    .ttl-ipt {
      margin-right: 0;
    }
    .time-picker {
      float: none;
      margin-bottom: 15px;
      > span {
        margin-right: 15px;
      }
      .start-blk {
        margin-right: 15px;
      }
      .ipt {
        height: 34px;
      }
    }
  }

  .ttl-range {
    margin-bottom: 15px;
    .range-lst {
      label {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }

  .ttl-chr-opt {
    float: none;
    height: auto;
    .refresh-btn {
      display: none;
    }
    .btn-apply {
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      height: auto;
    }
  }
}

.version-ct {
  padding: 20px 0;
}
