// dashboard layout
@import "./dashboard-layout.less";

.header-title-default {
  height: 100%;
  display: inline-flex;
  align-items: center;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
  padding-left: 15px;
  > span {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #7f8181;
    text-transform: capitalize;
    margin-left: 10px;
  }
}
//dashboard overview item less
@import "./overview-item/overview-item.less";
//dashboard tooltip item less
@import "./tooltip-item/tooltip-item.less";
//insights modal less
@import "./insights-modal/insights-modal.less";
//hotspot less
@import "./hotspot/hotspot.less";

//ngx datatable css
@import "../../../assets/css/ngx-datatable/material.css";
@import "../../../assets/css/ngx-datatable/icons.css";
@import "../../../assets/css/ngx-datatable/datatable.css";

.justify-between {
  justify-content: space-between;
  display: flex;
}

.mat-block {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.mat-block-header {
  border-bottom: 2px solid #dcdcdc;
  width: 100%;
  padding: 25px;
  height: 77px;
  display: flex;
  align-items: center;
  .form-control {
    height: 30px;
    background-color: #f5f5f5;
    border-color: transparent;
  }
  .header-tittle {
    /* H2 - 18px, Extra Bold */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    text-transform: uppercase;
    /* Text/Black */
    color: #000000;
    margin: 0px;
    display: inline;
  }
  .cus-mr{
    margin-right: 40px;
  }
}

.mat-block-content {
  padding: 24px;
  // display: flex;
  min-height: calc(~"100vh - 280px");
  position: relative;
}

.mat-block {
  &.plant-scheme-block {
    // max-width: 900px;
    min-width: 700px;
    width: 50%;
    .mat-block-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.plant-analysis-block {
    // max-width: 780px;
    min-width: 400px;
    width: calc(~"50% - 30px");
    .mat-block-content {
      overflow: auto;
      max-height: calc(~"100vh - 280px");
      height: calc(~"100vh - 280px");
    }
    .mat-block-header {
      padding: 20px 25px;
      .header-tittle {
        line-height: 30px;
      }
    }
  }
}

.hotspot-legend {
  display: flex;
  max-width: calc(~"100% - 200px");
  width: 520px;
  justify-content: space-between;
  @media (max-width: 1920px) {
    flex-wrap: wrap;
  }
  &.shift-legend {
    width: 300px;
  }
  > li {
    display: flex;
    align-items: center;
    margin-right: 10px;
    @media (max-width: 1920px) {
      margin-right: 0;
      width: 50%;
      flex: 0 0 50%;
      margin-bottom: 5px;
    }
    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
    }
  }

  .status-color {
    width: 50px;
    height: 25px;
    border: 2px solid;
    margin-right: 8px;

    &.green {
      background-color: rgba(16, 168, 31, 0.3);
      border-color: #10a81f;
    }
    &.yellow {
      background-color: rgba(255, 200, 31, 0.3);
      border-color: #ffc81f;
    }
    &.red {
      background-color: rgba(232, 26, 60, 0.3);
      border-color: #e81a3c;
    }
    &.transparent {
      background-color: rgba(0, 0, 0, 0.34);
      border-color: rgba(0, 0, 0, 1);
    }
  }
}

.plant-analysis-tabs {
  display: flex;
  justify-content: center;
  align-items: center;

  > li {
    // float: left;
    list-style: none;
  }

  .tab-button {
    border: 0px;
    background-color: #ffffff;
    color: #7f8181;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;

    &.active {
      color: #005aff;
    }
  }
}

overview-list {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
production-table {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-list {
  overflow: auto;
  margin-right: -12px;
  padding-right: 12px;
  max-height: 100%;

  > li {
    margin-bottom: 17px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.production-table {
  height: 100%;
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
  overflow-x: auto;
  width: 100%;
  // min-width: 830px;
}
.mini-oee-chart-wrapper {
  position: relative;
  min-width: 75px;
  width: 75px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  float: left;
  margin-right: 10px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.mini-oee-chart {
  width: 120px;
  min-width: 120px;
  height: 75px;
  min-height: 75px;
  transform: translate(3px, -10px);
}

.datatable-body-cell-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ngx-custom-cell {
  width: 100%;
  &.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  &.prod-goal {
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: -10px;
  }
}

.ngx-datatable-paging {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 25px;
  border-top: 2px solid #dcdcdc;
  align-items: center;
}
.production-table {
  .datatable-body-cell-label {
    position: relative;
    height: 100%;
  }
  .red-line {
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 5px;
    width: 4px;
    background-color: rgba(232, 26, 60, 0.3);
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: 100;
  }
  .datatable-row-center {
    .datatable-body-cell {
      &:first-child {
        width: 4px !important;
        padding: 0 !important;
      }
      &:nth-of-type(2) {
        width: 33% !important;
      }
    }
  }
}


@keyframes blinking {
  50% {
    background-color: #e81a3c;
  }
}
@-webkit-keyframes blinking {
  50% {
    background-color: #e81a3c;
  }
}
@-moz-keyframes blinking {
  50% {
    background-color: #e81a3c;
  }
}
@media (max-width: 1366px) {
  .hotspot-legend {
    > li {
      span {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
