/**
 * Media 480px-767px
 *
 * description: css rules for screen of new moderm smartphone
 */

@media (max-width: 767px) {
    body {
        &.bd-color {
            overflow: hidden;
            &::after {
                content: "";
                display: block;
                background-color: rgba(0,0,0,0.5);
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 500;
            }
            .color-system-wrap {
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
    .color-system-wrap {
        right: 50%;
        margin-right: -150px;
        top: 50px;
        background-color: #fff;
        border: 1px solid #ddd;
        .border-radius(5px);
        z-index: 510;
        .transform(translateX(0));
        .transition(opacity .3s ease);
    }
    .color-system {
        &.act {
            right: 0;
        }
    }
    .colorpicker {
        z-index: 510;
    }
}

@media (max-width: 540px) {
    #evt-ipt-modal.notice-access-modal .modal-footer .btn {
        width: 100%;

        &.btn-blue {
            margin-left: 0px;
            margin-top: 10px;
        }
    }
}