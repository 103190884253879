dashboard-grid,
dashboard-tiles,
dashboard-geo,
dashboard-plant {
    font-family: 'Rubik', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #C4C4C4;
        border-radius: 2px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #7F8181;
        border-radius: 2px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .header {
      .client-name {
        padding-left: 15px;
        padding-top: 0;
        display: flex;
        align-items: center;
      }
    }

    .machine-header {
      margin-top: 0;
    }
}

dashboard-tiles {
  .machine-itm .oee-full-scr span {
    left: 105%;
    &:first-child {
      top: -20px;
    }
  } 
}