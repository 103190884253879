/*
 * Overview css
 *
*/


.downtime-tbl{
    .table-cus{
        tr{
            &:first-child{
                th{
                    &:nth-child(3){
                        text-align: center;
                    }
                }
            }
            &:last-child{
                td{
                    border-bottom: 1px solid #ddd;
                }
            }
            td{
                border-top: none;
                padding: 10px 8px;
                &:first-child{
                    border-left: none;
                    text-align: center;
                }
                &:last-child{
                    text-align: center;
                }
            }
            th{
                padding: 15px 8px;
                text-align: left;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                font-weight: 700;
                &:first-child{
                    text-align: center;
                }
            }
        }
    }
} 
.fault-tbl{
    overflow: unset;
    .table{
        margin-bottom: 30px;
    }
    .md-ot-fault-table {
        height: 447px;
        overflow-y: auto;
        padding: 20px;
    }
}