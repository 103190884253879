/*
 * Custom setting for all page
 * ---------------------------
 * 1. Button
 * 2. Input
 * 3. Tab
 * 4. Messages 
 * 5. Modal
 * 6. Icon fonts
 * 7. Progress bar
*/

body{
    font-family: "Rubik", sans-serif;
    position: relative;
    font-size: 12px;
    a{
        color: @blue;
        // &:hover{
        //     color: darken(@blue, 10%);
        // }
    }
}

.margin-lft{
    margin-left: 64px;
} 

header .margin-lft { 
//   margin-left: 128px;
}

.btn, ipt{
    box-shadow: none;
    &:active, &:focus{
        box-shadow: none;
    }
}
ul{
    padding: 0;
    margin: 0;
    li{
        list-style: none;
    }
}
a, select, .btn, button, option{
    outline: none;
    &:active, &:focus{
        outline: none;
        box-shadow: none !important;
    }
}
.btn{
    &.active{
        box-shadow: none !important;
    }
}
// 1. Button
.disabled{
    cursor: not-allowed;
}
.btn{
    height: 32px;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0px 12px;
    line-height: 32px;
    min-width: 126px;
    font-weight: 600;
    letter-spacing: 0.4px;
    border-radius: 3px;
}
.btn-default{
    border-color: #999;
    background-color: #fff;
    color: #333;

    &:hover, &:active, &:focus{
        color: #333;
        background-color: #f6f6f6;
    }
}
.btn-blue{
    border-color: @blue;
    background-color: @blue;
    color: #fff;
    &:hover, &:active, &:focus{
        background-color: darken(@blue, 10%);
        color: #fff;
    }
}
.btn-lblue{
    border-color: #005AFF;
    background-color: #005AFF;
    color: #fff;
    &:hover, &:active, &:focus{
        background-color: lighten(#005AFF, 10%);
        border-color: lighten(#005AFF, 10%);
        color: #fff;
    }
}
.btn-line-blue {
    border-color: @blue;
    background-color: #fff;
    color: @blue;
    &:hover, &:active, &:focus{
        background-color: @blue;
        color: #fff;
    }
}
.btn-red{
    border-color: @red;
    background-color: @red;
    color: #fff;
    &:hover, &:active, &:focus{
        background-color: darken(@red, 10%);
        color: #fff;
    }
}
.btn-dgray{
    border-color: @l_dark;
    background-color: @l_dark;
    color: #fff;
    &:hover, &:active, &:focus{
        background-color: darken(@l_dark, 10%);
        color: #fff;
    }
}
.btn-lgray{
    border-color: #f3f4f5;
    background-color: #f3f4f5;
    color: #333;
    &:hover, &:active, &:focus{
        background-color: darken(#f3f4f5, 10%);
        color: #333;
    }
}
.mr-5 {
    margin-right: 5px;
}

// 2. Input
.ipt{
    height: 32px;
    outline: none;
    width: 100%;
    border: 1px solid #ddd;
    background-color: transparent;
    padding: 0px 20px;
    font-size: 12px;
    line-height: 32px;
    color: #333;
    background-image: none;
    font-weight: normal;
    border-radius: 2px;
    &:focus{
        border-color: #bbb;
    }
    &:disabled {
        border: none;
    }
    &.err {
        border-color: @red;
    }
    &[readonly] {
        cursor: default;
        background-color: #ddd;
    }
}
.txt-area{
    height: auto;
    padding: 10px 20px;
    line-height: 18px;
}

// 3. Tab
.c-tab-ct{
    padding-top: 30px;
    // padding-bottom: 70px;
}

.c-tab{
    border-bottom: 2px solid #ddd;
    
    >li{
        // margin-bottom: 0;
        >a{
            border: none;
            border-radius: 0;
            padding-left: 40px;
            padding-right: 40px;
            margin-right: 0;
            font-size: 14px;
            color: @dark;
            border: none;
            color: #333;
            &:hover {
                background-color: transparent;
                border: none;
                border-bottom: 4px solid #ddd;
            }
        }
        &.active{
            a{
                border: none;
                background-color: transparent;
                color: @blue;
                font-weight: 700;
                border-bottom: 4px solid @blue;
                &:focus, &:hover {
                    color: @blue;
                    background-color: transparent;
                    border: none;
                    border-bottom: 4px solid @blue;
                }
            }
        }
    }
} 

// 4. Messages
.success-msg{
    color: @green;
}
.primary-msg{
    color: @blue;
}
.warning-msg{
    color: @yellow;
}
.error-msg{
    color: @red;
}
.green-text {
    color: @green;
}
.red-text {
    color: @red;
}
.yellow-text {
    color: #f1d017;
}

.text-bold {
    font-weight: bold;
}
// 5. Modal
.modal-backdrop{
    width: 100%;
    height: 100%;
    z-index: 800;
    position: fixed;
}
.modal-header{
    position: relative;
    .close{
        position: absolute;
        font-size: 24px;
        top: 15px;
        right: 15px;
        border: none;
        background-color: transparent;
        span{
            color: #ddd;
        }
        &:hover{
            span{
                color: #ccc;
            }
        }
    }
}
.modal-content{
    z-index: 1020;
    border-radius: 2px;
}

textarea{
    resize: none;
    height: 170px;
    overflow-y: auto;
}

// 6. Icon font 
@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?nba1qd');
  src:  url('../../fonts/icomoon.eot?nba1qd#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?nba1qd') format('truetype'),
    url('../../fonts/icomoon.woff?nba1qd') format('woff'),
    url('../../fonts/icomoon.svg?nba1qd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bar-chart:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-electricity:before {
  content: "\e904";
}
.icon-monitor:before {
  content: "\e905";
}
.icon-networking:before {
  content: "\e906";
}
.icon-notepad:before {
  content: "\e907";
}
.icon-passage-of-time:before {
  content: "\e908";
}
.icon-pointer:before {
  content: "\e909";
}
.icon-running:before {
  content: "\e90a";
}
.icon-settings:before {
  content: "\e90b";
}
.icon-settingsui:before {
  content: "\e90c";
}
.icon-upload:before {
  content: "\e90d";
}
.icon-warning:before {
  content: "\e90e";
}
.icon-cursor:before {
  content: "\e90f";
}

// 404 @page
.not-found-msg{
    position: fixed;
    left: 0;
    top: 50%;
    text-align: center;
    width: 100%;
}

// Dropdown button 
.dropdown-menu{
    border: none;
    border-radius: 0;
    margin: 0;
}

// 7. Progress bar
.progress{
    margin-bottom: 0;
    border-radius: 0;
    height: 5px;
    display: none;
    &.shw{
        display: block;
    }
}

// Title for h1 have span
.m-ttl h1 span {
    display: inline-block;
}

#evt-ipt-modal.notice-access-modal .modal-footer .btn {
    width: auto;
}
body {
    &.mn-fixed {
        .main {
            margin-left: 220px;
        }
        header.static,
        .header {
            margin-left: 280px;
        }
    }
}