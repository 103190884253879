/*
 * Faults tab css
 *
*/

.f-t-table{
    .c-tb-th{
        .table-cus{
            tr{
                th{
                    border-top: 3px solid #ddd;
                }
            }
        }
    }
} 

.f-sankey{
    margin-bottom: 30px;
    width: 100%;
    overflow-x: auto;
}
.skey{
    display: none;
    &.shw{
        display: block;
    }
    &.chart-draw{
        height: auto;
    }
}
.node rect {
    cursor: move;
    fill-opacity: .9;
    shape-rendering: crispEdges;
}
.node text {
    pointer-events: none;
    font-size: 12px;
    // text-shadow: 0 1px 0 #fff;
    
}
.link {
    fill: none;
    stroke: #000;
    stroke-opacity: .2;
}
.link:hover {
    stroke-opacity: .5;
}
.node{
    position: relative;
    overflow: hidden;
}
.nodeValue{
    font-size: 12px !important;
}
div.f-tooltip {   
  position: absolute;           
  text-align: center;   
  .fGogamBook;        
  width: 120px;                  
  height: auto;                 
  padding: 2px;   
  font-weight: 700;          
  background: @blue;  
  color: #fff; 
  border: 0px;      
  border-radius: 2px;           
  pointer-events: none;         
}
.sankey-toggle {
    width: 100%;
    position: relative;
    z-index: 100;
    margin-bottom: 15px;
    transition: width 0.2s;
    a{
        background: #fff;
        display: inline-block;
        height: 40px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
        text-align: center;
        line-height: 36px;
        font-family: 'gotham_nvvbold_bbok';
        font-weight: normal;
        cursor: pointer;
        .transition(all 0.4s ease);
        padding-left: 15px;
        padding-right: 15px;
        color: #333;
        &:hover, &:active, &:focus{
            text-decoration: none;
        }
        &:hover{
            background-color: #eee;
        }
        &.active{
            background-color: #ddd;
        }
        &:last-child{
            border-right: 1px solid #eee;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            margin-left: -3px;
        }
    }

    &.active {
        top: 70px;
        position: fixed;
        right: -30px;
    }
}
.sankey-root{
    position: relative;
    margin-top: 70px;
    min-height: 900px;
}

.amcharts-legend-div g[aria-label~=Unselect] {
    transform: translate(-75px, 8px);
    -ms-transform: translate(-75px, 8px);
    -webkit-transform: translate(-75px, 8px);
    -moz-transform: translate(-75px, 8px);

    > path{
        fill: #fff;
        stroke: #000;
        transform: translate(35px, 0);
        -ms-transform: translate(35px, 0);
        -webkit-transform: translate(35px, 0);
        -moz-transform: translate(35px, 0);
    }

    > text {
        transform: translateY(30px);
        -ms-transform: translateY(30px);
        -webkit-transform: translateY(30px);
        -moz-transform: translateY(30px);
    }

    g {
        transform: translate(35px, 0);
        -ms-transform: translate(35px, 0);
        -webkit-transform: translate(35px, 0);
        -moz-transform: translate(35px, 0);

        > path {
            stroke: #000;
            stroke-width: 2px;
            d: path('M0 5l-5-5.3 1.4-1.43 3.425 3.7 5.52-6.05 1.45 1.403z');
        }
    }

    > rect {
        width: 70px;
        transform: translate(-19px, 23px);
        -ms-transform: translate(-19px, 23px);
        -webkit-transform: translate(-19px, 23px);
        -moz-transform: translate(-19px, 23px);
    }
}

.mc-tab,
.parts-tab {
  .faults-table,
  .col-chrt,
  .d-t-part-pr,
  .uptime-chrt {
    clear: both;
  }
}
// css downtime 
.downtime-tab{
    .cus-cursor{
        cursor: pointer;;
    }
    .chart-ttl,.title-insights {
        label{
            margin-bottom:0px;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
        }
    }
    .downtime-insights{
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 40px;
        .icon-drop label{
            font-size: 24px;
            color: #000000;
            margin-bottom:5px;
            margin-left: 15px;
        }
        .mr-t{
            margin-top: 40px;
        }
        .flex-excel{
            display: flex;
            width: 100%;
            // align-items: center;
            margin-bottom: 15px;
        }
        .title-insights{
            label{
                width: 100%;
            }
            .chart-ttl{
                width: 100%;
                margin-bottom: 0px;
                .bg-cus-btn{
                    background-color: #005AFF!important;
                    color: #FFFFFF;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    // line-height: 17px;
                    height: 35px!important;
                    min-width: 160px;
                    line-height: 35px;
                    padding: 0px 16px!important;
                    i{
                        color: #FFFFFF;
                    }
                }
            }
        }
        .pd-t-cus{
            padding-top: 40px;
        }
        
    }
    .table-icon{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        color: #7f8181;
        i{
            line-height: 0.5;
        }
    }
    .th-flex{
        display: flex;
        align-items: center;
        .title-th{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            color: #7F8181;
        }
    }
    .table-tr-cus{
        padding: 32px 0px 19px 10px;
        border-bottom: 1px solid #ddd;
        margin-left: 26px;
        margin-right: 20px;
    }
    .box-table{
        background: #FFFFFF;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }
    .content-downtimeInsights{
        width: 33%;
        padding-right: 32px;
        .item-insights{
            padding: 12px 12px 12px 12px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            height: 273px;
        }
        .box-green{
            display: flex;
            align-items: center;
            background: #F3F6FF;
            border-radius: 3px;
            margin-bottom: 20px;
            height: fit-content;
            // .head-green{
            //     width: 8px;
            //     height: fit-content;
            //     background: #ABE3B0;
            //     border-radius: 3px 0px 0px 3px;
            // }
            .content-green{
                position: relative;
                label{
                    margin-bottom: 0px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: #000000;
                    margin: 8px;
                }
                label:before{
                    content: "";
                    width: 8px;
                    height: 100%;
                    background: #ABE3B0;
                    position: absolute;
                    bottom: 0px;
                    left: -5px;
                    border-radius: 3px 0px 0px 3px;
                }
            }
        }
        .title-time{
            margin-bottom: 12px;
            label{
                margin-bottom: 0px;
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                text-transform: uppercase;
                color: #000000;
            }
        }
        .hitory-time{
            p{
                margin-bottom: 8px!important;
            }
            ul{
                margin-left: 28px;
            }
            .blue-name{
                margin-bottom: 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #005AFF;
                cursor: pointer;
            }
        }
    }
    .d-f-downtimeInsights{
        display: flex;
    }
    #downtimePartsProducedChart,#manualDowntimeStackedChart,#machineFaultsDowntime{
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
    .div-pie-chart{
        // display: flex;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        .box-pie{
            width: 100%; 
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            // margin-right: 20px;
            // height: 600px;
            min-height: 600px;
            position: relative;
            
        }
        .m-t-b{
            margin-top: 40px;
            margin-bottom: 12px;
        }
        // .item-pie{
        //     display: flex;
        // }
    }
    .table-cus tr th span{
        padding-left: 0px!important;
    }
    .table-cus{
        .th-border-none{
            border: none!important;
            background-color: red;
        }
        padding: 16px 20px 15px  25px;
        th{
            font-style: normal;
            font-weight: normal;
            font-size: 14px!important;
            line-height: 17px;
            color: #000000!important;
            padding-left: 10px!important;
            padding-bottom: 15px!important;
        }
    }
    #downtimePartsProducedChart text, #down-part-produce-res text{
        cursor: pointer;
    }
    #down-part-produce-res .amcharts-legend-div{
        height: 0px!important;
        min-height: 65px!important;
        // height: unset!important;
        position: relative;
        text-align: left;
    }
    .showExpand button,.showExpand i,.hideExpand button,.hideExpand i{
        background: none;
        border: none;
        color: #007bff;
        font-weight: bold;
        padding-right: 0px;
        // margin-right: 5px;
        cursor: pointer;
    }
    .btn-show-hide{
        font-size: 14px;
        margin-left: 59%;
        // margin-top: 100px;
    }
    // .showExpand,.hideExpand{
    //     display: flex;
    //     align-items: center;
    //     /* z-index: 10000; */
    //     /* position: absolute;
    //     right: 24%;
    //     bottom: 26.5%;  */
    //     margin-left: 59%;
    //     /* margin-top:20% */
    // }
}
@media only screen and (max-width: 1550px) {
    .downtime-tab .div-pie-chart {
        display: grid;
        grid-template-columns: repeat(2, 1fr)!important;
        grid-gap: 20px;
    }
  }
