/*
 * Dashboard sidebar css
 *
*/



// Navigation
.navigation {
    .fontRaleway(400);
    // background-color: @l_dark;
    background-color: #25262D;
    height: 100%;
    width: 64px;
    max-width: 280px;
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
    z-index: 650;
    .transition(all 0.3s ease);

    a {
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    ul {
        padding: 0;

        li {
            list-style: none;
        }
    }
}

.show-menu {
    width: 280px;
    z-index: 700;

    .nav-header {
        border-bottom: 1px solid rgba(197, 208, 222, 0.5);
        a {
            .pc-logo {
                // .opacity(1);
                // visibility: visible;
                // width: 235px;
                display: block;
            }

            .colapse-logo {
                // .opacity(0);
                // visibility: hidden;
                width: 0;
                display: none;
            }
        }
    }

    .nav-middle {
        flex: 1;
        overflow-y: auto;
    }

    .menu-lv1 {
        .menu-btn {
            .menu-ttl {
                display: block;
                margin-left: 50px;
                font-size: 12px;
                position: absolute;
                top: 17px;
            }

            i {
                margin-left: 0 !important;
                visibility: visible;
                .transition(all 0.4s ease 0.2s);
            }
        }
    }

    .nav-menu {
        position: relative;

        ul {
            >li {
                .menu-btn {
                    &:hover {
                        background-color: #44454E;
                        border-radius: 4px;
                    }
                    span,
                    i {
                        visibility: visible;
                        .transition(all 0.4s ease 0.2s);
                    }

                    .ic-font {
                        margin-left: 0;
                    }

                    .menu-img {
                        margin-left: 0px;
                    }

                    .p-ic {
                        margin-left: 0;
                    }
                }
            }
        }

        h3 {
            display: block;

            span {
                visibility: visible;
                opacity: 1;
            }

            &:before {
                content: '';
                width: 25px;
                border: 1px solid #ddd;
                display: none;
                position: absolute;
                top: 35%;
                left: 20px;
            }
        }

        .help-button {
            padding: 10px 0 5px;
        }
    }
}

.nav-header {
    height: 64px;
    vertical-align: middle;
    text-align: center;
    // background-color: @dark;
    background-color: #25262D;

    a {
        display: block;
        height: 64px;
        text-align: center;

        .colapse-logo {
            display: block;
            margin: 0 auto;
            width: 30px;
            background-image: url(~assets/images/s_logo2.png);
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            background-position: center;
        }

        .pc-logo {
            margin: 0 auto;
            background-image: url(~assets/images/full-logo-2.png);
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            background-position: center;

            // .opacity(0);
            // visibility: hidden;
            // width: 0;
            // .transition(all 0.4s linear 0.3s);
            width: 80%;
            display: none;
        }
    }
}

.nav-menu {
    // margin-bottom: 60px;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .menu-lv1 {
        .menu-ttl {
            display: none;
        }

        .menu-btn {
            height: 50px;
            display: block;
            position: relative;

            i {
                position: absolute;
                display: block;
                width: 50px;
                height: 50px;
                text-align: center;
                line-height: 48px;
                left: 0;
                top: 0;
                font-size: 20px;
                margin-left: 5px;
            }
        }
    }
}

.navigation {
    h3 {
        position: relative;

        span {
            visibility: hidden;
            // display: none;
            opacity: 0;
            color: #6D737C;
            font-size: 13px;
            line-height: 15px;
            padding-left: 22px;
            font-weight: 600;
            height: 15px;
            white-space: nowrap;
        }

        &:before {
            content: '';
            width: 25px;
            border: 1px solid #525765;
            display: block;
            position: absolute;
            top: 35%;
            left: 20px;
        }
    }
}

.navigation {
    ul {
        >li {
            // &:hover {
            //     background-color: @dark;
            // }

            .menu-btn {
                // padding: 12px 15px;
                height: 50px;
                display: block;
                position: relative;

                .menu-img {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    margin-left: 5px;
                    background-repeat: no-repeat;
                    background-size: 18px;
                    background-position: center;
                }

                span {
                    font-size: 15px;
                    visibility: hidden;
                    position: absolute;
                    top: 17px;
                    color: #DCDCDC;
                    .fontRaleway(500);
                    // .transition(all 0.3s ease);
                }

                .menu-ttl {
                    margin-left: 50px;
                }

                .p-ic {
                    position: absolute;
                    display: block;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 48px;
                    left: 0;
                    top: 0;
                    font-size: 20px;
                }

                .ic-font {
                    position: absolute;
                    display: block;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 48px;
                    left: 0;
                    top: 0;
                    font-size: 20px;
                }

                i {
                    position: absolute;
                    right: 5px;
                    top: 20px;
                    visibility: hidden;

                    // .transition(all 0.3s ease);
                    &.ic-font {
                        visibility: visible;
                        margin-left: 5px;
                    }

                    &.p-ic {
                        visibility: visible;
                        margin-left: 0;
                    }
                }

                // &.active {
                //     background-color: @dark;
                // }
            }

            .sub-menu {
                display: none;
                // background-color: @dark;

                li {

                    // display: none;
                    a {
                        display: none;
                        padding: 15px 0;
                        padding-left: 52px;
                        font-size: 13px;
                        // margin: 0 10px;
                        .fontRaleway(500);
                        color: #DCDCDC;
                        &.active, &:hover {
                            // background-color: @blue;
                            background-color: #44454E;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}

.navigation {
    &.show-menu {
        flex: 1;
        display: flex;
        flex-direction: column;

        .sub-menu {
            &.active {
                display: block;
            }

            li {
                a {
                    display: block;
                }
            }
        }
    }
}


// Responsive menu
.mn-open {
    overflow: hidden;
}

.res-bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    position: fixed;
    display: none;
    // visibility: hidden;
    opacity: 0;

    // .transition(all 0.4s ease 0.4s);
    &.active {
        opacity: 0.8;
        display: block;
        // visibility: visible;
        z-index: 500;
    }
}

.nav-bottom {
    margin-top: auto;
    border-top: 1px solid #C5D0DE;
}

.copyright {
    width: 280px;
    padding: 10px 0;
    text-align: center;
    // background: #181c26;
    color: #757B86;

    P {
        margin-bottom: 0;
    }

    visibility: hidden;
    opacity: 0;
    display: none;
}

.help-button {
    cursor: pointer;
    text-align: center;
    font-size: 40px;
    color: #dcdcdc;
    padding: 15px 0;
    // border-top: 1px solid #c4c4c4;
}

.navigation {
    &.show-menu {
        .nav-menu {
            overflow-y: auto;
            overflow-x: hidden;
            .custom-scroll;
        }

        .copyright {
            display: block;
            visibility: visible;
            opacity: 1;
            .transition(all 0.4s ease 0.3s);
        }
    }
}

i {

    &.UserAdmin:before,
    &.ClientAdmin:before {
        content: "\F0C0";
    }

    &.DataManagement:before {
        content: "\F1C0";
    }

    &.AdvancedAnalytics:before {
        content: "\F201";
    }

    &.EventInput {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }

    &.EventInput:before {
        content: "\E907";
    }

    &.NotificationSubscription:before,
    &.NotificationManagement:before {
        content: "\F0F3";
    }

    &.ThingManagement {
        background-image: url(~assets/images/icon/machine_icon_white/machine.png);
        display: inline-block;
        width: 50px;
        height: 50px;
        text-align: center;
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
    }

    &.HierarchyAdmin:before {
        content: "\f0e8"
    }
    &.ThingPerformanceDetails,
    &.EventInput,
    &.AdvancedAnalytics,
    &.ClientAdmin,
    &.ThemeManagement,
    &.ProductionOrderAdmin,
    &.UserAdmin,
    &.HierarchyAdmin,
    &.DataManagement  {
        display: inline-block;
        width: 50px;
        height: 50px;
        text-align: center;
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
        &:before {
            display: none
        }
    }

    &.ThingPerformanceDetails {
        background-image: url(~assets/images/window.png); 
    }
    &.EventInput {
        background-image: url(~assets/images/sidebar/ico-manual-input.svg);
    }
    &.AdvancedAnalytics {
        background-image: url(~assets/images/sidebar/ico-analytics.svg);
        background-size: 22px;
    }
    &.ClientAdmin {
        background-image: url(~assets/images/sidebar/ico-clients.svg);
        background-size: 22px;
    }
    &.ThemeManagement {
        background-image: url(~assets/images/sidebar/ico-theme.svg);
        background-size: 22px;
    }
    &.ProductionOrderAdmin {
        background-image: url(~assets/images/sidebar/ico-product-order.svg);
        background-size: 24px;
    }
    &.UserAdmin {
        background-image: url(~assets/images/sidebar/ico-user.svg);
        background-size: 24px;
    }
    &.HierarchyAdmin {
        background-image: url(~assets/images/sidebar/ico-hierarchy.svg);
        background-size: 24px;
    }
    &.DataManagement {
        background-image: url(~assets/images/sidebar/ico-data.svg);
        background-size: 22px;
    }
}
.navigation {
    h3 {
        margin-bottom: 0;
    }
    &.show-menu {
        .nav-menu h3 {
            // padding-top: 15px;
            margin-bottom: 10px;
        }
        .menu-btn {
            &.active {
                background-color: #44454E;
                .p-ic {
                    background-color: transparent;
                }
            }
        }
    }
    .menu-btn {
        &.active {
            .p-ic {
                background-color: #44454E;
            }
        }
    }
    .nav-header {
        position: relative;
    }
    .sidebar-arr {
        width: 24px;
        height: 24px;
        background-color: #DCDCDC;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: 20px;
        z-index: 10;
        cursor: pointer;
        .arr {
            width: 12px;
            height: 10px;
            background-image: url('../../../images/sidebar/ico-arrow-right.svg');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &.show-menu {
        .sidebar-arr {
            .arr {
                transform: rotate(-180deg)
            }
        }
    }
}
.navigation {
    .menu-lst {
        margin: 0 7px;
        .help-button {
            text-align: left;
            padding: 15px 0;
            position: relative;
            .fa-question-circle {
                font-size: 24px;
            }
            .menu-ttl {
                &.fa-question-circle {
                    font-size: 12px;
                }
                &::before {
                    display: none;
                }
            }
        }
        .menu-help-center {
            &.active {
                .p-ic {
                    background-color: transparent;
                }
            }
        }
    }
}
.nav-middle {
    div {
        &:nth-of-type(1) {
            h3 {
                &::before {
                    top: 0;
                }
            }
        }
    }
}