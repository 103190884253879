/*
    Admin: manage users css
*/


// 1. User table css
// 1.1. User info modal
.groups-slt{
    // padding-top: 15px;
    position: relative;
    &.add-client-rl{
        padding-top: 0;
    }
    .scroll-wrapper{
        padding: 10px 20px !important;
    }
    .g-search-rs, .assigned-list{
        position: relative;
        height: 150px;
        overflow: auto;
        border: 1px solid #ddd;
        // padding-left: 10px;
        border-radius: 2px;
        margin-bottom: 15px;
        &:before{
            display: none;
            content: '';
            position: absolute;
            z-index: 100;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0.7;
        }
        &.shw{
            &:before{
                display: block;
            }
        }
        &.err-msg{
            border-color: @red;
        }
    }
}
.client-lst{
    li{
        display: none;
        &.shw{
            display: block;
        }
        a{
            display: block;
            color: #333;
            text-decoration: none;
            padding: 5px 10px;
            &:hover{
                background-color: #eee;
            }
        }
    }
}
.assigned-ul{
    li{
        display: none;
        &.shw{
            display: block;
        }
        a{
            display: block;
            color: @green;
            padding: 5px 10px;
            text-decoration: none;
            i{
                margin-right: 5px;
            }
            &:hover{
                background-color: #eee;
            }
        }
    }
}
.user-info{
    .lbl{
        margin-top: 8px;
    }
    .ipt{
        margin-bottom: 10px;
    }
    .c-r{
        .col-md-2, .col-sm-2{
            width: 12%;
        }
        .col-md-10, .col-sm-10{
            width: 88%;
        }
    }
}
// 1.2. User table
.actions-lst{
    // position: relative;
    a{
        .transform(translate(-50%, -50%));
        top: 50%;
        left: 50%;
        position: absolute;
        color: #333333;
        font-size: 20px;
        &:hover{
            color: #000;
        }
    }
    .status-gd{
        position: absolute;
        .transform(translateY(-50%));
        left: 40%;
        top: 50%;
    }
}

// Manage header
.manage-header{
    padding: 30px 15px;
    position: relative;

}
.m-ttl{
    h1{
        font-weight: bold;
        margin-top: 0;
        margin-right: 50px;
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 32px;
        span{
            margin-left: 10px;
            font-weight: normal;
            font-size: 14px;
            text-transform: none;
        }
    }
}
.general-act{
    // margin-top: 7px;
    a{
        margin-right: 10px;
    }
}
.m-search-frm{
    width: 410px;
    position: relative;
    margin-top: 7px;
    button{
        position: absolute;
        top: 4px;
        border: none;
        background-color: transparent;
        left: 5px;
        font-size: 16px;
        color: #757575;
    }
    .ipt{
        padding-left: 35px;
    }
}

.chk-td{
    input[type="checkbox"]{
        display: none;
    }
    label{
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 20px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        &:before{
            width: 20px;
            content: "\F096";
            display: inline-block;
            text-align: left;
        }
        &.invalid {
            color: #db0000;
        }
    }

    input[type=checkbox]:checked + label:before{
        content: '\f046';
    }
}

// 2. Group table

// 2.1. Group modal
.user-info{
    .c-area{
        height: auto;
        line-height: 1.42857143;
        padding: 5px 20px;
    }
}
.groups-filter{
    padding-top: 15px;
}
.n-gr-lst, .in-gr-lst{
    &.scroll-wrapper{
        padding: 10px 20px !important;
    }
    margin-top: 15px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-bottom: 15px;
    li{
        a{
            position: relative;
            display: block;
            color: #333;
            padding: 5px 0;
            i{
                position: absolute;
                right: 0;
                top: 7px;
            }
            &:hover{
                text-decoration: underline;
            }
            &.active{
                color: @blue;
                font-weight: 700;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
.not-in-gr{
    .btn{
        i{
            margin-left: 5px;
        }
    }
}
.in-gr{
    .btn{
        i{
            margin-right: 5px;
        }
    }
}
.td-desc{
    // height: 30px;
    text-align: left;
    p{
        margin-bottom: 0;
    }
}

.pass-blk{
    position: relative;
    .view-pass{
        color: #333;
        position: absolute;
        top: 8px;
        left: 290px;
        i{
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            &:before{
                content: "\f06e";
            }
        }
        &.view{
            i{
                &:before{
                    content: '\f00d';
                }
            }
        }
    }
}

// Client table
.client-tb{
    .actions-lst{
        position: static;
        a{
            top: 50%;
            .transform(translateY(-50%));
            right: 40%;
        }
        .status-gd{
            top: 50%;
            left: 40%;
            .transform(translateY(-50%));
        }
    }
}

.rs-pass-blk{
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    .load-pass{
        border: 2px solid #ddd; /* Light grey */
        border-top: 2px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
        position: absolute;
        top: 6px;
        left: 120px;
    }
    a{
        margin-right: 10px;
        color: #fff;
        background-color: @green;
        border-color: @green;
        &:hover, &:active, &:focus, &:visited{
            background-color: #19b257;
            border-color: #19b257;
            color: #fff;
        }
        &.disabled {
            background-color: #ddd;
            border-color: #ddd;
            color: #333;
        }
    }
    i{
        color: @green;
        display: none;
        &.shw{
            display: inline-block;
        }
    }
}
.edt-pass{
    margin-bottom: 15px;
    width: 295px !important;
    margin-right: 20px;
    &:disabled{
        border: 1px solid #ddd !important;
        cursor: not-allowed;
    }
}

.lock-user{
    text-align: right;
    padding-right: 30px;
    .lock-user-btn{
        padding: 5px 10px;
        display: inline-block;
        background-color: #DEDCDE;
        color: #999;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        &:first-child{
            margin-right: -3px;
        }
        &.active{
            background-color: @green;
            color: #fff;
        }
    }
    // &.lock-client{
    //     padding-right: 30px;
    // }
}

.ad-machine-tb{
    .actions-lst{
        // .copy-btn{
        //     left: 40%;
        // }
        .del-btn{
            left: 80%;
        }
    }
}
.kf-tb{
    .actions-lst{
        .del-btn{
            left: 70%;
        }
        .edit-btn{
            left: 30%;
        }
    }
}

.open-modal{
    cursor: pointer;
}

#user-info{
    .modal-content{
        min-height: 540px;
        &:before{
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0.6;
            z-index: 100;
        }
        &.shw{
            &:before{
                display: block;
            }
        }
    }
}

// Custom multi select role 
.slt-role-blk{
    position: relative;
    overflow-y: auto;
    max-height: 130px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #fff;
    margin-bottom: 15px;
    &.err-msg{
        border-color: @red;
    }
    &:before{
        content: '';
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: #fff;
        opacity: 0.7;
    }
    &.shw{
        &:before{
            display: block;
        }
    }
}
.ul-role{
    li{
        position: relative;
        a{
            display: block;
            color: #333;
            padding: 8px 12px 8px 25px;
            text-decoration: none;
            span{
                position: absolute;
                left: 8px;
                top: 10px;
                display: none;
            }
            &:hover, &.active{
                background-color: #eee;
            }
            &.active{
                span{
                    display: inline-block;
                }
            }
        }
    }
}

// Validate new user form
.new-usr-valid{
    display: none;
    color: @red;
    &.shw{
        display: block;
    }
    &.hiden {
        display: none;
    }
}
#new-user,
#new-client,
#upt-client,
#user-info,
#hie-info,
#define,
#new-hie,
#duplicate-machine,
#new-station,
#station-info,
#new-cds,
#datasource-info,
#new-client-dts,
#client-dts-info,
.new-mc-info,
#new-alert,
#edit-alert,
#edit-alert-tpl,
#new-alert-tpl,
#new-contract,
#contract-info{
    .err{
        border-color: @red;
    }
}

.u-search-frm{
    position: relative;
    margin-top: 7px;
    button{
        position: absolute;
        top: 4px;
        border: none;
        background-color: transparent;
        left: 20px;
        font-size: 16px;
        color: #757575;
    }
    .ipt{
        padding-left: 35px;
    }
}

.filter-user{
    select{
        display: inline-block;
        margin-left: 10px;
        height: 32px;
        outline: none;
        border: 1px solid #ddd;
        line-height: 32px;
        border-radius: 2px;
        min-width: 150px;
    }
}
.c-r{
    .a-n-role {
        // margin-left: 15px;
        display: inline-block;
        padding-bottom: 15px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// Clock user 
.table-cus{
    tr{
        td{
            .clock-user{
                color: #333;
                background-color: #eee;
                font-size: 14px;
                padding-top: 0;
            }
        }
    }
}
.is-lock{
    padding-right: 0;
    text-align: left;
}
.lock-date{
    border: 1px solid #ddd;
    border-radius: 2px;
    height: 27px;
    padding: 0 10px;
    outline: none;
    &:focus{
        border-color: #bbb;
    }
}

.is-lock-btn{
    &:last-child{
        margin-left: -3px;
    }
}

.multi-role{
    label{
        span{
            font-family: 'Raleway', sans-serif;
            font-size: 12px;
            vertical-align: 4px;
        }
    }
}

.ad-machine-tb{
    .actions-lst{
        a{
            &.copy-btn{
                left: 50%;
            }
            &.del-btn{
                left: 80%;
            }
        }
    }
}

.hover-user-page {
    position: relative;
    i{
        font-size: 16px;
        position: absolute;
        right: 10px;
        top: 8px;
        &.confirmed{
            color: @green;
        }
        &.not-confirmed{
            color: @red;
        }
    }
    .cfrm-tooltip{
        position: absolute;
        background-color: #333;
        color: #fff;
        padding: 5px;
        border-radius: 2px;
        // z-index: 300;
        // top: 28px;
        right: 0;
        visibility: hidden;
        opacity: 0;
        z-index: 0;
        top: 32px;
        .transition(all 0.4s ease);
        &.shw{
            opacity: 1;
            z-index: 300;
            visibility: visible;
            top: 28px;
        }
        p{
            margin-bottom: 0;
            position: relative;
            &:before{
                content: '';
                display: block;
                z-index: 300;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #333;
                position: absolute;
                top: -10px;
                right: 10px;
            }
        }
    }
}

ul.tree li {
    .menu-lv1 {
        position: relative;
    }

    .gr-has-child {
        padding-left: 22px;

        li {
            position: relative;
        }
    }

    a {
        padding: 5px;
        display: block;
    }

    input {
        position: absolute;
        display: none;
    }

    label {
        margin-top: 3px;
        margin-bottom: 0px;

        span {
            background: #fff;
            display: inline-block;
            left: 19px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 6px;
            padding-right: 5px;
            z-index: 2;
        }
    }

    &.active {
        .gr-has-child {
            display: none;
        }

        .i-arrow::before {
            content: "\F0D7";
            top: -2px;
        }
    }

    .cfrm-tooltip {
        right: auto;
        left: 0;
        min-width: 100px;

        p::before{
            left: 20px;
            right: auto;
        }
    }

    .i-arrow {
        cursor: pointer;
        display: block;
        height: 1px;
        left: 25px;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        position: absolute;

        &::before {
            content: "\F0D8";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 22px;
            right: -15px;
            text-rendering: auto;
            top: -2px;
            position: absolute;
            -webkit-font-smoothing: antialiased;
        }

        &::after {
            background: #ddd;
            content: '';
            display: block;
            height: 1px;
            width: 100%;
        }
    }

    input[type=checkbox]:checked+label:before {
        content: "\F046";
    }

    .lbl-theme {
        font-weight: 400;
        cursor: pointer;

        &:before{
            content: "\F096";
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 20px;
        }
    }
}

.margin-btm {
    margin-bottom: 15px;
}