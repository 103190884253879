header {
    &.not-extended {
        .dashboard-header {
            position: fixed;
            top: 0px;
            // left: 130px;
            // right: 300px;
            height: 65px;
            left: 64px;
            right: 0;
            width: 100%;
            z-index: 501;
            padding: 12px;
            background-color: #ffffff;
        }
    }
}

header {
    &.extended {
        .dashboard-header {
            position: fixed;
            top: 63px;
            left: 65px;
            right: 0px;
            border-top: 1px solid #eeeeee;
            background: #ffffff;
            padding: 5px 20px;
            z-index: 1;
        }
    }
}

header {
    &.static {

    }
}

@media (max-width: 1280px) {
    .usr-account{
        .usr-name {
            .user-name {
                display: none;
            }
            i {
                right: 10px;
            }
        }
        .usr-menu {
            margin-left: -69px;
        }
    } 
    
    header {
        &.not-extended {
            .dashboard-header {
                right: 150px;
            }
        }
    }
    header {
        &.extended {
            .dashboard-header {
                left: 0px;
            }
        }
    }
    header {
        &.static {
            .dashboard-header {
                margin-left: -64px;
            }
        }
    }
}
@media (max-width: 991px) {
    header {
        &.not-extended {
            .extend-scroll-header{
                .viewmode-boxes > li {
                    display: block;
                    width: 35px;
                    height: 35px;
                }
            }
        
            .extend-scroll-header{
                .default-widget-wrapper {
                    width: 150px;
                    margin-right: 10px;
                }
                .dashboard-header .machine-header .mc-btn {
                    width: 150px;
                }
            }
        }
    }
}
