.mc-scroll {
  //display: flex;
  //flex-wrap: nowrap;
  //overflow-x: auto;
  //overflow-y: hidden;
  //
  //li {
  //    flex: 0 0 auto;
  //}
}

.bg-image {
  height: 180px;
  width: 180px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.theme {
  .color-system-wrap {
      position: initial;
      opacity: 1;
      display: block;
      visibility: visible;
      width: 100%;
      .transition(all .3s ease);
      .transform(unset);
      min-height: auto;
  }

  .p-description {
    font-size: 12px;
    font-weight: 700;
    margin: 10px;
  }

  .enable-toggle {
    padding-left: 0;
  }
}

.large-btn a {
  font-size: 16px;
  height: auto;
  margin-top: 10px;
}

.theme-info .more {
  display:none;

  > span {
    position: relative;
    display: block;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
  }
}

.overflow{
  position:absolute;
  right: 15px;
  display:none;
  min-width: 160px;
  font-size: 14px;
  z-index: 1;
  list-style: none;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;

  li > a {
      position: relative;
      display: block;
      padding: 10px 15px;
  }
}

.three-action {
  text-align: center;

  a {
    position: unset;
    margin: 10px;
    text-decoration: unset;

    &:hover {
      text-decoration: none;
    }
  }
}


#duplicate-theme {
  .btn-default.disabled {
    .box-shadow(none);
    .opacity(0.65);
    cursor: not-allowed;
    pointer-events: none;
    filter: alpha(opacity=65);
    border: #999 1px solid;
    color: #333;
  }
}


.dropdownmutliSelect {
  width: 100%;
}

.dropdownmutliSelect dd,
.dropdownmutliSelect dt {
  margin: 0px;
  padding: 0px;
}

.dropdownmutliSelect ul {
  margin: -1px 0 0 0;
}

.dropdownmutliSelect dd {
  position: relative;
}

.dropdownmutliSelect a,
.dropdownmutliSelect a:visited {
  color: #fff;
  text-decoration: none;
  outline: none;
  font-size: 12px;
}

.dropdownmutliSelect dt .btnSelect {
  display: block;
  padding: 8px 20px;
  height: 32px;
  overflow: hidden;
  border: 0;
  width: 100%;
  border: 1px solid #ddd;
  background-color: transparent;
  color: #333;
  font-weight: normal;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    border: solid #333;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    .transform(rotate(45deg));
    position: absolute;
    right: 12px;
  }

  .multiSel {
      height: 32px;
      overflow: hidden;
      border: 0;
      width: 100%;
      line-height: 32px;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 20px;
      padding-right: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        padding-right: 5px;

        &:not(:last-child):after {
          content: ',';
        }
      }
  }
}

.dropdownmutliSelect dt .btnSelect span,
.multiSel span {
  cursor: pointer;
}

.dropdownmutliSelect dd ul {
  background-color: transparent;
  border: 1px solid #ddd;
  color: #333;
  display: none;
  left: 0px;
  padding: 0 20px;
  position: absolute;
  top: 2px;
  width: 100%;
  list-style: none;
  height: 101px;
  overflow-y: auto;

  li {
    padding: 5px 0px;
  }
}

.dropdownmutliSelect span.value {
  display: none;
}

.dropdownmutliSelect dd ul li {
  position: relative;

  a {
    padding: 5px;
    display: block;
  }

  input {
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  label {
    margin-top: 3px;
    margin-bottom: 0px;

    span {
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
    }
  }

  input[type=checkbox]:checked+label:before {
    content: "\F046";
  }

  .lbl-theme {
    font-weight: 400;
    cursor: pointer;

    &:before{
      content: "\F096";
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 18px;
    }
  }
}

.dropdownmutliSelect dd ul li a:hover {
  background-color: #fff;
}


.ad-theme-tb {
  .kf-wrap {
    &.wrapped{
      height: 65px;
      overflow: hidden;
    }

    &.unwrap {
      height: auto;
    }
  }

  .expand-p {
    display: none;
    &.shw {
      display: inline-block;
    }
  }
}

.expand-all-theme {
  padding: 0 15px;
}

.table-cus td .expand-tr:before {
  font: normal normal normal 14px/1 FontAwesome !important;
}
