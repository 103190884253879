/**
 * Base settings
 *
 * Description: fast setting for bootstrap and rules. Use to store variable that use in <selectors> and <mixins>
 */


//** ------ {{ color and cusotmiaztion variables }} ------------------------------------ 
//** application color table
@blue : #005aff;
@yellow : #ffe44d;
@green : #1cc861;
@red: #e81a3c;
@l_dark : #181c26;
@dark : #11141c;

//** ------ {{ variable settings }} ------------------------------------ 
// 
// note: uncomment to apply/edit new rules
// 

//** Grid system
// @grid-gutter-width :         30px;
// @grid-columns      :         12;

//** Extra small screen / phone
// @screen-xs         :         480px;

//** Small screen / tablet
// @screen-sm         :         768px;

//** Medium screen / desktop
// @screen-md         :         992px;

//** Large screen / wide desktop
// @screen-lg         :         1200px;



//** ------ {{ font setting }} ------------------------------------ 

.fontAwesome {
    font-family: 'Fontawesome';
}
.fGogamBold {
  font-family: 'gotham_nvvbold', arial, sans-serif;
}
.fontRaleway(@fontWeight: 400) {
    font-family: 'Raleway', sans-serif;
    font-weight: @fontWeight;
}
.fontCafe {
    font-family: 'UTMCafeta', Arial, sans-serif;
}
.fGogamBook{
    font-family: 'gotham_nvvbold_bbok';
}
// @font-face {
//    font-family: 'Roboto-Regular';
//    src: url("../fonts/Roboto-Regular.eot");
//    src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
//    font-weight: normal;
//    font-style: normal;
// }

// @font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
// @font-family-serif:       Georgia, "Times New Roman", Times, serif;
// @primary-font: 'Roboto-Regular';
// @font-family-base:  @primary-font, Arial;

// @font-size-base:          14px;
// @line-height-base:        1.428571429; // 20/14

//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// @line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
// @font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
// @font-family-base:        @font-family-sans-serif;

// @font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
// @font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

// @font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
// @font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
// @font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
// @font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
// @font-size-h5:            @font-size-base;
// @font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

//** Heading tags will inherit fromt he <body> style by default
// @headings-font-family:    inherit;
// @headings-font-weight:    500;
// @headings-line-height:    1.1;
// @headings-color:          inherit;


//** ------ {{ meida breakpoints }} ------------------------------------ 
// Small screen / tablet
// @container-tablet:             (720px + @grid-gutter-width);

// Medium screen / desktop
// @container-desktop:            (940px + @grid-gutter-width);

// Large screen / wide desktop
// @container-large-desktop:      (1140px + @grid-gutter-width);




//** ------ {{ bootstrap components }} ------------------------------------ */
// 
// note: uncomment to include new components
// 

// @import "bootstrap/component-animations.less";
// @import "bootstrap/dropdowns.less";
// @import "bootstrap/button-groups.less";
// @import "bootstrap/input-groups.less";
// @import "bootstrap/navs.less";
// @import "bootstrap/navbar.less";
// @import "bootstrap/breadcrumbs.less";
// @import "bootstrap/pagination.less";
// @import "bootstrap/pager.less";
// @import "bootstrap/labels.less";
// @import "bootstrap/badges.less";
// @import "bootstrap/jumbotron.less";
// @import "bootstrap/thumbnails.less";
// @import "bootstrap/alerts.less";
// @import "bootstrap/progress-bars.less";
// @import "bootstrap/media.less";
// @import "bootstrap/list-group.less";
// @import "bootstrap/panels.less";
// @import "bootstrap/responsive-embed.less";
// @import "bootstrap/wells.less";
// @import "bootstrap/close.less";
// End bootstrap

