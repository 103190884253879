.image-mapper {
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.image-mapper-img {
  max-width: 100%;
  max-height: 100%
}

.image-mapper-svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%
}

footer {
  margin-top: 30px
}

footer .container {
  border-top: 2px solid #f4f4f4;
  padding-top: 10px
}

.navbar {
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: #469408;
  border-color: #2F6405
}

.navbar-header h1 {
  margin: 0
}

.navbar-brand {
  font-family: Quantico, sans-serif
}

@media (max-width: 767px) {
  .step {
    text-align: center
  }

  .step span {
    display: block;
    margin: 10px 0
  }

  .navbar-text.navbar-right {
    display: none
  }
}

@media (min-width: 768px) {
  .navbar-right {
    margin-right: 0 !important
  }
}

#image-map-wrapper {
  text-align: center
}

#image-map-container {
  display: inline-block;
  border: 2px solid #DDD;
  padding: 2px;
  border-radius: 3px;
  max-width: 100%
}

#image-map {
  display: inline-block;
  max-width: 100%
}

.segment {
  margin-bottom: 10px;
  padding-bottom: 30px;
  border-bottom: 2px solid #f4f4f4
}

.step {
  border-radius: 6px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 48px;
  padding-top: 48px;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 30px
}

.heading, .step h1 {
  text-align: left;
  margin-top: 0;
  font-size: 20px;
  font-family: Quantico, sans-serif;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 48px
}

.step .container .row > * {
  padding-left: 0;
  padding-right: 0
}

#image-mapper-file {
  display: none
}

#image-mapper-dialog .glyphicon-refresh {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg)
  }
  to {
    -ms-transform: rotate(360deg)
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg)
  }
  to {
    -moz-transform: rotate(360deg)
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

#social {
  text-align: center
}


// Build image map pro

.hotspot-content {
  position: absolute;
  left: -15px;
  right: -15px;

  #wcp-editor {
    min-height: 800px;
    max-height: 1500px;
    border-top: 1px solid #ddd;
  }
}
