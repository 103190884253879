/**
 * Global styles
 *
 * Description: This is the most accessible LESS file. For creating stucture of a LESS project
 * There're some simple rules: 
 *  - use base rule on this file. E.g. every single rule/indent or each component. To generate the fast view for the parent components
 *  - for children rules put it in matched folders and import it in parent's section
 *  
 */
body {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
.t-l {
  text-align: left;
}
.t-r {
  text-align: right;
}
.t-c {
  text-align: center;
}
// CSS LOADING
#preview-area {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 200;
}
.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  .transform(translateX(-50%) translateY(-50%));
  z-index: 120;
  &.loading-tile {
    top: 50%;
    left: 50%;
    // .transform(translateX(-50%) translateY(-50%));
    // margin-top: 20px;
    // margin-bottom: 30px;
  }
  &.loading-gr {
    top: 40%;
    z-index: 220;
  }
  &.loading-chrt {
    top: 50%;
  }
}

.spinner > div {
  background-color: @blue;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.aiq-version {
  color: #7f8181;
  position: absolute;
  z-index: 200;
  right: 10px;
  bottom: 0;
  display: none;
}
.login-page {
  .aiq-version {
    display: block;
  }
}

.ico-trash,
.ico-duplicate,
.ico-edits,
.ioc-custom {
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.ioc-custom {
  &.ioc-m-custom {
    width: 15px;
    vertical-align: sub;
    height: 15px;
    margin-right: 7px;
  }
}

.ico-trash {
  background: url("../images/icon/delete.svg") no-repeat;

  &.ico-red {
    background: url("../images/icon/red-delete.svg") no-repeat;
  }
}

.ico-duplicate {
  background: url("../images/icon/copy.svg") no-repeat;
}

.ico-edits {
  background: url("../images/icon/edit.svg") no-repeat;
}

.ico-file-upload {
  background: url("../images/icon/file-upload.svg") no-repeat;
}

.ico-file-download {
  background: url("../images/icon/file-download.svg") no-repeat;
}

.ico-plus-circle {
  background: url("../images/icon/plus-circle.svg") no-repeat;
}

.mt-17 {
  margin-top: 17px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mh-0 {
  min-height: 0!important;
}

.font-20 {
  font-size: 20px !important;
}

// Extra global class
.btn-outline-blue {
  border: 1px solid #005aff;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.16667;
  color: #005aff;
  &:hover {
    color: lighten(#005aff, 10%);
    border-color: lighten(#005aff, 10%);
    text-decoration: none;
  }
  &:active:focus {
    outline: none;
  }
  &:focus {
    color: #005aff;
  }
  i.fa {
    &:focus {
      outline: none;
    }
  }

}

.btn-light {
  border: 1px solid #7f8181;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.16667;
  color: #7f8181;
}

.absolute-middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.hidden {
  display: none;
}

.color-success {
  color: #1cc861;
}

.color-danger {
  color: #e81a3c;
}

.color-main {
  color: #005aff;
}

.bg-white {
  background-color: #fff;
}

.text-bold {
  font-weight: bold;
}

.text-bolder {
  font-weight: 900;
}

.align-center {
  display: flex;
  align-items: center;
}

.mx-8 {
  margin: 0 8px;
}

.hand {
  cursor: pointer;
}

.ellipsis-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}

.ellipsis-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}
// Animation
.blinking-anima {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
      opacity: 0.2;
  }
}