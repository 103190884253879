/*
    Hierachy table css
*/ 
.hierachy-tb{
    .actions-lst{
        .copy-btn{
            left: 40%;
        }
        .del-btn{
            left: 50%;
            top: 50%;
            .transform(translate(-50%, -50%));
        }
    }
}
.add-user-tb{
    tr{
        &:first-child{
            th{
                text-align: left;
            }
        }
        th{
            padding: 10px;
        }
        td{
            text-align: left;
            padding: 10px !important;
            line-height: 25px !important;
        }
    } 
    .actions-lst{
        a{
            left: 90%;
        }
    }
    .usr-add-name{
        text-decoration: none;
        color: @green;
        font-weight: 600;
    }
}
.chart-ttl{
    .total-can-see{
        position: absolute;
        font-size: 11px;
        font-weight: normal;
        left: 160px;
        top: 10px;
    }
}
.search-add{
    .row{
        margin-bottom: 15px;
    }
    .search-add-btn{
        a{
            margin-left: 15px;
        }
    }
}

.general-frm{
    label{
        margin-bottom: 10px;
    }
    .ipt{
        margin-bottom: 15px;
    }
    .enable-toggle{
        padding-left: 0;
    }
    textarea{
        width: 100%;
        border: 1px solid #ddd;
        padding: 5px 20px;
        font-size: 12px;
        outline: none;
        border-radius: 2px;
        color: #333;
        display: inline-block;
        &:focus{
            border-color: #bbb;
        }
    }
}

.hierachy-tb{
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 15%;
    }
}

.hie-info{
    textarea{
        outline: none;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 2px;
        padding: 8px 20px;
        &:focus{
            border-color: #bbb;
        }
    }

}
#hie-info{
    .modal-content{
        position: relative;
        &:before{
            position: absolute;
            content: '';
            display: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            background-color: #fff;
            opacity: 0.7;
        }
        &.shw{
            &:before{
                display: block;
            }
        }
    }
}

#define{
    position: relative;
}
.ic-machine{
    font-size: 18px;
    position: absolute;
    left: 30px;
    top: 15px;
    z-index: 1;
}