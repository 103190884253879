/**
 * CSS MAP
 */
#dashboard-map {
    height: 700px;
    background-color: #eee;
    width: 100%;
}
.map-wrap{
    position: relative;
}
.info-wrap {
    width: 360px;
    .info-img {
        width: 120px;
        height: 120px;
        float: left;
        background-repeat: no-repeat;
        background-size: 120px auto;
    }
    .info-content {
        margin-left: 135px;
        .info-ttl {
            font-size: 14px;
            line-height: 20px;
            color: #333;
            font-weight: 900;
        }
    }
    a {
        color: #333;
        &:hover {
            text-decoration: none;
        }
    }
}
.info-wrap {
    table.tbl-info {
        width: 100%;
        tr {
            height: auto;
            td {
                &:first-child {
                    border-left: none;
                }
                text-align: left;
                height: auto;
                padding: 2px !important;
                &.t-r {
                    text-align: right;
                }
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}
.info-wrap {
    .percent {
        font-weight: 900;
    }
    &.low {
        .percent {
            color: @red;
        }
    }
    &.medium {
        .percent {
            color: @yellow;
        }
    }
    &.high {
        .percent {
            color: @green;
        }
    }
}
.gm-style-iw + div {
    right: auto !important;
    left: 10px;
}
.my-marker {
    width: 50px;
    height: 50px;
    line-height: 50px;
    .border-radius(50%);
    text-align: center;
    .fontCafe();
    color: #fff;
    background-repeat: no-repeat;
    background-size: 50px auto;
    font-size: 18px;
    &.low {
        background-image: url('../../../uploads/mk-low.png');
    }
    &.medium {
        background-image: url('../../../uploads/mk-medium.png');
    }
    &.high {
        background-image: url('../../../uploads/mk-high.png');
    }
    .d-letter {
        span {
            font-size: 11px;
        }
    }
}
.tbl-map-machine {
    tr {
        &.has-show {
            background-color: #eee;
        }
        > th {
            width: 10%;

            &.col-small {
                width: 5%;
            }

            &.col-small {
                width: 30%;
            }
        }
    }
    &.table-cus {
        tr {
            &.has-show {
                td{
                    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        background-color: #eee;
                    }
                }
            }
        }
    }
}

.map-chart{
    margin-top: 30px;
}