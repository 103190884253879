/*
 * Header
 * 
*/ 

header{
    height: 64px;
    position: relative;
    z-index: 300;
    // width: 100%;
    background-color: #fff;
    margin-left: 64px;
    &.has-extended {
        height: unset;
        &.not-extended {
            z-index: 600;
        }
    }
}

.header{
    height: 64px;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    .transition(all 0.7s ease);
    z-index: 500;
    .box-shadow(0px 2px 2px 0px rgba(0,0,0, 0.2));
    a{
        &:hover, &:active, &:focus{
            text-decoration: none;
        }
    }   
}

.togle-nav-menu{
    display: none;
    width: 65px;
    height: 64px;
    text-align: center;
    position: fixed;
    z-index: 600;
    border-right: 1px solid #eeeeee;
    margin-left: 64px;
    background-color: #ffffff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    a{
        display: block;
        line-height: 60px;
        color: @dark;
        position: absolute;
        // padding: 20px 0;
        top: 0;
        left: 0px;
        width: 100%;
        height: 100%;
        &.responsive-btn{
            display: none;
            i{
                font-size: 24px;
            }
        }
        &.toggle-btn{
            // line-height: 28px;
            // .transform(rotate(180deg));
            &.show-menu{
                display: none;
                
            }
        }
        &.close-menu{
            display: none;
            &.active{
                display: block;
                .transform(rotate(180deg));
                left: 216px;
                border-right: 1px solid #eeeeee;
                z-index: 600;
            }
        }
    }
}

.usr-account{
    position: absolute;
    top: 0px; 
    right: 64px;
    &.no-notifi {
        right: 0;
    }
    .usr-name{
        display: inline-block;
        vertical-align: middle;
        // width: 100%;
        // height: 100%;
        padding: 11px 10px 7px 10px;
        position: relative;
        color: #333;
        font-weight: 700;
        z-index: 130;
        .transition(all 0.7s ease);
        
        .user-name{
            // position: absolute;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 30px;
            display: inline-block;
            // margin-top: 13px;
        }
        img{
            margin-right: 15px;
            width: 46px;
            height: 46px;
        }
        i{
            margin-left: 10px;
            font-size: 18px;
            position: absolute;
            top: 25px;
            right: 25px;
        }
        &:hover{
            background-color: #ebecf0;
        }
        &.active{
            background-color: #ebecf0;
        }
    }
    .user-ava{
        display: inline-block;
        position: relative;
        vertical-align: 6px;
        width: 61px;
        height: 46px;
        // top: -2px;
        .usr-status{
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: @green;
            left: 33px;
            bottom: 0px;
            border: 2px solid #fff;
        }
    }
    .live-meeting-available {
        display: inline-block;
        vertical-align: middle;
        margin-top: 5px;
    }
    .live-meeting-btn {
        display: inline-block;
        color: red;
        font-size: 40px;
    }
}



.usr-account{
    .usr-menu{
        position: absolute;
        width: 100%;
        z-index: 502;
        top: 63px;
        background-color: #fff;
        // border-right: 1px solid #ddd;
        border-radius: 0;
        border: none;
        margin: 0;
        .box-shadow(0px 2px 2px 0px rgba(0,0,0,0.5));
        // display: none;
        ul{
            margin-bottom: 0;
        }
        li{
            list-style: none;
            a{
                display: block;
                padding: 7px 20px;
                color: @dark;
                .transition(all 0.4s ease);
                &:hover{
                    background-color: #ddd;
                }
                i{
                    font-size: 18px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    color: #777e86;
                    margin-right: 10px;
                }
                &.active{
                    background-color: #ddd;
                }
            }
        }
        &.active{
            display: block;
        }
    }
}


// .header{
//     .search-frm{
//         position: absolute;
//         height: 100%;
//         width: 100%;
//         top: 0;
//         input[type="text"]{
//             width: 100%;
//             height: 64px;
//             display: none;
//             border: none;
//             padding-left: 64px;
//             padding-right: 15px;
//             z-index: 120;
//             outline: none;
//             &.active{
//                 display: block;
//             }
//         }
//     }
// }
// .header{
//     .search-frm{
//         button{
//             outline: none;
//             width: 64px;
//             height: 64px;
//             display: block;
//             position: absolute;
//             border: none;
//             background-color: transparent;
//             z-index: 100;
//             top: 0;
//             border: none;
//             box-shadow: none;
//             font-size: 18px;
//             color: #bbb;
//             .transition(all 0.7s ease);
//             i{
//                 display: block;
//                 margin-top: -4px;
//             }
//             &:active, &:focus{
//                 outline: none;
//             }
//             &.h-close{
//                 right: 10px;
//                 display: none;
//             }
//             &.h-search-frm{
//                 left: 64px;
//             }
//             &.search-btn{
//                 display: none;
//             }
//             &.active{
//                 display: inline-block;
//             }
//         }
//     }
// }



.header{
    .notify{
        width: 64px;
        height: 100%;
        // position: relative;
        z-index: 130;
        >a{
            // position: relative;
            display: block;
            height: 100%;
            text-align: center;
            // padding: 20px 0;
            line-height: 64px;
            border-left: 1px solid #eeeeee;
            color: #666;
            font-size: 20px;
            span{
                font-size: 10px;
                display: block;
                position: absolute;
                top: 10px;
                right: 15px;
                width: 20px;
                height: 20px;
                background-color: @red;
                line-height: 20px;
                color: #fff;
                border-radius: 100%;
            }
        }
    }
}

.notify-ct{
    width: 50%;
    font-size: 16px;
    border-top: 1px solid #ddd;
    position: absolute;
    right: 0px;
    z-index: 9999;
    background-color: #fff;
    padding: 10px !important;
    .box-shadow(0px 2px 2px 0px rgba(0,0,0,0.5));
    display: none;
    &.shw{
        display: block;
    }
    .noti-err{
        position: absolute;
        width: 100%;
        color: #ccc;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 300;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 22px;
    }
    .progress{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    >a{
        font-size: 16px;
        text-transform: capitalize;
        border-top: 1px solid #ddd;
        display: block;
        height: 100%;
        text-align: center;
        color: #666;
        line-height: 45px;
        .transition(all 0.4s ease);
        &:hover{
            background-color: @blue;
            color: #fff;
        }
    }
    .noti-ic{
        // width: 40px;
        // height: 40px;
        background-repeat: no-repeat;
        // background-size: cover;
        display: table-cell;
        background-size: 35px auto;
        vertical-align: middle;
        background-position: top center;
        width: 40px;
        &.warning{
            background-image: url('../../../images/warn-ico.png');
        }
        &.trend{
            background-image: url('../../../images/trends.png');
        }
        &.alert{
            background-image: url('../../../images/alert-info.png');
        }
        &.information{
            background-image: url('../../../images/information-blue.png');
        }
        &.criticalsystemevent{
            background-image: url('../../../images/critical.png');
        }
    }
    .noti-txt{
        width: 80%;
        padding-left: 10px;
        padding-right: 10px;
        display: table-cell;
        // vertical-align: middle;
        p{
            color: #8B8B8A;
            margin-bottom: 5px;
            // height: 50px;
            overflow: hidden;
            .transition(all 0.4s ease);
            .sp-ttl{
                // width: 125px;
                margin-right: 5px;
                font-weight: 600;
                display: inline-block;
            }
        }
    }
    .noti-time{
        width: 14%;
        display: table-cell;
        // vertical-align: middle;
        p{
            margin-bottom: 0;
            color: #DDDDDE;
            font-size: 14px;
        }
    }
    .noti-itm{
        margin-bottom: 10px;
        // height: 50px;
        cursor: pointer;
        display: table;
        .tb-r{
            display: table-row;
        }
        &:hover{
            .noti-txt{
                p{
                    color: #000;
                }
            }
        }
    }
    .scroll-noti{
        // height: 240px;
        height: 600px;
        overflow-y: auto;
        position: relative;
        
    }
    &.no-height{
        .scroll-noti{
            height: auto !important;
        }
    }
}
.client-name{
    height: 100%;
    padding-top: 22px;
    padding-left: 20px;
    p{
        margin-bottom: 0;
    }
}
.choose-pg-btn{
    >a{
        display: inline-block;
        width: 49%;
        margin-right: 1%;
        margin-bottom: 15px;
        position: relative;
        span{
            position: absolute;
            top: 8px;
            right: 10px;
            display: none;
        }
        &.choose{
            span{
                display: block;
            }
        }
    }
    .slt-usr{
        margin-right: 0;
    }
    .usr-tb{
        .bg-eee{
            background-color: #eee;
        }
        tr{
            th, td{
                border-color: #eee !important;
            }
        }
    }
}
#switch-page{
    .modal-content{
        width: 380px;
    }
}

.machine-dt-head{
    margin-left: 15px;
    height: 100%;
    padding-top: 15px;
    display: none;
    &.shw{
        display: block;
    }
    span{
        display: inline-block;
        margin-right: 25px;
        border: 2px solid #ddd;
        padding: 5px 10px;
        font-weight: 600;
        &.high{
            border-color: #1cc861;
        }
        &.low{
            border-color: #e81a3c;
        }
        &.med{
            border-color: #ffe44d;
        }
    }
}
.machine-detail-prod-info {
    padding-left: 15px;
    margin-left: 15px;
    position: relative;
    p {
        margin-bottom: 0;
        &:first-child {
            height: 13px;
        }
    }
    &::after {
        content: '';
        width: 1px;
        height: 30px;
        background-color: #dcdcdc;
        left: 0;
        bottom: 20px;
        z-index: 1;
        position: absolute;
    }
    .status {
        &.red {
            color: #E81A3C;
        }
        &.green {
            color: #10A81F;
        }
    }
}
.active-text{
    position: absolute;
    left: 50%;
    top: 50%;
    color: @green;
    .transform(translate(-50%, -50%));
    z-index: 500;
    font-size: 16px;
    i{
        margin-right: 10px;
    }
}

.extend-scroll-header {
    padding-top: 64px;
    // height: 167px;
}

.header {
    .machine-detail-head-title{
        display: inline-flex;
        height: 100%;
        padding-left: 20px;
        flex-direction: column;
        .machine-title-bot {
            display: inline-flex;
            align-items: flex-start;
        }
        .machine-header-title {
            color: #7F8181;
            padding-top: 10px;
            i {
                margin-left: 10px;
            }
        }
        group-filter{
            a.mc-btn{
                span {
                    max-width: 300px;
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 24px;
                    color: black;
                }
                i {
                    font-size: 24px;
                    color: black;
                }
            } 
            .search-machine{
                top: 65px;
                left: 0;
            }
        } 
        &:hover {
            group-filter{
                a.mc-btn{
                    span {
                        color: #005aff;
                    }
                    i {
                        color: #005aff;
                    }
                } 
            } 
        }

    }
    .machine-detail-recent-history{
        // display: inline-flex;
        // height: 100%;
        // padding-left: 20px;
        // flex-direction: column;
        // position: relative;
        // top: 15px;
        // left: 45%;

        display: inline-flex;
        height: 100%; 
        padding-left: 20px; 
        flex-direction: column;

        // position: relative;
        // float: right;
        // top: 20px;
        // right: 250px;
    }
}
header {
    &.extended {
        .header {
            .machine-detail-head-title{
                group-filter{
                    a.mc-btn{
                        span {
                            max-width: 135px;
                        }
                    }
                }
            }
        }
    }
}
.img-user-change {
    width: 24px;
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    z-index: 1;
}
@media (max-width:1440px){
    
    header.extended .header .machine-detail-head-title{
        .line-name-icon {
            width: 50px !important;
            height: 20px !important;
        }
        group-filter a.mc-btn{
            span {
                max-width: 100px;
                font-size: 16px;
            }
            i {
                font-size: 16px;
            }
        } 
    } 
}