/*
    Admin machine
*/



// Machine table ----------------------
#duplicate-machine{
    .choose-tpl{
        display: none;
        &.shw{
            display: block;
        }
    }
    .modal-footer{
        display: none;
        &.shw{
            display: block;
        }
    }
    .ask-tpl{
        &.hide{
            display: none;
        }
    }
}

.ad-machine-tb{
    .open-modal{
        cursor: pointer;
    }
}


// Machine detail ----------------------


// Info tab
.define-tpl{
    padding-top: 10px;
    input[type="checkbox"]{
        display: none;
    }
    label{
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 20px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        // font-weight: 100;
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        color: #333;
        &:before{
            content: "\F096";
            width: 20px;
            display: inline-block;
            text-align: left;
        }
        span{
            font-family: 'Raleway', sans-serif;
            font-size: 12px;
            color: #333;
            vertical-align: 4px;
        }
    }

    input[type=checkbox]:checked + label:before{
        content: '\f046';
    }
}

.enable-toggle{
    a{
        display: inline-block;
        color: #999 !important;
        background-color: #DEDCDE;
        text-transform: uppercase;
        padding: 7.5px;
        font-weight: bold;
        font-size: inherit !important;

        &:hover, &:active, &:focus, &:visited{
            text-decoration: none;
        }
        &.active{
            background-color: @green;
            color: #fff !important;
        }
    }
}
.lbl{
    margin-top: 10px;
}

.general-info, .upload-img{
    margin-bottom: 50px;
    position: relative;
}
.upload-area{
    border: 2px dashed #bbb;
    border-radius: 4px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    p{
        position: absolute;
        color: #bbb;
        font-size: 16px;
        top: 50%;
        left: 50%;
        .transform(translate(-50%, -50%));
    }
}

.attr-btn-blk{
    a{
        display: inline-block;
        padding: 6px 15px;
        border: 1px solid #ddd;
        color: #333;
        text-decoration: none;
        border-radius: 2px;
        // &:last-child{
        //     border-left: none;
        // }
        &:hover{
            background-color: #ddd;
        }
    }
}

.upload-doc{
    .upload-area ,.dropzone{
        margin-bottom: 30px;
    }
    .file-name{
        color: @green;
        font-weight: 600;
    }
    .del-upload{
        color: #333;
        font-size: 14px;
        &:hover{
            color: #000;
        }
    }
}
.dropzone{
    min-height: 200px;
    border: 2px dashed #ddd;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    text-align: center;
}


.mc-s-frm{
    margin-right: 0;
    width: 100%;
}

.filter-mc-blk{
    margin-top: 7px;
    select{
        display: inline-block;
        margin-left: 10px;
        height: 32px;
        outline: none;
        border: 1px solid #ddd;
        line-height: 32px;
        border-radius: 2px;
        min-width: 150px;
    }
}


// Key file tab
.k-h{
    margin-bottom: 15px;
    h2{
        display: inline-block;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 50px;
        span{
            margin-left: 30px;
            font-weight: normal;
            font-size: 14px;
            text-transform: none;
        }
    }
    a{
        display: inline-block;
        margin-top: -10px;
        margin-right: 15px;
    }
}
.k-act{
    //margin-bottom: 15px;
    a{
        margin-left: 15px;
    }
}

.kf-tb{
    td{
        p{
            width: 100%;
            margin-bottom: 0;
            // overflow-x: auto;
        }
    }
}

.upload-kf-head{
    h4{
        span{
            font-weight: normal;
            text-transform: none;
            font-size: 12px;
            color: #bbb;
            margin-left: 25px;
        }
    }
}

.kf-frm{
    h5{
        font-weight: bold;
        margin-bottom: 20px;
    }
    .file-choose{
        background-color: #ddd;
        padding: 5px;
        span{
            color: @blue;
            display: block;
            font-weight: bold;
        }
        a{
            display: block;
            color: #333;
            text-decoration: none;
            font-size: 14px;
            &:hover{
                color: #000;
            }
        }
    }
    .dropzone{
        margin-bottom: 20px;
        cursor: pointer;
    }
}

.kf-tb{
    .ipt{
        display: none;
        padding: 0px 5px;
    }
    .kf-text{
        width: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        // margin: 0 auto;
        &.kf-mc-tagname{
            width: 200px;
        }
        &.kf-tag-val{
            width: 50px;
        }
    }
    .actions-lst{
        .close-btn{
            display: none;
            color: @red;
        }
        .save-edit-btn{
            display: none;
            color: @green;
        }
    }
}
.kf-tb {
    .expand-p{
        display: none;
        &.shw{
            display: inline-block;
        }
    }
    .kf-wrap{
        position: relative;
        &.wrapped{
            height: 50px;
            overflow: hidden;
        }
    }
}
.expand-all{
    margin-bottom: 15px;

    .title-device {
        position: relative;
        margin-right: 10px;
    }

    .attr-tooltip {
        text-align: initial;
        z-index: 101;
        min-width: 300px;
        right: 0;
        left: unset;

        &:before {
            left: unset;
            right: 18px;
        }
    }
}
.table-cus tr{
    &.n-record-td{
        &:hover{
            td{
                background-color: #fff !important;
            }
        }
    }
    .n-record-ttl{
        text-align: left;
    }
}
.n-record-td{
    &.hide{
        display: none;
    }
    .enable-toggle{
        padding: 0;
        margin: 0;
        a{
            padding: 0;
            height: 32px;
            width: 45px;
            line-height: 32px;
        }
    }
    textarea{
        &.ipt{
            height: 70px;
            resize: vertical;
        }
    }
    .ipt{
        display: inline-block;
    }
    .n-record-btn{
        position: relative;
        a{
            // position: absolute;
            display: inline-block;
            // margin-bottom: 5px;
            &.btn-blue{
                margin-bottom: 0;
            }
        }
    }
    .n-record-ttl{
        text-align: left;
        span{
            display: block;
            margin-top: 10px;
            font-weight: 600;
        }
    }
}

.table-cus .n-record-td td{
    padding: 10px !important;
    border-top: none;
}

.kf-tb{
    .editting{
        .status-ic{
            display: none;
        }
        .text-center{
            .ipt{
                text-align: left;
            }
        }
        .ipt{
            background-color: #fff;
            display: inline-block;
        }
        p{
            display: none;
        }
        .actions-lst{
            .close-btn{
                display: block;
                color: @red;
                left: 30%;
            }
            .save-edit-btn{
                display: block;
                color: @green;
            }
            .edit-btn{
                display: none;
            }
            .del-btn{
                left: 90%;  
            }
            .lock-btn{
                left: 70%;
            }
        }
        select{
            display: inline-block;
        }
        .kf-slt-tagname{
            display: block;
        }
        .not-edit{
            p{
                display: block;
            }
            select, input{
                display: none;
            }
            .kf-slt-tagname{
                display: none;
            }
        }
    }
}
.kf-act{
    a{
        font-size: 16px;
    }
    &.kf-checkbox {
        a{
            font-size: 20px;
        }   
    }
}
.kf-act, .station-act{
    .del-btn, .edit-btn{
        &.cannot-del{
            color: #bbb;
            cursor: not-allowed;
            pointer-events: none;
            &:hover{
                color: #bbb;
            }
        }
    }
}
.station-info, .station-hotspot{
    margin-bottom: 30px;
}
.warn-itm{
    .ipt{
        margin-bottom: 15px;
    }
}

.hotspot-opt{
    margin-bottom: 30px;
    position: relative;
    border: 1px solid #ddd;
    width: 100%;
    height: 45px;
    span{
        display: block;
        font-weight: 600;
        border-right: 1px solid #ddd;
        position: absolute;
        left: 0;
        line-height: 45px;
        padding: 0 15px;
    }
    .clear-hotspot{
        display: block;
        position: absolute;
        right: 0;
        border-left: 1px solid #ddd;
        line-height: 45px;
        padding: 0 15px;
        color: #333;
        text-decoration: none;
        &:hover{
            color: @blue;
        }
    }
}

.tool-list{
    position: absolute;
    left: 101px;
    li{
        float: left;
        
        a{
            display: block;
            width: 45px;
            height: 44px;
            border-right: 1px solid #ddd;
            position: relative;
            .transition(all 0.4s ease);
            &:before{
                content: '';
                display: block;
                border: 2px solid #bbb;
                background-color: #fbfbfb;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                .transform(translate(-50%, -50%));
            }
            &:hover, &.active{
                background-color: #ddd;
                &:before{
                    border-color: #333;
                }
            }
        }
        .circle{
            &:before{
                border-radius: 100%;
            }
        }
        .pentagon{
            &:before{
                top: 12px;
                left: 12px;
                transform: rotate(45deg);
            }
        }
    }
}

.hotspot-img{
    position: relative;
    min-height: 200px;
    img{
        width: 100%;
    }
    .dropzone{
        min-height: 100px;
    }
    .dropzone#m-up-img{
        margin-bottom: 30px;
    }
    .dropzone#st-up-img{
        min-height: 65px;
        padding: 0;
        margin-bottom: 30px;
    }

    .st-img-up{
        position: relative;
        &:hover{
            a{
                display: block;
            }
        }
        a{
            display: none;
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 18px;
            color: #fff;
            background-color: @red;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            opacity: 0.5;
            .transition(all 0.4s ease);
            &:hover{
                // background-color: darken(@red, 10%);
                opacity: 1;
            }
        }
    }
}

.kf-tb{
    .new-usr-valid{
        font-size: 12px;
    }
    select{
        display: none;
        height: 32px;
        outline: none;
        border: 1px solid #ddd;
        line-height: 32px;
        border-radius: 2px;
        width: 100%;
        &.shw{
            display: block;
        }
    }
    .kf-slt-tagname{
        display: none;
    }
    .ipt{
        &.err{
            border-color: @red;
        }
    }

    .n-record-td{
        select{
            display: block;
            &.err{
                border-color: @red;
            }
        }
        .ipt{
            &.err{
                border-color: @red;
            }
        }
    }
    p{
        cursor: default;
    }
}

.station-upload-img{
    margin-top: 30px;
}

.define-st-blk{
    textarea{
        width: 100%;
        outline: none;
        width: 100%;
        border: 1px solid #ddd;
        background-color: transparent;
        padding: 5px 20px;
        font-size: 12px;
        color: #333;
        background-image: none;
        font-weight: normal;
        border-radius: 2px;
    }
}

.machine-info-img{
    position: relative;
    width: 180px;
    height: auto;
    margin: 0 auto;
    img{
        width: 100%;
    }
    .del-img{
        display: block;
        text-decoration: none;
        position: absolute;
        top: 50%;
        left: 50%;
        .transform(translate(-50%, -50%));
        font-size: 32px;
        color: @red;
        display: none;
        z-index: 30;
        background-color: rgba(234,232,232,0.7);
        border-radius: 100%;
        width: 55px;
        height: 55px;
        line-height: 55px;
    }
    &:before{
        content: '';
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #bbb;
        opacity: 0.7;
        top: 0;
        left: 0;
        z-index: 20;
    }
    &:hover{
        // &:before{
        //     display: block;
        // }
        .del-img{
            display: block;
        }
    }
}

.up-img-blk, .general-info{
    position: relative;
    min-height: 200px;
}
.uploadd-list, .file-choose{
    margin-top: 30px;
}
.general-info{
    .form-group{
        margin-left: 0;
        margin-right: 0;
        // padding: 0 15px;
    }
    .enable-toggle{
        padding-left: 0;
        margin-bottom: 0;
    }
}
.info-frm, .upload-doc-div, .attr-blk{
    position: relative;
    &:before{
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.7;
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.loading{
        &:before{
            display: block;
        }
    }
}
.info-frm  {
    .validate-button {
        right: 140px;
    }
}
.verification-list {
    max-height: 500px;
    overflow-y: auto;
    li {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 3px;
        list-style: none;
        font-size: 14px;
        line-height: 1.6;
        &:last-child {
            margin-bottom: 0;
        }
        color: #084298;
        background-color: #cfe2ff;
        border-color: #b6d4fe;
        &.error {
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;
        }
        &.warning {
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
        }
    }
}
.upload-img{
    &.shw{
        &:before{
            display: block;
        }
    }
    &:before{
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        z-index: 30;
        background-color: #fff;
        opacity: 0.7;
        top: 0;
        left: 0;
        position: absolute;
    }
}

.ipt-url{
    outline: none;
    width: 100%;
    border: 1px solid #ddd;
    background-color: transparent;
    padding: 5px 20px;
    font-size: 12px;
    color: #333;
    background-image: none;
    font-weight: normal;
    border-radius: 2px;
    &:focus, &:active{
        border-color: #bbb;
    }
}

// Machine modal
#station-info, 
#new-station, 
#new-cds, 
#datasource-info, 
#new-client-dts, 
#client-dts-info, 
#new-alert, 
#edit-alert, 
#edit-alert-tpl,
#new-alert-tpl,
#new-contract,
#contract-info,
#evt-ipt-modal,
#machine-list-modal,
#new-user{
    .modal-content{
        &:before{
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: 0.6;
            z-index: 150;
        }
        &.shw{
            &:before{
                display: block;
            }
        }
    }
}

.inf-en-toggle{
    position: relative;
    .enable-btn{
        &.mg-left{
            margin-left: -4px;
        }
    }
    .enable-msg{
        position: absolute;
        left: 90px;
        top: 50%;
        .transform(translateY(-50%));
        &.shw{
            display: inline-block;
        }

        &.text-danger {
            display: block;
            margin-top: 10px;
            top: unset;
            .transform(unset);
            position: initial;
        }
    }

    &.disabled {
        .box-shadow(none);
        .opacity(0.65);
        cursor: not-allowed;
        pointer-events: none;
        filter: alpha(opacity=65);
    }

}
.dts-slt{
    position: relative;
    p{
        position: absolute;
        top: 8px;
        right: 48px;

        &.text-danger {
            display: block;
            margin-top: 10px;
            top: unset;
            position: inherit;
            right: 0;
        }
    }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

// Keyfile table tooltip
.kf-tb.table-cus{
    tr{
        td{
            font-size: 13px;
            vertical-align: middle;
        }
    }
}
.kf-tb .state-status {
    width: 100%;
    margin-bottom: 0;
    // overflow-x: auto;
    cursor: default;
    position: relative;
    height: 100%;
    color: @green;
    &.unverified{
        color: @red;
    }
    .state-tooltip {
        display: block;
        width: 100%;
        z-index: 70;
        text-align: center;
        font-size: 11px;
        background-color: #000;
        color: #fff;
        padding: 3px 5px;
        border-radius: 2px;
        position: absolute;
        .opacity(0);
        visibility: hidden;
        .transition(all 0.4s ease);
        &:before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #000;
            position: absolute;
            top: -5px;
            left: 50%;
            .transform(translateX(-50%));
            .opacity(0);
            visibility: hidden;
        }
    }
    &:hover{
        .state-tooltip{
            .opacity(1);
            visibility: visible;
            &:before{
                .opacity(1);
                visibility: visible;
            }
        }
    }
}

.attr-blk{
    margin-bottom: 30px;
}
.new-machine{
    .c-tab{
        .disabled{
            pointer-events: none;
            color: #ddd;
        }
    }
}

.attr-frm{
    .ipt{
        &.err{
            border-color: @red;
        }
    }
}
.m-attr{
    .ipt{
        &[readonly]{
            background-color: #ddd;
            pointer-events: none;
        }
    }
}
.m-dt-head{
    width: 100%;
    float: left;
    margin-top: 10px;
}
.select-2{
    .select2-container{
        width: 100% !important;
    }
    &.error{
        .select2-container--default .select2-selection--single{
            border-color: @red;
        }
    }
}
.log-blk{
    // padding: 0 15px;
    height: 300px;
    position: relative;
    overflow-y: auto;
    display: none;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.7;
        z-index: 50;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.load-log{
        &:before{
            display: block;
        }
    }
    &.shw{
        display: block;
    }
    .table{
        p{
            width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: default;
            margin: 0;
        }
    }
}

.log-itm{
    // border: 1px solid #ddd;
    position: relative;
    border-left: 5px solid @red;
    padding: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
    // border-radius: 2px;
    &.running{
        border-left-color: @blue;
    }
    &.completed{
        border-left-color: @green;
    }
    h3{
        font-size: 16px;
        font-weight: 600;
    }
    p{
        span{
            display: inline-block;
            margin-right: 10px;
            font-weight: 600;
            width: 120px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.m-readonly{
    &[readonly]{
        background-color: #ddd;
        pointer-events: none;
    }
}
.not-edit{
    font-size: 11px;
    // display: inline-block;
    // margin-top: 3px;
}
.attr-func{
    // height: 32px;
    position: relative;
    .attr-desc{
        position: absolute;
        top: 16px;
        left: -25px;
        transform: translateY(-50%);
        display: block;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        border-radius: 100%;
        background-color: #eee;
        font-size: 11px;
        color: #999;
        cursor: default;
    }
}
.attr-tooltip{
    position: absolute;
    background-color: #333;
    color: #fff;
    border-radius: 2px;
    padding: 5px;
    top: 32px;
    left: -40px;
    visibility: hidden;
    .opacity(0);
    z-index: 100;
    .transition(all 0.4s ease);
    min-width: 160px;
    min-height: 15px;
    &:before{
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #333;
        top: -5px;
        position: absolute;
        left: 18px;
    }
    &.shw{
        .opacity(1);
        visibility: visible;
        // z-index: 100;
    }
}

.kf-search{
    margin-bottom: 15px;
    height: 32px;
    position: relative;

    .select-search{
        display: inline-block;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #ddd;
        &:focus, &:active{
            border-color: #bbb;
        }
    }
    .ipt{
        display: inline-block;
        height: 33px;
        padding-left: 30px;
    }

    button {
        border: none;
        display: block;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 32px;
    }
    .no-found{
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
    }
}

.kf-action-left a {
    margin-left: 14px;

    &.add-single-kf {
        margin-right: 40px;
    }
}

.kf-action-left {
    display: inline-block;
    width: calc(~'100% - 285px');
    text-align: right;
}

.kf-action-right {
    display: inline-block;
    float: right;
    position: relative;
    padding-left: 20px;
}

.kf-action-right,
.kf-remove-action {
    &:before {
        content: '';
        width: 2px;
        background: #ebecef;
        height: 40px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.kf-remove-action {
    padding-left: 20px;
    padding-top: 4px;
    display: inline-block;
    position: relative;
    margin-left: 20px;

    span {
        vertical-align: middle;
        margin-right: 20px;
    }

    .btn-remove-kf {
        border-color: #e91a3c;
        color: #e91a3c;
        margin-left: 10px;

        &:hover {
          color: #e91a3c;
        }
    }
}

.edit-btn {
    i {
        vertical-align: middle;
    }

    span {
        color: #7f8181;
        vertical-align: middle;
    }
}

// Custom css
.inline-block {
    display: inline-block;
}

.block {
  &.disabled {
      .box-shadow(none);
      .opacity(0.65);
      cursor: not-allowed;
      pointer-events: none;
  }
}

.defaultAlert {
    background: #fff;
    position: fixed;
    width: 250px;
    margin: auto;
    overflow: visible;
    z-index: 10;
    .transform(translate3d(0, 0, 0));
    transition: 1s right, 4s 1s left cubic-bezier(0,1,1,1);
    opacity: 1;
    bottom: 7px;
    right: -100%;
    .box-shadow(0 2px 20px 0 #cfd0d0);

    &.active {
        right: 7px;
    }

    .title {
        color: #000;
        font-weight: bold;
        margin-bottom: 10px;
    }

    span.close {
        color: #000000;
        text-decoration: none;
        line-height: 18px;
        font-size: 30px;
        font-weight: 700;
        text-shadow: 0 1px 0 #fff;
        top: 5px;
        right: 10px;
        position: absolute;
        cursor: pointer;
    }
}

.keyfile-tb {
  .attr-tooltip {
    left: 50%;
    transform: translateX(-50%);
    top: 46px;
    text-align: center;
    padding: 15px;

    &.kf-mc-description-tooltip {
      .box-shadow(0 2px 20px 0 #cfd0d0);
      background: #fff;
      color: #000;
      top: 0;
      text-align: left;
      width: 100%;

      &:before {
        display: none;
      }

      &.shw {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #333;
      top: -9px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &.shw {
        left: 50%;
        top: 45px;
        transform: translateX(-50%);
    }
  }

  .kf-wrap {
    .multiLineEllipsis(18px, 2);
  }

  .system-row {
      background: #cfcfcf;

      .not-allow {
          cursor: not-allowed;
          pointer-events: none;
      }
  }
}

.remote-block {
    position: relative;
    margin-top: 50px;
}

.text-capitalize {
    text-transform: capitalize !important;
}