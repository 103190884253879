/*
 * Tiles grid css
 *
*/ 

.tiles-ct{
    background-color: #fff;
    min-height: 660px;
}
.tiles-wrap{
    padding-top: 30px;
}
// Dashboard title item
.machine-itm{
    font-size: 12px;
    position: relative;
    display: block;
    color: #333;
    margin-bottom: 30px;
    border-radius: 3px;
    background-color: #fff;
    padding: 15px;
    border-top: 2px solid #ddd;
    .box-shadow(0px 2px 5px 0px rgba(207,208,208,1));
    .transition(all 0.4s ease);
    &:hover, &:focus, &:active{
        color: #333;
        text-decoration: none;
    }
    &.red{
        border-color: @red;
    }
    &.green{
        border-color: @green;
    }
    &.yellow{
        border-color: @yellow;
    }
    h2{
        font-weight: bold;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 10px;
        height: 44px;
        line-height: 20px;
        overflow: hidden;
        span{
            margin-left: 10px;
            color: #999;
        }
    }
    .machine-img{
        width: 150px;
        margin: 0 auto;
        img{
            width: 90%;
        }
    }
    .tile-ico{
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .time-ipt{
        margin-bottom: 0;
        margin-top: 15px;
        font-size: 10px;
        font-weight: 700;
        &.l-refresh-time{
            margin-top: 0;
        }
    }
}

.tile-section {
    &.act {
        .row > .tile-itm:not(.act) {
          opacity: 0.4;
        }
    }

    .qpa-itm {
        min-height: 45px;
        .qpa-canvas-inf {
            min-width: 45px;
            min-height: 45px;

            .chrt,
            .chrt canvas {
                min-width: 35px;
                min-height: 35px;
            }
        }
    }

    .machine-itm {
        &.type-machine:before,
        &.type-line:before {
            display: inline-block;
            font-size: 22px;
            right: 15px;
            text-rendering: auto;
            width: 30px;
            position: absolute;
            -webkit-font-smoothing: antialiased;
            text-align: center;
            cursor: pointer;
            border-radius: 2px;
        }

        &.type-machine {
            &:before {
                background: url("../../../images/icon/machine.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 23px;
                height: 22px;
                top: 25px;
            }

            &.logical:before {
                background: url("../../../images/icon/machine_dotted.png");
                width: 23px;
                height: 23px;
                background-size: cover;
            }
        }

        &.type-line:before {
            content: "\F067";
            font: normal normal normal 22px/1 FontAwesome;
            top: 28px;
        }

        &.type-line {
            cursor: pointer;
            position: relative;
            transition: transform 0.3s ease-in-out;
            will-change: transform;
            transform: translate(0px, 0px);
            padding: 0;
            display: block;

            &:before {
                transform: translate(7px, 7px);
                background-color: #adadad !important;
            }

            &:after {
                transform: translate(14px, 14px);
                background-color: darken(#adadad, 30%) !important;
            }

            &:before,
            &:after {
                border-radius: 3px;
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: currentColor;
                transition: transform 0.3s ease-in-out;
                will-change: transform;
                display: block;
                width: auto;
            }

            &:before {
                z-index: -1;
            }

            &:after {
                z-index: -2;
            }

            .machine-itm-content {
                background-color: #fff !important;
                border-radius: 3px;
                padding: 15px;

                &:before {
                    content: "";
                    font: normal normal normal 22px/1 FontAwesome;
                    right: 20px;
                    top: 21px;
                    position: absolute;
                    background: url("../../../images/icon/line.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    width: 80px;
                    height: 32px;
                }

                //&.logical:before {
                //    background: url("../../../images/icon/machine_dotted.png");
                //    width: 23px;
                //    height: 23px;
                //    background-size: cover;
                //}
            }

            .qty-child {
                position: absolute;
                right: -10px;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                color: #fff;
                font-weight: bold;
                text-align: center;
                line-height: 23px;
                top: -10px;
                font-size: 16px;
                border: 2px solid transparent;
                background: transparent;
            }

            &.green .qty-child {
                border: 2px solid #1cc861;
                background: #1cc861;
            }

            &.yellow .qty-child {
                border: 2px solid #ffe44d;
                background: #ffe44d;
            }

            &.red .qty-child {
                border: 2px solid #e81a3c;
                background: #e81a3c;
            }
        }

        .normal-canvas {
            width: 45%;

            canvas {
                width: 35px;
                height: 35px;
            }

        }

        .r-f-scr {
            .col-md-4:last-child {
                width: 21%;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .tile-itm {
        width: 33.33% !important;
        @media (max-width: 1440px) {
            &.changeover {
                width:50% !important;
            }
        }
    }
}

@media (max-width: 991px) {
    .tile-section .machine-itm .normal-canvas {
        width: 60%;
    }

    .tile-section .machine-itm .r-f-scr .col-md-4:last-child {
        width: 40%;
    }

    .tile-section .machine-itm .full-scr-p {
        font-size: 1.083em;
    }
}


.machine-itm{
    &:hover{
        .box-shadow(0px 2px 20px 0px rgba(207,208,208,1));
    }
}

.info{
    position: relative;
    width: 150px;
    min-width: 100px;
    min-height: 100px;
    margin: 0 auto;

  canvas {
    min-width: 100px;
    min-height: 100px;
  }
}
.oee{
    .fontCafe;
    position: absolute;
    left: 50%;
    top: 50%;
    .transform(translate(-50%, -50%));
    p{
        position: relative;
        font-size: 34px;
        span{
            .fontRaleway(700);
            font-size: 10px;
            display: block;
            position: absolute;
            top: 7px;
            right: -22px;
            &:last-child{
                top: 18px;
                right: -16px;
                font-size: 16px;
            }
        }
    }
    &.nan{
        left: 50%;
        top: 50%;
        .transform(translate(-50%, -50%));
    }
}

.faults{
    height: 38px;
    width: 38px;
    background-color: #fff;
    opacity: 0.9;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    color: @dark;
    p{
        .fGogamBold;
        font-size: 14px;
        margin-bottom: 0;
        width: 38px;
        height: 38px;
        padding-top: 5px;
        position: relative;
        span{
            .fontRaleway(400);
            font-size: 10px;
            display: inline-block;
            position: absolute;
            bottom: 3px;
            left: 6px;
        }
    }
}

.parts{
    width: 50px;
    height: 50px;
    background-color: #fff;
    opacity: 0.9;
    border-radius: 50%;
    position: absolute;
    bottom: -15px;
    left: 50%;
    .transform(translateX(-50%));
    text-align: center;
    color: @dark;
    .fGogamBold;
    p{
        font-size: 15px;
        margin-bottom: 0;
        width: 50px;
        height: 50px;
        padding-top: 10px;
        position: relative;
        span{
            font-size: 10px;
            display: inline-block;
            position: absolute;
            top: 25px;
            left: 50%;
            .transform(translateX(-50%));
        }
    }
}
.time-upt{
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 0;
}

.tile-itm{
    /*&.part-filler, &.greenville{
        display: none;
    }*/
    &.shw{
        display: block;
    }
    &.d_shw{
        display: none;
    }
}
.no-data{
    color: #ccc;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 0;
    &.d-first-child {
        display: none;
        &:first-child {
            display: block;
        }
    }
    .td {
        border-bottom: none !important;
    }
}

.tile-noti{
    position: absolute;
    right: 15px;
    top: 15px;
    a{
        width: 27px !important;
        height: 27px !important;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 10px;
        background-image: url('../../../images/ok-ico.png');
        &.criticalsystemevent-ico{
            background-image: url('../../../images/critical.png') !important;
        } 
        &.alert-ico{
            background-image: url('../../../images/alert-ico.png') !important;
        } 
        &.alarm-ico{
            background-image: url('../../../images/warn-ico.png');
        } 
        &.info-ico{
            background-image: url('../../../images/info-ico.png');
        } 
        &.trend-ico{
            background-image: url('../../../images/warn-ico.png');
        } 
        &:last-child {
            margin-left: 0;
        }
    }
}

.noti-tooltip, .crit-tooltip{
    position: absolute;
    top: 60px;
    right: 15px;
    background-color: #1c2b36;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    min-width: 100px;
    opacity: 0;
    .transition(all 0.4s ease);
    &.shw{
        opacity: 1;
        &:before{
            display: block;
        }
    }
    &:before{
        content: '';
        display: none;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #1c2b36;
        position: absolute;
        top: -5px;
        right: 15px;
    }
}

.crit-tooltip{
    right: 40px;
}

// Dashboard title image item block
.machine-itm{
    .tile-full-img{
        width: auto;
        img{
            width: 100%;
        }
    }
}

.t-f-noti{
    right: 1vw;
    top: 3vh;
    a{
        width: 1.4vw;
        height: 4vh;
    }
}
.qpa-itm{
    height: 45px;
    position: relative;
    margin-bottom: 1.2vh;
    &:last-child{
        margin-bottom: 0;
    }
    strong{
        font-size: 1.1vw;
        position: absolute;
        left: 0;
        top: 50%;
        .transform(translateY(-50%));
    }
    .qpa-canvas-inf{
        position: absolute;
        width: 45px;
        height: 45px;
        top: 50%;
        .transform(translateY(-50%));
        left: 13%;
        
        .chrt{
            position: relative;
            width: 45px;
            height: 45px;
            span{
                font-weight: bold;
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: .8vw;
                .transform(translate(-50%, -50%));
            }
        }
    }
    .qpa-txt{
        position: absolute;
        left: 47%;
        top: 50%;
        .transform(translateY(-50%));
        font-size: .8vw;
        p{
            margin-bottom: 0px;
            font-weight: bold;
        }
    }

    .hpu-txt {
        margin-bottom: 0px;
        font-size: .8vw;
        .tiltle {
            display: inline-block;
            width: 50%;
            margin-right: 20px;
            font-weight: bold;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.machine-itm {
    .full-scr-p{
        //font-size: .7vw;
        font-size: 1.083em;
    }
    .h-full-scr{
        //font-size: 1vw;
        font-size: 1.58em;
        line-height: 5vh;
        margin-bottom: 20px;
        height: auto;
    }

    .oee-full-scr {
        font-size: 3.8vw;

        span {
            font-size: 0.263em;

            &:last-child {
                font-size: 0.375em;
            }
        }
    }

    .qpa-itm {
        strong {
            font-size: 1.8em;
        }

        .qpa-canvas-inf .chrt span {
            font-size: 1.25em;
        }

        .qpa-txt {
            font-size: 1.25em;
        }
    }
}

.oee-full-scr{
    // left: 40%;
    font-size: 3.8vw;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    .transform(translateY(-50%));
    left: 15%;
    &.resize-font {
        font-size: 2.6vw;
        span{
            top: 16%;
            font-size: .8vw;
            &:last-child{
                font-size: 1.1vw;
                top: 41%;
            }
        }
    }
    span{
        font-size: 1vw;
        top: 20%;
        position: absolute;
        left: 96%;
        &:last-child{
            font-size: 1.4vw;
            position: absolute;
            top: 42%;
            left: 100%;
        }
    }
    
}
.r-f-scr{
    margin-left: -5px;
    margin-right: -5px;
    [class*="col-"]{
        padding-left: 5px;
        padding-right: 5px;
    }
}

// Hide menu tile
//.t-f-scr{
//    height: 150px;
//    .title {
//        padding-top: 10px;
//    }
//}
.m-h-f-scr{
    //height: 40px;
    //margin-top: -40px;
    .group-machine, .from-to {
        >a{
            padding: 20px 0;
        }
    }
    .search-machine, .date-picker{
        top: 40px;
    }
}
.tile-full-scr{
    padding-top: 15px;
}

.f-scr-itm{
    // min-height: 330px;
    margin-bottom: 4vh;
}

.machine-header {
    .from-to{
        .link-tile-full{
            position: absolute;
            right: 280px;
            display: block;
            width: 100px;
            text-decoration: underline;
        }
    }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0);
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
    from {
        transform: translate3d(0,-10%,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInDown {
    from {
        transform: translate3d(0,-10%,0);
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}
.animatedFadeInUp {
  opacity: 0;

  &.fadeInUp {
    display: block;
  }
}

.animatedFadeInDown {
    opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.fadeInDown {
  opacity: 0;
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
}

.select-display .chk-td label:before {
  display: none;
}

.relative-machine-btn {
    top: 320px;
    display: block;
}

.chk-td-right label:before {
    content: '';
    display: none !important;
}

.chk-td-right {
    display: inline-block;
    position: relative;
    width: 110px;
    padding: 17.5px 0;
}

.chk-td-right label:after {
    width: 20px;
    content: "\F096";
    display: inline-block;
    text-align: left;
    margin-left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.chk-td-right label span {
    font-family: Raleway, sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.chk-td-right input[type=checkbox]:checked + label:after {
    content: '\f046';
}

.group-lines {
    position: relative;
    padding: 15px 0;
    margin-bottom: 20px;
    border-radius: 3px;
    background: #fff;
    display: none;
    margin-left: 15px;
    margin-right: 15px;
    clear: both;
    float: left;
    width: calc(~'100% - 30px');

    &.m-select-1 {
        &:before,
        &:after {
            left: 15%;
        }
    }

    &.m-select-2 {
        &:before,
        &:after {
            left: 50%;
        }
    }

    &.m-select-3 {
        &:before,
        &:after {
            left: 85%;
        }
    }

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        top: -35px;
    }

    &:before {
        border-width: 17px;
    }

    &:after {
        border-color: transparent transparent #fff transparent;
        border-width: 18px;
    }

    &.c-green {
        border: 2px solid #1cc861;
        .box-shadow(0px 2px 5px 0px #1cc861);

        &:before {
            border-color: transparent transparent #1cc861 transparent;
        }
    }

    &.c-yellow {
        border: 2px solid #ffe44d;
        .box-shadow(0px 2px 5px 0px #ffe44d);

        &:before {
            border-color: transparent transparent #ffe44d transparent;
        }
    }

    &.c-red {
        border: 2px solid #e81a3c;
        .box-shadow(0px 2px 5px 0px #e81a3c);

        &:before {
            border-color: transparent transparent #e81a3c transparent;
        }
    }

    &.shw {
        display: block;
    }

    &.m-append .tile-itm:nth-child(3n+1) {
        clear: left;
    }
}

.head-machine-info {
    padding: 10px 10px 20px 10px;

    .view-all-btn {
        line-height: 30px;
        color: #000;
        font-weight: 700;
        font-size: 1.58em;
        text-decoration: underline;
    }

    .head-machine-item {
        span {
            display: inline-block;
            margin-right: 25px;
            border: 2px solid #ddd;
            padding: 5px 10px;
            font-weight: 600;
            border-color: #ddd;

            &.low {
                border-color: #e81a3c;
            }
            &.normal {
                border-color: #ffe44d;
            }
            &.high {
                border-color: #1cc861;
            }
        }
    }
}

@media (max-width: 991px) {
    .select-display {
        display: inline-block;
    }
}

@media (min-width: 992px) {
    .select-display {
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .tile-section {
        .machine-itm {
            .canvas-full,
            .canvas-full canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
