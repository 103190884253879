/*
 * Machine view
 *
*/ 

.chart-ttl{
    position: relative;
    font-size: 18px;
    font-weight: 700;
    height: 40px;
    margin-top: 0;
    margin-bottom: 15px;
    // &:before{
    //     content: '';
    //     position: absolute;
    //     width: 35px;
    //     height: 35px;
    //     display: block;
    //     border: 2px solid #ddd;
    // }
    >span{
        display: block;
        /*width: 250px;*/
        height: 26px;
        background-color: #fff;
        z-index: 100;
        position: absolute;
        left: 10px;
        top: 5px;
        padding-top: 3px;
        text-transform: uppercase;
    }
}

.export-btn{
    position: absolute;
    right: 0;
    top: 2px;
    .transition(all 0.4s ease);
    i{
        margin-right: 10px;
        color: @green;
        font-size: 18px;
    }
}

.chart-draw{
    // margin-bottom: 60px;
    text-align: right;
    min-height: 400px;
    height: 100%;
    float: left;
    width: 100%;

}
#faultTrendChrt{
    height: auto;
}

#trendChrt {
    height: auto;
}
#f-sankey{
    height: auto;
}
#oeePart{
    /*height: 700px;*/
}
.amcharts-chart-div {
    height: 100% !important;
    min-height: 400px;
}
.chrt-wait{
    width: 100%;
    min-height: 400px;
    position: relative;

    .chrt-zoom{
        color: #000;
        position: absolute;
        display: none;
        top: 36px;
        right: 20px;
        background-color: transparent;
        padding: 10px 20px;
        z-index: 100;
        text-decoration: none;
        &:hover{
            background-color: #ddd;
        }
        &.shw{
            display: block;
        }
        &.o-d-zoom, &.trend-zoom{
            right: 20px;
        }
        &.o-p-zoom{
            right: 60px;
        }
        &.oee-zoom{
            right: 56px;
        }
        &.prod-zoom{
            right: 88px;
        }
        &.fault-zoom{
            right: 20px;
            top: 20px;
        }
        &.dt-zoom{
            right: 82px;
        }
    }
}

.f-tab-trend{
    .chrt-wait{
        height: 700px;
    }
}

ul.c-tab > li > a {
    padding-left: 30px;
    padding-right: 30px;
}

.machines-content {
    .filt-inner {
        overflow-x: hidden;
        height: 100%;
        padding-bottom: 65px;
    }

    .title-filt-detail {
        padding-bottom: 10px;
        color: #fff;
    }

    .filt-list {
        margin-top: 30px;
        overflow-y: visible;

        .fa-arrow-up {
            color: #1cc861;
        }

        .fa-arrow-down {
            color: #e81a3c;
        }
    }

    a {
        color: #fff;
        text-decoration: underline;
    }
}
.mr-10 {
    margin-right: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-5 {
    margin-top: 5px;
}
.machine-detail {
    display: flex;
    .mx-auto {
        margin: 0 auto;
    }
    .line-center {
        display: none;
    }
    .date-range-picker {
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 1680px) {
        .machine-info {
            width: 50%;
        }
    }
}
.card-block {
    position: relative;
}
.overview-tab {
    .chrt-wait {
        .chrt-zoom {
            &.o-p-zoom {
                top: 0;
                right: 0;
            }
        }
    }
}