/*
 * Table css
 *
*/ 
.c-tb::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.c-tb::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.c-tb::-webkit-scrollbar-thumb
{
	background-color: rgba(0,0,0,0.3);;
}
.c-dt-table{
    border: 1px solid #ddd;
    .box-shadow(0px 2px 2px 0px rgba(0,0,0, 0.1));
    margin-bottom: 10px;
}
.c-tb-th{
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.c-tb{
    
    // height: 545px;
    height: 620px;
    width: 100%;
    overflow-y: auto;
    position: relative;
    tr{
        td{
            font-size: 15px;
        }
    }
}

.table-cus{
    border-collapse: initial;
    margin-bottom: 10px;
    width: 100%;
    &:first-child{
        margin-bottom: 0;
    }
    tr{
        // height: 60px;
        &.tb-th{
            height: 0px !important;
            th{
                padding: 0 !important;
            }
        }
        td{
            // text-align: center;
            padding: 15px 10px !important;
            &:nth-child(2){
                // text-align: left;
            }
            .oee-chk{
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                background-color: @green;
                padding-top: 1px;
                font-size: 10px;
                text-align: center;
            }
            .status-gd{
                display: inline-block;
                width: 20px;
                height: 20px;
                // margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                background-color: @green;
                padding-top: 2px;
                font-size: 12px;
                text-align: center;
                &.status-bad{
                    background-color: @red;
                }
                &.status-cog{
                    background-color: @blue;
                }
            }
        }
    }
}

.table-cus{
    td{
        position: relative;
        .expand-tr{
            // position: absolute;
            cursor: pointer;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            // top: 35%;
            // left: 5px;
            font-size: 18px;
            display: none;
            color: #333;
            &:hover, &:active, &:focus{
                text-decoration: none;
            }
            &:before{
                content: '\f196';
            }

            &.active{
                &:before{
                    content: '\f147';
                }
            }
        }
    }
}

.table-cus{
    tr{
        &.sub-td{
            display: none;
            &:hover{
                background-color: transparent !important;
                td{
                    background-color: transparent !important;
                }
            }
            td{
                text-align: left !important;
                padding: 15px 60px !important;
                padding-bottom: 0;
                background-color: #fff !important;
                li{
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #ddd;
                    &:last-child{
                        border-bottom: none;
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                    }
                    .dtr-title{
                        display: inline-block;
                        width: 120px;
                        margin-right: 10px;
                        font-weight: bold;
                    }
                    &.td-860{
                        display: none;
                    }
                }
            }
            // &.active{
            //     display: table-row;
            // }
        }
    }
}


.table-cus{
    tr{
        th{
            cursor: pointer;
            span{
                padding: 20px 10px;
                display: block;
                position: relative;
                &:hover{
                    // background-color: #ddd;
                }
                i{
                    /*position: absolute;*/
                    /*top: 20px;*/
                    margin-left: 2px;
                }
            }
        }
        &:hover{
            td{
                background-color: #eee;
            }
        }
        &:first-child{
            th{
                border-top: none;
                vertical-align: middle;
                // text-align: center;
            }
        }
        &:last-child{
            td{
                border-bottom: 1px solid #ddd;
            }
        }
    }
}


.table-ft{
    border-top: 1px solid #ddd;
    padding: 0 15px;
    padding-top: 5px;
    height: 50px;
    .show-itm{
        padding-top: 3px;
        strong{
            margin-right: 10px;
        }
        .form-control{
            display: inline-block;
            border-radius: 2px;
            width: 50px;
            box-shadow: none;
            margin-right: 10px;
            padding: 0;
        }
    }
    .paging{
        padding-top: 2px;
        .btn{
            display: inline-block;
            padding: 0px 20px;
            width: 110px;
            margin-left: 10px;
            text-align: center;
            color: @dark;
            .transition(all 0.5s ease);
            &:last-child{
                margin-left: 5px;
            }
            &:hover, &:active, &:focus{
                text-decoration: none;
            }
        }
    }
}
.bg-eee{
    background-color: #eee;
}
.bg-f7{
    background-color: #f7f7f7;
}
.bg-fb{
    background-color: #fbfbfb;
}
.bg-eefaff{
    background-color: #eefaff;
}


// Production tab table
.prod-tb{
    .col-eq{
        width: 12%;
    }
    .col-sm {
        width: 10%;
    }
}

// Dashboard grid table
.d-g-tb{
    .col-small{
        width: 3%;
    }
    .col-big{
        width: 20%;
    }
    .col-eq{
        width: 10%;
    }
    .col-med{
        width: 7%;
    }
    .summary{
        position: absolute;
        height: 61px;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.8);
    }
}
.machine-pg-tb{
    position: relative;
}
// Dashboard geo table
.geo-tb{
    .col-small{
        width: 3%;
    }
    .col-big{
        width: 34%;
    }
    .col-eq{
        width: 10%;
    }
}

// Noti table
.notify-tb{
    .col-sm{
        width: 10%;
    }
    .col-eq{
        width: 45%;
    }
    .col-medium{
        width: 15%;
    }
}

// Admin manage user table
.user-tb{
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 15%;
    }
    .col-lg{
        width: 25%
    }
}

// Admin manage client table
.client-tb{
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 30%;
    }
    .col-med{
        width: 15%
    }
}

// Admin machine table
.ad-machine-tb{
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 15%;
    }
    .col-eq-ad-tb{
        width: 12%;
    }
    .col-med{
        width: 15%
    }
}

.td-txt-ellipsis{
    max-width: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.m-station-tb{
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 20%;
    }
    .col-bg{
        width: 65%
    }
    .col-med{
        width: 10%;
    }

    .colAhalf {
        width: 50% !important;
    }
}

.kf-tb{
    .col-xs{
        width: 3%;
    }
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 8%;
    }
    .col-meq{
        width: 10%;
    }
    .col-leq{
        width: 12%;
    }
    .col-seq{
        width: 13%;
    }
    .col-lseq{
        width: 14%;
    }
    .col-mlarge {
        width: 15%;
    }
    .col-large {
        width: 22%;
    }
    .col-large {
        width: 100px;
    }
}

.cloud-tb{
    .col-sm{
        width: 5%;
    }
    .col-md{
        width: 17%;
    }
    .col-eq{
        width: 10%;
    }
}
.dts-tb{
    .col-sm{
        width: 5%;
    }
    .col-md{
        width: 10%;
    }
    .col-bg{
        width: 35%;
    }
    .col-eq{
        width: 25%;
    }
}

.contract-tb{
    .col-sm{
        width: 5%;
    }
    .col-eq{
        width: 20%;
    }
    .col-lg{
        width: 70%;
    }
}

.word-break {
    word-break: break-word;
}

// Make default hover effect for grid "a" element
.table-cus tr td:not(:last-child) a:not(.shw-pass):hover,
.tb-content-inner .table-row .table-comp:first-child a:hover {
    color: #23527c;
}

.expand-btn{
    width: 10px;
    display: block;

    &:before {
        content: "\f105";
        font: normal normal normal 22px/1 FontAwesome;
        color: #333;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
    }

    &.act:before {
        content: "\f107";
    }
}

.line-table {
    width: 100%;
}

tbody {
    &.act {
        .tr-expand {
            display: table-row;
        }
    }

    .tr-expand {
        display: none;

        > td {
            padding: 0 !important;
        }
    }
}

.table-machine-v2 {
    tr > th {
        width: 8%;

        &.col-big {
            width: 17%;
        }

        &.col-small {
            width: 3%;
        }
    }
}

.relative {
    position: relative !important;
}

.hidden-tr {
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    height: 0;

    th ,
    th > span {
        height: 0;
        padding: 0 !important;
        margin: 0;
        font-size: 0 !important;
        line-height: 0 !important;
    }
}

.table-cus,
.geo-tb {
    tr.tr-expand:hover td {
        background-color: #fff !important;

        &.bg-eee {
            background-color: #eee !important;
        }

        &.bg-f7 {
            background-color: #f7f7f7 !important;
        }

        &.bg-fb {
            background-color: #fbfbfb !important;
        }

        &.bg-eefaff {
            background-color: #eefaff !important;
        }
    }
}

.mch-sub {
    > td:nth-child(2) {
        padding-right: 0 !important;
        text-align: right;
    }

    .expand-tr {
        float: left;
    }
}