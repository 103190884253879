.custom-mat-autocomplete {
    width: 100%;
    position: relative;
}
.custom-mat-search-btn {
    position: absolute;
    top: ~"calc(50% - 14px)";
    border: 0px;
    background-color: transparent;
    width: 35px;
    font-size: 18px;
}

.custom-mat-autocomplete>input {
    width: 100%;
    border: 0px;
    padding: 12px;
    padding-left: 35px;
    background-color: #F5F5F5;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1;
    &:hover, &:focus {
        background-color: #F3F6FF;
    }
    &:focus {
        outline-color: #005AFF;
    }
}

//drop down
.custom-mat-autocomplete .dropdown-menu {
    right: 0;
    padding: 0px;
    overflow: hidden;
    border-radius: 5px;
    z-index: 200;
}
.custom-mat-autocomplete .sub-dropdown-menu {
    > .dropdown-item {
        padding: 20px;
        padding-right: 15px;
        border-bottom: 1px solid #EEEEEE;
        font-size: 14px;
        cursor: pointer;

        &.is-active,
        &:hover {
            background-color: #005AFF;
            color: #fff;
        }
        &:last-child {
            border-bottom: 0px;
        }
        
    }
    .no-data {
        padding: 20px;
        font-size: 16px;
    }
}