/*
 * OEE tab css
 *
*/

.oee-chart{
    margin-bottom: 60px;
    p{
        font-weight: 700;
        font-size: 16px;
    }
}

.oee-table{
    .fGogamBook;
    .table{
        tr{
            td{
                font-size: 12px;
                /*border: none;*/
                &:first-child{
                    width: 70%;
                }
                &:last-child{
                    width: 30%;
                }
            }
        }
    }

    .financial-impact{
        margin: 20px 20px 73px;
        tr{
            td{
                border: none;
            }
        }
    }
    
}
.assump-blk{
    // height: 400px;
}
.assump{
    td{
        span{
            position: relative;
            /*&:before{
                content: '';
                width: 5px;
                height: 5px;
                background-color: #000;
                border-radius: 100%;
                display: block;
                position: absolute;
                left: -10px;
                top: 50%;
                .transform(translateY(-50%));
            }*/
        }
        label{
            font-size: 12px;
        }
    }
   
    .ipt {
        &:disabled {
            padding-left: 0;
        }
    }
}
.btn-edit-assump{
    position: absolute;
    right: 0;
    z-index: 100;
}
.chart-ttl{
    font-size: 16px;
}

.oee-summarize{
    font-weight: 600;
    padding-left: 8px;
    margin-bottom: 15px;
}