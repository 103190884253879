/*
 * Notification tab css
 *
*/

// Noti type

.noti-type{
    padding-top: 60px;   
    position: relative;
}

.noti-lst{
    li{
        position: relative;
        list-style: none;
        a{
            display: block;
            // position: relative;
            color: #333;
            padding: 15px 10px;
            font-size: 11px;
            text-transform: uppercase;
            &.active{
                background-color: #f5f5f5;
            }
            span{
                position: absolute;
                display: block;
                right: 10px;
                top: 10px;
                width: 25px;
                height: 25px;
                text-align: center;
                background-color: #ddd;
                color: #fff;
                border-radius: 100%;
                line-height: 25px;
                &.cus-alert{
                    background-color: @red;
                }
                &.cus-information{
                    background-color: @blue;
                }
                &.cus-trend{
                    background-color: @l_dark;
                }
                &.cus-criticalsystemevent{
                    background-color: #db0000;
                }
                &.cus-warning{
                    background-color: @red;
                }
            }
        }
    }
}

.noti-tb{
    // min-height: 400px;
    // border-left: 1px solid #ddd;
    padding-top: 30px;
    position: relative;
    .chk{
        position: relative;
        input[type="checkbox"]{
            display: none;
        }
        
        label{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 20px;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-weight: 100;
            cursor: pointer;
            span{
                font-size: 12px;
                font-weight: 100;
                position: absolute;
                left: 22px;
                top: 3px;
                display: inline-block;
                .fontRaleway(400);
            }
            &:before{
                content: '\f096';
            }
        }

        input[type=checkbox]:checked + label:before{
            content: '\f046';
        }
    }
    .f-t-table{
        margin-top: 30px;
    }

    .noti-action{
        position: absolute;
        top: 0;
        left: 0px;
        .act-slt{
            width: 150px;
            float: left;
            margin-right: 10px;
            .ipt{
                padding: 0;
            }
        }
    }
}

.noti-desc{
    position: relative;
    i{
        position: absolute;
        top: 10px;
        left: -10px;
        .transform(translateY(-50%));
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        cursor: default;
        // &:before{
        //     content: "\f097";
        // }
    }
    p{
        margin-bottom: 0;
        padding-left: 10px;
        font-weight: normal;
        // cursor: pointer;
    }
    &.unread{
        cursor: pointer;
        i{
            cursor: pointer;
            &:before{
            content: "\f111";
        }
        }
        p{
            font-weight: bold;
        }
    }
}

.notify-tb{
    tr{
        td{
            span{
                &.criticalsystemevent{
                    color: #db0000;
                }
                &.alert{
                    color: @red;
                }
                &.warning{
                    color: @red;
                }
                &.trend{
                    color: @l_dark;
                }
                &.information{
                    color: @blue;
                }
            }
        }
    }
}

.noti-search{
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    .noti-search-frm{
        position: relative;
        .search-noti{
            -webkit-appearance: none;
            -moz-appearance:    none;
            appearance:         none;
            &::-ms-clear {
                display: none;
            }
            &.err-srch{
                border-color: @red;
            }
        }
        a{
            position: absolute;
            left: -85px;
        }
        button{
            position: absolute;
            border: none;
            background-color: transparent;
            top: 6px;
            right: 5px;
            &.clear-search{
                right: 26px;
                font-size: 16px;
                top: 4px;
            }
        }
    }
}