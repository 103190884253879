/*
 * Station tab css
 *
*/


.station-tab{
    position: relative;
}

.machine-drawing{
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 75px;
}
.m-map{
    //overflow-x: auto;
    text-align: left;
    .machine-map {
        margin: 0 auto;
        canvas {
            width: auto !important;
            height: auto !important;
        }
    }
}
.drawing-opt{
    border-radius: 2px;
    height: 45px;
    background-color: #fff;
    position: absolute;
    top: 10px;
    right: 20px;
    color: #333;
    .box-shadow(0px 1px 2px 1px rgba(0, 0, 0, 0.1));
    a{
        display: block;
        float: left;
        width: 55px;
        height: 45px;
        color: #ddd;
        &:hover, &:active, &:focus{
            text-decoration: none;
        }
        &:hover{
            color: #999;
        }
        i{
            font-size: 24px;
            line-height: 48px;
        }
    }
    .shw-chrt,
    .shw-warning{
        border-right: 1px solid #cecece;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    .shw-point{
        border-left: 1px solid #cecece;
        border-right: 1px solid #cecece;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    .open-st-lst{
        border-left: 1px solid #cecece;
        display: none;
    }
}

.station-lst{
    margin-bottom: 75px;
    position: relative;
    min-height: 400px;
    h2{
        width: 100%;
        background-color: @l_dark;
        color: #fff;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        span{
            padding-top: 20px;
            padding-bottom: 20px;
            display: block;
        }
        i{
            font-size: 18px;
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;
            display: none;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 26px;
        }
    }
}

.st-lst{
    max-height: 970px;
    overflow: auto;
    li{
        font-size: 16px;
        // height: 80px;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        padding: 20px 30px;
        cursor: pointer;
        border-left: 5px solid transparent;
        p{
            margin-bottom: 0;
        }
        &:first-child{
            // height: 88px;            
            // padding-top: 28px;
            // padding-bottom: 28px;            
        }
        >strong{
            display: block;
            font-family: 'gotham_nvvbold';
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 18px;
        }
        &.active{
            background-color: #f0f5ff;
            border-left-color: @l_dark;
        }
        &:hover{
            background-color: #eee;
        }
    }
}

.station-info{
    .fontRaleway(400);
    font-size: 14px;
    .highest-dt{
        position: relative;
        &:before{
            content: '';
            width: 19px;
            height: 19px;
            display: block;
            border-radius: 100%;
            background-color: @green;
            opacity: 0.5;
            position: absolute;
            left: -5px;
            top: -5px;
            .animation(circle 2s infinite);
        }
        &:after{
            content: '';
            width: 29px;
            height: 29px;
            display: block;
            border-radius: 100%;
            background-color: @green;
            opacity: 0.1;
            position: absolute;
            left: -10px;
            top: -10px;
            .animation(circle 2s infinite);
        }
    }
    .highest-fault{
        position: relative;
        //.animation(circle 2s infinite);
        &:before{
            content: '';
            width: 19px;
            height: 19px;
            display: block;
            border-radius: 100%;
            background-color: @red;
            opacity: 0.5;
            position: absolute;
            left: -5px;
            top: -5px;
            .animation(circle 2s infinite);
        }
        &:after{
            content: '';
            width: 29px;
            height: 29px;
            display: block;
            border-radius: 100%;
            background-color: @red;
            opacity: 0.1;
            position: absolute;
            left: -10px;
            top: -10px;
            .animation(circle 2s infinite);
        }
    }
    .good{
        background-color: @green;
    }
    .medium{
        background-color: @yellow;
    }
    .unstable{
        background-color: @red;
    }
    .st-status{
        height: 9px;
        width: 9px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 5px;
    }
    .st-status-txt{
        width: 75px;
        display: inline-block;
    }
}

@keyframes circle{
    0%{
        .transform(scale(0.5, 0.5));
    }
    45%{
        .transform(scale(1, 1));
    }
    100%{
        .transform(scale(0.5, 0.5));
    }
}

@-webkit-keyframes circle{
   0%{
        .transform(scale(0.5, 0.5));
    }
    45%{
        .transform(scale(1, 1));
    }
    100%{
        .transform(scale(0.5, 0.5));
    }
}
@-moz-keyframes circle{
   0%{
        .transform(scale(0.5, 0.5));
    }
    45%{
        .transform(scale(1, 1));
    }
    100%{
        .transform(scale(0.5, 0.5));
    }
}

.station-chrt{
    margin-bottom: 30px;
}

.map-image-info {
    position: absolute;
    display: none;

    &.act {
        display: block;
        z-index: 101;
    }

    .content {
        padding: 10px;
        min-width: 200px;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 6px;
        outline: 0;
        text-align: left;
        position: relative;

        &.red {
            .box-shadow(0 2px 5px 0 @red);
            border: 2px solid @red;

            &:before {
                border-color: transparent transparent @red;
            }
        }

        &.green {
            .box-shadow(0 2px 5px 0 @green);
            border: 2px solid @green;

            &:before {
                border-color: transparent transparent @green;
            }
        }

        &.yellow {
            .box-shadow(0 2px 5px 0 @yellow);
            border: 2px solid @yellow;

            &:before {
                border-color: transparent transparent @yellow;
            }
        }

        &:after,
        &:before {
            border-width: 17px;
            border-style: solid;
            content: "";
            display: block;
            height: 0;
            position: absolute;
            width: 0;
        }

        &:after {
            border-color: transparent transparent #fff;
            top: -31px;
        }

        &:before {
            top: -34px;
        }

        .content-itm.name {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 15px;
            margin-top: 10px;
        }

        .close-action {
            cursor: pointer;
            font-size: 28px;
            position: absolute;
            right: 10px;
            top: -5px;
        }
    }
}
.m-map .machine-map {
    background-repeat: no-repeat;
    margin: 0;
    width: 100% !important;
    text-align: left;
}

.grid-map span {
    position: absolute;
    border: 1px solid #115aff;
    cursor: pointer;
}

.drawing-action {
    clear: both;
    margin-bottom: 10px;

    .focus-ac {
        margin-top: 10px;
    }
    
    .drawing-opt {
        position: initial;
        width: auto;

        a {
            text-align: center;
        }
    }
}