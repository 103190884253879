.meeting-session-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    .icon-btn {
        width: 30px;
        height: 30px;
        font-size: 24px;
        padding: 0px;
        display: inline-block;
        overflow: hidden;
        min-width: unset;
        background-color: transparent;
        color: #005aff;

        &:focus {
            color: #005aff;
        }
    }
}

.roster-component {
    position: absolute;
    right: 0px;
    top: 0px;
    // height: 300px;
    bottom: 0px;
    width: 275px;
    border: 2px solid #DCDCDC;
    background: #fff;
}

.meeting-tiles-container {
    width: calc(~'100% - 80px');
    height: 100%;
    position: relative;
    background-image: url('../../images/login-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.list-attendees-statuses {
    > li {
        list-style: none;
        padding: 10px;
        >span {
            margin-top: 7px;
            display: inline-block;
        }
    }

    .status-tag {
        padding: 5px;
        line-height: 1;
        color: #ffffff;
        border-radius: 10px;
        margin-bottom: 0px;
        font-size: 12px;
        margin-top: 3px;
        float: right;

        &.badge-success {
            background-color: #10A81F;
        }
        &.badge-secondary {
            background-color: #005aff;
        }
        &.badge-warning {
            background-color: #FFC81F;
        }
        &.badge-danger {
            background-color: #E81A3C;
        }
    }
    .icon-btn {
        display: block;
        float: right;
    }
}

.meeting-sub-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
}

.play-pause-btn {
    padding: 5px 10px;
    font-size: 18px;
    background-color: #005aff;
    border-radius: 15px;
    color: white;
    border: 0px;
}