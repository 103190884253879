@item_right_bottom_height: 170px;

.analysis-theme {
  .item-head {
    background: #000000;
    border-radius: 3px 3px 0px 0px;
    color: #ffffff;
    padding: 16px 12px;

    .item-name {
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 3px;
      color: #fff;

      &:hover {
        text-decoration: none;
        border-bottom: 1px dashed #dcdcdc;
      }
    }
    .item-product {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0px;
    }
  }

  .info-head {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    > span {
      color: #7f8181;
    }
  }

  .arrow-buttons {
    display: flex;
    button {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: 1px solid #005aff;
      color: #005aff;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-self: center;
      font-size: 10px;
      margin-right: 7px;

      &:last-child {
        margin-right: 0px;
      }

      &:disabled,
      &[disabled] {
        border: 1px solid #dcdcdc;
        color: #dcdcdc;
      }
    }
  }

  .info-tab-content {
    background: #f3f6ff;
    border-radius: 3px;
    position: relative;
    padding-bottom: 5px;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      top: 0px;
      width: 6px;
      border-radius: 3px 0px 0px 3px;
    }

    &.green {
      &::before {
        background-color: #abe3b0;
      }
    }

    > article {
      margin-bottom: 0px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;

      padding: 5px 0px 5px 15px;
      max-height: 24px;
      max-height: 24px;

      > p {
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0px;
        }

        strong {
          font-weight: 400;
          &.red {
            color: #e81a3c;
          }
        }
      }
    }

    .read-full-tag {
      margin-left: 15px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.info-box {
  text-align: right;
  > label {
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    color: #dcdcdc;
    font-weight: 400;

    &.grey {
      color: #7f8181;
    }
    &.red {
      color: #e81a3c;
    }
    &.green {
      color: #10a81f;
    }
  }
  > span {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }
}

.status-bar-box {
  .progress-outer {
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 3px !important;
    background-color: #c4c4c4 !important;
    width: 100% !important;
    border: 0px !important;
    height: 15px;
  }
  .progress-inner {
    min-width: 5% !important;
    max-width: 100% !important;
    border-radius: 3px !important;
    color: transparent;
    height: 15px;
  }
}
.status-bar-label {
  font-weight: 500;
}

.overview-item {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 830px;

  .item-head {
    display: flex;
    align-items: center;

    .right-ct {
      .info-box {
        width: 50%;
      }
    }
    .item-product {
      margin-top: 3px;
    }
  }

  .item-content {
    display: flex;

    .left-ct {
      width: 240px;
      min-width: 240px;
    }

    .right-ct {
      border-left: 1px solid #dcdcdc;
      width: 100%;
    }

    .content-top {
      padding: 18px 24px 20px;
      background-color: #f3f6ff;
    }

    .content-bottom {
      margin-left: -15px;
      margin-right: -15px;
    }
    .status-bars-col {
      width: 100%;
      display: flex;
      .status-bar-box {
        height: 55px;
        flex: 1;
        padding: 10px 9px 0px;
      }
    }
    .info-head {
      width: 100%;
      height: 75px;
      min-width: 85px;
      border-bottom: 1px solid #dcdcdc;
      .info-box {
        height: 100%;
        border-right: 1px solid #dcdcdc;
        text-align: center;
        flex: 1;
        padding-top: 16px;

        &:last-child {
          border-right: none;
        }
      }
    }
    .info-tab-content {
      left: 5px;
      width: calc(~"100% - 10px");
    }
  }
}

.overview-item {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 4px;
    background-color: rgba(232, 26, 60, 0.3);
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: 100;
    display: none;
  }
  &.flash-red {
    border: 4px solid rgba(232, 26, 60, 0.3);
    animation-name: border-blinking;
    animation-duration: 1s;
    animation-iteration-count: 100;
    &::after {
      // display: block;
    }
  }
}
@keyframes blinking {
  50% {
    background-color: #e81a3c;
  }
}
@-webkit-keyframes blinking {
  50% {
    background-color: #e81a3c;
  }
}
@-moz-keyframes blinking {
  50% {
    background-color: #e81a3c;
  }
}
@keyframes border-blinking {
  50% {
    border-color: #e81a3c;
  }
}
@-webkit-keyframes border-blinking {
  50% {
    border-color: #e81a3c;
  }
}
@-moz-keyframes border-blinking {
  50% {
    border-color: #e81a3c;
  }
}
