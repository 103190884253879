
.nr-search {
    padding: 15px;
    span {
        font-size: 14px;
        font-weight: 600;
    }
}

.nr-search-frm{
    width: 100%;
    position: relative;
    margin-top: 7px;
    button{
        position: absolute;
        top: 4px;
        border: none;
        background-color: transparent;
        left: 5px;
        font-size: 16px;
        color: #757575;
    }
    .ipt{
        padding-left: 35px;
    }
}

.nr-tags {
    padding: 15px;

}

.nr-tag-list {
    border-top: 1px solid #ddd;
    max-height: 415px;
    overflow-y: auto;
    
    a {
        border-bottom: 1px solid #ddd;
        color: #000000;
        font-weight: 500;
        font-size: 15px;
        width: 100%;
        height: 100%;
        display: block;
        padding: 15px;
    }

    a:hover {
        text-decoration: none;
        background: #F3F6FF;
    }
}
.nr-tag-inf-frm {
    max-height: 600px;
    overflow-y: auto;
    padding-bottom: 60px;
    .frm-blk {
        padding: 15px;
    }

    textarea {
        height: 130px;
        width: 100%;
        padding: 8px 20px;
        line-height: 1.5;
    }

    .status {
        display: block;
        color: #1cc861;
        padding-top: 8px;
        font-size: 15px;
        text-align: left;
        margin-bottom: 10px;
        &.unverified {
            color: #e81a3c;
        }
    }

    .enable-toggle {
        padding-left: 0px;
    }

    .attr-tooltip {
        left: 0px;
        &::before {
            left: 15px;
        }
    }
}

.btn {
    &.border-none {
        border: 0px;
        background-color: transparent;
    }

    &.left {
        float: left;
    }
}

