/**
 * CSS THING MANAGEMENT v2
 * @Author: huanvt@elinext.co
 * Date: July 2020
 */
.dashboard-manage-thing {
    .table-actions {
        display: flex;
        align-items: center;
        .general-act {
            padding-left: 13px;
            margin-left: 13px;
            border-left: 1px solid #dcdcdc;
        }
        select.form-control {
            margin-right: 7px !important;
        }
        .btn {
            &.btn-default {
                border-color: #7F8181;
                color: #7F8181;
                font-weight: normal;
            }
            &.btn-remove {
                color: #E81A3C;
                border-color: #E81A3C;
                .fa {
                    margin-right: 10px;
                }
            }
        }
    }   
}
.dashboard-manage-thing {
    .td-txt-ellipsis {
        .status-gd {
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
    }
    .c-tb {
        tr {
            td {
                font-size: 14px;
            }
        }
    }
    .actions-lst {
        .fa {
            color: #7F8181;
        }
        .txt {
            font-size: 14px;
            font-weight: normal;
            color: #7F8181;
        }
    }
}
.dashboard-manage-thing {
    .ad-user-lst {
        .row {
            margin-left: -5px;
            margin-right: -5px;
            [class*="col"] {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .filter-user select {
            margin-left: 0;
            min-width: auto;
        }
        .form-control {
            background-color: #F5F5F5;
            border-radius: 3px;
            border: none;
            color: #7F8181;
            font-weight: normal;
            padding-top: 0;
            padding-bottom: 0;
            height: 34px;
            line-height: 34px;
            box-shadow: none;
            &::-webkit-input-placeholder {
                color: #7F8181;
            }
            &::-moz-placeholder {
                color: #7F8181;
            }
            &:-ms-input-placeholder {
                color: #7F8181;
            }
            &:-moz-placeholder {
                color: #7F8181;
            }
        }
        &.u-search-frm {
            margin-top: 0;
            button {
                left: 10px;
            }
            .ipt {
                background-color: #F5F5F5;
                border-radius: 3px;
                border: none;
                color: #7F8181;
                font-weight: normal;
                padding-top: 0;
                padding-bottom: 0;
                height: 34px;
                line-height: 34px;
                box-shadow: none;
                &::-webkit-input-placeholder {
                    color: #7F8181;
                }
                &::-moz-placeholder {
                    color: #7F8181;
                }
                &:-ms-input-placeholder {
                    color: #7F8181;
                }
                &:-moz-placeholder {
                    color: #7F8181;
                }
            }
        }
    }
}
.dashboard-manage-thing {
    .manage-header {
        .m-ttl {
            text-align: right;
        }
        .btn-blue {
            padding-top: 0;
            padding-bottom: 0;
            height: 34px;
            line-height: 34px;
            margin-right: 0;
        }
    }
    .ico-add-form {
        display: inline-block;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-image: url('../../../images/manage-thing/ico-add.png');
        background-size: cover;
        margin-right: 8px;
        vertical-align: -2px;
    }
}
.dashboard-manage-thing {
    .col-checkbox {
        width: 50px;
    }
    // .col-name {
    //     width: 15%;
    // }
    .col-short-name{
        width: 15%;
    }
    .col-created,
    .col-actions {
        width: 15%;
    }
    .col-status{
        width: 7%;
    }
    .actions-lst {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        a {
            position: relative;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding-left: 0;
            min-width: auto;
            top: 12px;
        }
    }
    .ad-machine-tb {
        tr {
            &.hight-light {
                td {
                    background-color: #F3F6FF !important;
                }
                &:hover {
                    td {
                        background-color: #F3F6FF !important;
                    }
                }
            }
        }
        .actions-lst {
            a {
                &.copy-btn, &.del-btn {
                    left: auto;
                }
            }
        }
    }
}
.dashboard-manage-thing {
    .table-shadow {
        .table-th-inner {
            .table-th__label {
                padding-left: 0;
            }
        }
    }
    .chk-td {
        label {
            margin-bottom: 0;
            border: 2px solid #dcdcdc;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                display: none;
            }
        }
        input[type=checkbox]:checked {
            + label {
                border-color: transparent;
                background-color: #D0DFFB;
                &::before {
                    display: inline-block;
                    content: '\f00c';
                    color: #025aff;
                    font-size: 12px;
                    width: auto;
                }
            }
        }
    }
}
.dashboard-manage-thing {
    .mc-tab {
        &.new-machine {
            background-color: #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
        ul.c-tab {
            border-bottom: none;
            > li {
                > a {
                    padding-top: 15px;
                    padding-bottom: 10px;
                    &:hover {
                        border-bottom: none;
                    }
                }
                &.active {
                    > a {
                        &:hover {
                            border-bottom: 4px solid #005aff;
                        }
                    }
                }
            }
        }
    }
}
.dashboard-manage-thing {
    .form-title {
        font-family: 'Open Sans';
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 15px;
    }
    .btn {
        border-radius: 3px;
        // height: 40px;
        &.btn-success {
            background-color: #10A81F;
            border-color: #10A81F;
            &:hover {
                background-color: darken(#10A81F, 10%);
            }
        }
        &.btn-primary {
            background-color: #005AFF;
            border-color: #005AFF;
            text-transform: uppercase;
            &:hover {
                background-color: darken(#005AFF, 10%);
            }
            .fa {
                padding-left: 10px;
                margin-left: 10px;
                font-size: 14px;
                border-left: 1px solid #fff;
            }
        }
    }
}
.mt-30 {
    margin-top: 30px;
}
.group-material {
    position: relative;
    label 				 {
        color:#7F8181; 
        font-size:14px;
        line-height: 18px;
        font-weight:normal;
        position:absolute;
        pointer-events:none;
        left:15px;
        top:20px;
        margin-bottom: 0;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
    }
    .form-control {
        border: none;
        background-color: #F5F5F5;
        border-radius: 3px;
        padding-top: 0;
        padding-bottom: 0;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        color: #000;
        &:focus, &:valid { 
            ~label {
                top: 2px;
                left: 10px;
                font-size: 12px;
            }
        }
    }
}
.form-control {
    box-shadow: none;
    &:focus { outline:none; box-shadow: none; }
}
.form-general {
    .form-group {
        margin-bottom: 20px;
    }
    .txt_area {
        height: 192px;
        background-color: #f5f5f5;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        border: none;
        border-radius: 3px;
        resize: auto;
        &:focus {
            border-color: transparent;
            outline: none;
            box-shadow: none;
        }
        &::-webkit-input-placeholder {
            color: #7F8181;
        }
        &::-moz-placeholder {
            color: #7F8181;
        }
        &:-ms-input-placeholder {
            color: #7F8181;
        }
        &:-moz-placeholder {
            color: #7F8181;
        }
    }
}
.form-general {
    .select-control {
        .form-control {
            appearance: none;
            -webkit-appearance:none;
            -moz-appearance:none;
        }
        &::after {
            position: absolute;
            top: 30px;
            right: 10px;
            width: 0;
            height: 0;
            padding: 0;
            content: '';
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #000;
            pointer-events: none;
        }
    }
}

.custom-mat-toggle {
    >label {
        position: relative;
        width: 40px;
        height: 18px;
        left: 0px;
        top: 2px;
        border-radius: 10px;
        background: #DCDCDC;
        &::before {
            content: "\F00C";
            position: absolute;
            width: 22px;
            height: 22px;
            left: 0px;
            top: -2px;
            background: #C4C4C4;
            color: #C4C4C4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: color 0.1s ease-out, left 0.3s ease ;
        }
    }

    >input[type='checkbox']:checked + label{
        background: #ABE3B0;
        &::before {
            left: unset;
            right: 0px;
            background: #10A81F;
            color: #FFFFFF;
        }
    }

}
.custom-select-box {
    position: relative;
    &::after {
        content: '\f0d7';
        display: inline-block;
        font-family: 'FontAwesome';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        color: #555;
    }
    .form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
    &.sl-product {
        width: 100%;
        .form-control {
            padding: 11px 15px;
            height: 40px;
            background-color: #f5f5f5;
            border: none;
       }
    }
}
.dashboard-manage-thing {
    .tab-header {
        .row, .blk-assign {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .blk-assign {
            flex-wrap: initial;
        }
    }
    .blk-assign {
        justify-content: center;
        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            top: -5px;
            bottom: -5px;
            width: 2px;
            background-color: #dcdcdc;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
        .txt {
            color: #7F8181;
            margin-right: 25px;
            text-align: left;
        }
    }
    .blk-button-group {
        .btn-primary {
            margin-left: 10px;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: normal;
        }
        .btn-outline-primary {
            margin-right: 15px;
        }
    }
    .btn-outline-primary {
        color: #005AFF;
        border-color: #005AFF;
        border-radius: 3px;
        background-color: #fff;
        &:hover {
            background-color: #005AFF;
            color: #fff;
        }
    }
}
.dashboard-manage-thing {
    .table-shadow {
        .form-control {
            background: #F5F5F5;
            border-radius: 3px;
            border: none;
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 14px;
            &.w70 {
                width: 70px;
            }
        }
        input[type="number"] {
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        textarea {
            &.form-control {
                padding-top: 5px;
                padding-bottom: 5px;
                height: 54px;
                &:focus {
                    background-color: #F3F6FF;
                }
            }
        }
    }
    .associated-list {
        width: 300px;
        font-size: 14px;
        display: flex;
        align-items: center;
        .list {
            margin-right: 10px;
            span {
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
                &.d-block {
                    display: block;
                    margin-right: 0;
                }
            }
        }
        .attach-more {
            position: relative;
            margin-right: 10px;
            left: auto;
            top: auto;
            transform: translateY(0);
            &:last-child {
                margin-right: 0;
            }
        }
        &.questions {
            width: 150px;
        }
    }
    .attachments-wrap {
        &.questions-image {
            width: 160px;
            .attach-more {
                border-bottom: none;
                &:hover {
                    color: #7F8181;
                }
                span {
                    margin-right: 10px;
                    border-bottom: 1px dashed #7F8181;
                    &:hover {
                        color: #005aff;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .action-item {
        cursor: pointer;
        font-size: 18px;
        color: #7F8181;
        margin-right: 10px;
        &:hover {
            color: #E81A3C;
        }
        &:last-child {
            margin-right: 0;
        }
        &.duplicate {
            &:hover {
                color: #005AFF;
            }
        }
        &.disabled {
            opacity: .65;
            cursor: default;
            &:hover {
                color: #7F8181;
            }
        }
    }
    .table-cus tr td:not(.bg-eee):not(.bg-f7):not(.bg-fb):not(.bg-eefaff):not(.disabled){
        background-color: transparent !important;
    }
}
.form-add-parameter {
    .form-group {
        padding-left: 30px;
        padding-right: 30px;
        .form-control {
            background: #F5F5F5;
            border-radius: 3px;
            border: none;
            padding: 11px 15px;
            height: 40px;
        }
        input[type="number"] {
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        textarea {
            &.form-control {
                padding-top: 15px;
                padding-bottom: 15px;
                height: 137px;
                resize: auto;
            }
        }
        .col-label {
            color: #7F8181;
        }
    }
}
#modal-add-parameter,
#modal-add-step {
    .modal-dialog {
        width: 100%;
        max-width: 570px;
    }
    .form-title {
        text-transform: none;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        padding-left: 15px;
    }
    .modal-body {
        padding-bottom: 100px;
    }
    .form-group {
        .form-title {
            padding-left: 0;
        }
    }
    .btn-default {
        border: 1px solid #7F8181;
        border-radius: 3px;
        font-size: 12px;
        color: #7F8181;
        &:hover {
            background-color: #7F8181;
            color: #fff;
        }
    }
}
#modal-add-step {
    .modal-body {
        padding-bottom: 15px;
        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
    }
}
#modal-associate-product {
    .modal-dialog {
        width: 100%;
        max-width: 570px;
    }
    .btn-default {
        border: 1px solid #7F8181;
        border-radius: 3px;
        font-size: 12px;
        color: #7F8181;
        &:hover {
            background-color: #7F8181;
            color: #fff;
        }
    }
    add-list-component {
        h4 {
            font-size: 14px;
            line-height: 18px;
            color: #000;
        }
    }
    .add-product-component {
        .row {
            margin-left: -8px;
            margin-right: -8px;
            [class*="col"] {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
    .list-footer {
        .btn-primary {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 12px;
        }
        .btn-no-outline {
            min-width: auto;
        }
    }
}

.status-message-box {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 300;
    width: 260px;
    min-height: 100px;
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transform: translateX(300px);
    transition: all 0.4s ease-in;
    &.show {
        opacity: 1;
        visibility: visible;
        transform: translateX(0px);
    }
    .inner {
        position: relative;
    }
    .title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        /* Service / error */
        color: #E81A3C;
        padding-left: 12px;
        padding-top: 12px;
        padding-right: 25px;
    }
    .btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
        min-width: auto;
        width: 15px;
        height: 15px;
        background: transparent;
        display: flex;
        padding: 0px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #7F8181;
        font-weight: normal;
    }
    .message {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        word-break: break-word;
        /* Text/Dark-Grey */
        color: #7F8181;
        padding: 12px;
    }
}
#create-new-machine,
#new-client-dts,
#client-dts-info {
    .select2-container {
        width: 100% !important;
    }
    .select2-container--default {
        .select2-selection--single {
            border-color: #ddd !important;
            height: 32px !important;
            border-radius: 2px !important;
            .select2-selection__rendered {
                line-height: 32px !important;
                padding-left: 20px;
            }
        }
    }
}
.form-control {
    &[type="number"] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none; 
            margin: 0;
        }
    }
}
.mt-0 {
    margin-top: 0 !important;
}
.fa-question-circle {
    &.help-text {
        color: #999;
        font-size: 16px;
        cursor: pointer;
        vertical-align: middle;
        padding-left: 5px;
    }
}
.machine-list-more {
    span {
        font-size: 14px;
        color: #999;
        &.more {
            color: #666;
            border-bottom: 1px dashed #666;
        }
    }
}
// CSS RESPONSIVE
@media (max-width: 1670px) {
    .dashboard-manage-thing {
        .blk-assign {
            .txt {
                margin-right: 10px;
            }
        }
    }
    .dashboard-manage-thing {
        .table-shadow {
            &.table-questions {
                .table-wrap-responsive {
                    overflow: hidden;
                    overflow-x: auto;
                    .table-cus {
                        padding-right: 5px;
                        padding-left: 5px;
                        width: 1700px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .dashboard-manage-thing {
        .actions-lst {
            a {
                &.copy-btn, &.del-btn {
                    .txt {
                        display: none;
                    }
                }
            }
        }       
    }
    .dashboard-manage-thing {
        .table-shadow {
            .table-wrap-responsive {
                overflow: hidden;
                overflow-x: auto;
                .table-cus {
                    padding-right: 5px;
                    padding-left: 5px;
                    width: 1540px;
                }
            }
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}
.form-shift-non-working {
    .base-loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    .add-product-component {
        .col-md-6 {
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }
    }
}
#shift-none-working-dates {
    .more {
        margin-left: 5px;
    }
}
.mr-10 {
    margin-right: 10px;
}
#build-shift-wizard {
    .modal-dialog {
        max-width: 600px;
    }
    .table-comp {
        &.shift-detail-comp {
            width: auto;
        }
    }
    .tb-comp-inner {
        display: flex;
        align-items: center;
    }
    .lbl {
        margin-right: 10px;
    }
    .table-row {
        justify-content: space-between;
    }
    .modal-footer {
        text-align: center;
    }
    .table-content {
        height: auto;
    }
}
.red {
    color: #E81A3C;
}
.non-working-flag {
    width: 32px;
    &:hover {
        content: url("../../../images/not-working-flag-red.png");
    }
}
.non-working-blk {
    position: relative;
}
.non-working-popup {
    position: absolute;
    background-color: #fff;
    width: 600px;
    z-index: 100;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    left: 45px;
    display: none;
    top: 0;
    &.shw {
        display: block;
    }
    .head {
        padding: 20px;
        background-color: #000;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 3px 3px 0 0;
        position: relative;
        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 10px 0;
            border-color: transparent #000 transparent transparent;
            position: absolute;
            top: 5px;
            left: -10px;
            z-index: 1;
        }
        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            padding: 0 !important;
        }
        .close {
            font-size: 18px;
            padding: 0 !important;
        }
    }
    .content {
        padding: 20px;
        border-radius: 0 0 3px 3px;
        min-height: 100px;
        position: relative;
    }
    .ttl {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #000 !important;
    }
    .table {
        margin-bottom: 0 !important;
        td, th {
            border: none !important;
            padding: 5px !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 17px !important;
        }
        th {
            color: #7F8181 !important;
            font-size: 12px !important;
            line-height: 14px !important;
        }
        tr {
            &:hover {
                td, th {
                    background-color: transparent !important;
                }
            }
        }
    }
}
.table-cus {
    tr:hover {
        .non-working-blk {
            td {
                background-color: transparent !important;
            }
        }
    }
}
@media (max-width: 1440px) {
    .dashboard-manage-thing {
        .blk-button-group {
            .btn {
                min-width: auto;
            }
            .btn-outline-primary {
                margin-right: 0;
            }
        }
    }
}
@media (max-width: 1200px) {
    .dashboard-manage-thing {
        .header-parameters {
            .col-xs-4, .col-xs-8 {
                width: 100%;
            }
            .col-xs-4 {
                margin-bottom: 10px;
            }
        }
    }
}
@media (max-width: 767px) {
    #modal-add-step {
        .modal-body {
            max-height: none;
            overflow: visible;
        }
    }
}
@media (max-width: 575px) {
    #modal-add-parameter,
    #modal-add-step {
        .modal-dialog {
            max-width: 90%;
        }
        .modal-body {
            padding-bottom: 15px;
        }
    }
}