/*
    User profile css
*/ 

.usr-prof{
    // border-top: 1px solid #ddd;
    // padding: 30px 0;
    .frm-gr{
        .ipt{
            margin-bottom: 15px;
            &:disabled{
                border: 1px solid #ddd;
                cursor: not-allowed;
            }
            &.err{
                border-color: @red;
            }
        }
        &.disabled {
            background-color: #ddd;
            cursor: not-allowed;
            opacity: 0.65;
            label {
                pointer-events: none;
            }
            .ipt {
                border-color: #bdbdbd;
                pointer-events: none;
            }
        }
    }
    .btn-submit{
        position: relative;
        .load-pass{
            border: 2px solid #ddd; /* Light grey */
            border-top: 2px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
            position: absolute;
            top: 6px;
            left: 100px;
        }
    }
    .alert-msg{
        position: relative;
        span{
            display: inline-block;
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 16px;
        }
    }
}