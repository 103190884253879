.datatable.material {
    background: #FFF;
    /* -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24); */
    /**
     * Shared Styles
     */
    /**
     * Global Row Styles
     */
    /**
     * Header Styles
     */
    /**
     * Body Styles
     */
    /**
     * Footer Styles
     */
  }
  .datatable.material.striped .datatable-row-odd {
    background: #eee;
  }
  .datatable.material.single-selection .datatable-body-row.active, .datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .datatable.material.multi-selection .datatable-body-row.active, .datatable.material.multi-selection .datatable-body-row.active .datatable-row-group {
    background-color: #ABE3B0;
    color: #000;
  }
  .datatable.material.single-selection .datatable-body-row.active:hover, .datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .datatable.material.multi-selection .datatable-body-row.active:hover, .datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: #ABE3B0;
    color: #000;
  }
  .datatable.material.single-selection .datatable-body-row.active:focus, .datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .datatable.material.multi-selection .datatable-body-row.active:focus, .datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group {
    background-color: #ABE3B0;
    color: #000;
  }
  .datatable.material:not(.cell-selection) .datatable-body-row:hover, .datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: #eee;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }
  .datatable.material:not(.cell-selection) .datatable-body-row:focus, .datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
    background-color: #ddd;
  }
  .datatable.material.cell-selection .datatable-body-cell:hover, .datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
    background-color: #eee;
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }
  .datatable.material.cell-selection .datatable-body-cell:focus, .datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
    background-color: #ddd;
  }
  .datatable.material.cell-selection .datatable-body-cell.active, .datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
    background-color: #ABE3B0;
    color: #000;
  }
  .datatable.material.cell-selection .datatable-body-cell.active:hover, .datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
    background-color: #ABE3B0;
    color: #000;
  }
  .datatable.material.cell-selection .datatable-body-cell.active:focus, .datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
    background-color: #ABE3B0;
    color: #000;
  }
  .datatable.material .empty-row {
    height: 50px;
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }
  .datatable.material .loading-row {
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }
  .datatable.material .datatable-header .datatable-row-left,
  .datatable.material .datatable-body .datatable-row-left {
    background-color: #FFF;
    background-position: 100% 0;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
  }
  .datatable.material .datatable-header .datatable-row-right,
  .datatable.material .datatable-body .datatable-row-right {
    background-position: 0 0;
    background-color: #fff;
    background-repeat: repeat-y;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
  }
  .datatable.material .datatable-header {
    border-bottom: 1px solid #F3F6FF;;
  }
  .datatable.material .datatable-header .datatable-header-cell {
    text-align: center;
    padding: 0.5rem 1.2rem;
    font-weight: 400;
    color: #757575;
    vertical-align: bottom;
  }
  .datatable.material .datatable-header .datatable-header-cell.longpress {
    color: #00E676;
  }
  .datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
    border-right: none;
  }
  .datatable.material .datatable-header .resize-handle {
    border-right: solid 1px #eee;
  }
  .datatable.material .datatable-body .datatable-row-detail {
    background: #f5f5f5;
    padding: 10px;
  }
  .datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    justify-content: center;
    text-align: center;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .datatable.material .datatable-body .progress-linear {
    display: block;
    position: relative;
    width: 100%;
    height: 5px;
    padding: 0;
    margin: 0;
    position: absolute;
  }
  .datatable.material .datatable-body .datatable-row-wrapper {
    border-bottom: 1px solid #F3F6FF;
  }
  .datatable.material .datatable-body .progress-linear .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: #aad1f9;
  }
  .datatable.material .datatable-body .progress-linear .container .bar {
    transition: all 0.2s linear;
    -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    background-color: #106cc8;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
  }
  .datatable.material .datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 16px;
  }
  .datatable.material .datatable-footer .page-count {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem;
  }
  .datatable.material .datatable-footer .datatable-pager {
    margin: 0 10px;
  }
  .datatable.material .datatable-footer .datatable-pager li {
    vertical-align: middle;
  }
  .datatable.material .datatable-footer .datatable-pager li.disabled a {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: transparent !important;
  }
  .datatable.material .datatable-footer .datatable-pager li.active a {
    background-color: rgba(158, 158, 158, 0.2);
    font-weight: bold;
  }
  .datatable.material .datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 24px;
    line-height: 22px;
    padding: 0 6px;
    border-radius: 3px;
    margin: 6px 3px;
    text-align: center;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: none;
    vertical-align: bottom;
  }
  .datatable.material .datatable-footer .datatable-pager a:hover {
    color: rgba(0, 0, 0, 0.75);
    background-color: rgba(158, 158, 158, 0.2);
  }
  .datatable.material .datatable-footer .datatable-pager .icon-left,
  .datatable.material .datatable-footer .datatable-pager .icon-skip,
  .datatable.material .datatable-footer .datatable-pager .icon-right,
  .datatable.material .datatable-footer .datatable-pager .icon-prev {
    font-size: 20px;
    line-height: 20px;
    padding: 0 3px;
  }

  .datatable.material .datatable-header .datatable-header-cell:first-of-type {
    text-align: left;
  }
  
  .datatable.material .datatable-body .datatable-body-row .datatable-body-cell:first-of-type {
    justify-content: left;
  }
  
  .datatable-body-cell{
    display: flex !important;
    align-items: center !important;
    text-align: center;
  }
  /**
   * Checkboxes
  **/
  .datatable-checkbox {
    position: relative;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 0;
  }
  .datatable-checkbox input[type=checkbox] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;
  }
  .datatable-checkbox input[type=checkbox]:before {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    border: 2px solid #f2f2f2;
  }
  .datatable-checkbox input[type=checkbox]:checked:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    height: 0.5rem;
    border-color: #009688;
    border-top-style: none;
    border-right-style: none;
  }
  .datatable-checkbox input[type=checkbox]:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: #fff;
    cursor: pointer;
  }
  
  /**
   * Progress bar animations
   */
  @-webkit-keyframes query {
    0% {
      opacity: 1;
      -webkit-transform: translateX(35%) scale(0.3, 1);
      transform: translateX(35%) scale(0.3, 1);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-50%) scale(0, 1);
      transform: translateX(-50%) scale(0, 1);
    }
  }
  @keyframes query {
    0% {
      opacity: 1;
      -webkit-transform: translateX(35%) scale(0.3, 1);
      transform: translateX(35%) scale(0.3, 1);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-50%) scale(0, 1);
      transform: translateX(-50%) scale(0, 1);
    }
  }